export const userRoutes = [
  {
    path: 'auth/user/subscription:status?',
    component: () => import('@/js/components/user/UserSubscription.vue'),
    name: 'UserSubscription',
    meta: {
      requireAuth: true
    },
    props: true
  },
  {
    path: 'auth/user/pwa',
    component: () => import('@/js/components/user/UserPwa.vue'),
    name: 'UserPwa',
    meta: {
      requireAuth: true
    }
  },
  {
    path: 'auth/user/settings',
    component: () => import('@/js/components/user/settings/UserSettings.vue'),
    name: 'UserSettings',
    meta: {
      requireAuth: true
    }
  },
  {
    path: 'auth/user/settings/bg',
    component: () => import('@/js/components/user/settings/bg/UserSettingsBackground.vue'),
    name: 'UserSettingsBackground',
    meta: {
      requireAuth: true
    }
  }
]
