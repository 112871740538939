export const webappRoutes = [
    {
        path: '/webapp/:uuid',
        component: () => import('@/js/modules/webapp/IndexWebApp.vue'),
        name: 'IndexWebApp',
        props: true,
        meta: {
            isPublic: true
        },
    },
    {
        path: '/tournament/:uuid',
        component: () => import('@/js/modules/webapp/IndexTournament.vue'),
        name: 'IndexTournament',
        props: true,
        meta: {
            isPublic: true
        },
    },
    {
        path: '/course/:uuid/homeworks',
        component: () => import('@/js/modules/webapp/Course/Lesson/Partials/Homework/List/HomeworkList.vue'),
        name: 'IndexCourseHomeworks',
        props: {
            from_course: true,
        },
        meta: {
            isPublic: true,
        },
    },
    {
        path: '/course/:uuid',
        component: () => import('@/js/modules/webapp/IndexCourse.vue'),
        name: 'IndexCourse',
        props: true,
        meta: {
            isPublic: true
        },
    },
    {
        path: '/course/:uuid/:section_id',
        component: () => import('@/js/modules/webapp/Course/IndexSection.vue'),
        name: 'IndexSection',
        props: true,
        meta: {
            isPublic: true
        },
    },
    {
        path: '/course/:uuid/:section_id/:lesson_id',
        component: () => import('@/js/modules/webapp/Course/Lesson/ShowSectionLesson.vue'),
        name: 'IndexSectionLesson',
        props: true,
        meta: {
            isPublic: true
        },
    },
    {
        path: '/homework/:url',
        component: () => import('@/js/modules/webapp/IndexHomework.vue'),
        name: 'ShowHomework',
        props: (route) => ({
            homework_url: route.params.url,
        }),
        meta: {
            isPublic: true,
        }
    },
    {
        path: '/landing/:uuid',
        component: () => import('@/js/modules/webapp/IndexLanding.vue'),
        name: 'IndexLanding',
        props: true,
        meta: {
            isPublic: true
        },
    },
    {
        path: '/product/:uuid',
        component: () => import('@/js/modules/webapp/IndexProduct.vue'),
        name: 'IndexProduct',
        props: (route) => ({
            uuid: route.params.uuid,
            type: 'product',
        }),
        meta: {
            isPublic: true
        },
    },
    {
        path: '/subscription/:uuid',
        component: () => import('@/js/modules/webapp/IndexProduct.vue'),
        name: 'IndexSubscription',
        props: (route) => ({
            uuid: route.params.uuid,
            type: 'subscription',
        }),
        meta: {
            isPublic: true
        },
    }
]
