<template>

    <div :class="{'dark': isDark}" class="h-[inherit] min-h-[100vh] max-sm:text-xs">
        <div class="h-[inherit] min-h-[100vh]" :class="webApp.styles['wrap']">
            <web-app-header />
            <web-app-install-request />
            <div class="h-[inherit]" :class="webApp?.isRawLanding ? '' : 'container max-w-[600px] mx-auto grid grid-cols-1 gap-5 px-[10px!important]'">
                <web-app-preloader />
                <router-view v-if="webApp?.isInit" :params="params" />
            </div>
        </div>
    </div>

</template>

<script>
import WebAppPreloader from "@/js/modules/webapp/components/WebAppPreloader.vue";
import WebAppHeader from "@/js/modules/webapp/components/WebAppHeader.vue";
import {useWebApp} from "@/js/store/pinia/webapp/WebApp.js";
import {useWebAppSubscriber} from "@/js/store/pinia/webapp/WebAppSubscriber.js";
import {useWebAppSettings} from "@/js/store/pinia/webapp/WebAppSettings.js";
import WebAppInstallRequest from "@/js/modules/webapp/components/WebAppInstallRequest.vue";
import {useWebAppTelegram} from "@/js/store/pinia/webapp/WebAppTelegram.js";

export default
{
    name: "WebApp",
    components:
    {
        WebAppInstallRequest,
        WebAppHeader,
        WebAppPreloader,
    },
    props:
    {
        params: {
            required: false,
            type: Object
        },
        chatId: {
            required: true,
            type: String
        },
    },
    mounted()
    {
        this.init();
    },
    computed:
    {
        webApp() {
            return useWebApp();
        },
        subscriber() {
            return useWebAppSubscriber();
        },
        targetParams() {
            return [
                ['wwb_aq_sub_uuid'],
                ['id', 'first_name', 'last_name', 'username', 'photo_url', 'auth_date', 'hash'],
                ['wwb_redirect_landing']
            ];
        },
        settings() {
            return useWebAppSettings();
        },
        isDark() {
            return this.settings.isDark && !this.settings.isRawLanding;
        },
        telegram() {
            return useWebAppTelegram();
        }
    },
    methods:
    {
        async init()
        {
            await this.webApp.init(this.chatId);

            let collectedParams = {};
            const urlParams = new URLSearchParams(window.location.search);
            this.targetParams.forEach(fields => {
                if (fields.every(key => urlParams.has(key)))
                {
                    for (const key of fields)
                    {
                        collectedParams[key] = urlParams.get(key);
                        urlParams.delete(key);
                    }
                }
            });
            await this.subscriber.init(collectedParams.wwb_aq_sub_uuid);
            await this.subscriber.init(collectedParams.id);
            this.webApp.isInit = true;

            const newUrl = window.location.pathname + (urlParams.toString() ? '?' + urlParams.toString() : '');
            const currentUrl = window.location.pathname + window.location.search;

            if (newUrl !== currentUrl)
            {
                this.$router.replace(newUrl);
            }
        }
    }
}
</script>

<style lang="css">

</style>
