import { ChatModel } from '@/js/models/chat/ChatModel'
import { ChatWidgetTypeModel } from '@/js/models/chat/widget/ChatWidgetTypeModel'

export class ChatWidgetModel {
  static NOT_FILLABLE = ['description', 'buttons', 'image', 'image_fit']

  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/widgets`
  }

  static getJsCode(id, chat_uuid) {
    return (
      `<script>
        (function (w, d, t, a, n, js, ojs){
        w['WWBLandingWidget'] = n;
        w[n] = w[n] || function () { (w[n].q = w[n].q || []).push(arguments)};
        w[n].l = 1 * new Date(); js = d.createElement(t);
        ojs = d.getElementsByTagName(t)[0]; js.async = 1; js.src = a;
        ojs.parentNode.insertBefore(js, ojs);
        })(window, document, 'script', '${window.baseOrigin()}/widget-landing/widget.js', 'wwblanding');
        wwblanding('init')

        wwblanding('show', {
            bot_uuid: '${chat_uuid}',
            widget_id: ${id}
        }) \n</` + 'script>'
    )
  }

  constructor() {
    this.form = {
      id: null,
      type_id: ChatWidgetTypeModel.BIG,
      repeat_id: null,
      title: '',
      description: '',
      image: '',
      image_fit: 0,
      bg: '',
      delay: 0,
      buttons: []
    }
  }
}
