export const chatMessageRoutes = [
  {
    path: 'chat/:chat_id/messages/:type_id',
    component: () => import('@/js/components/chat/message/IndexChatMessage.vue'),
    name: 'IndexChatMessage',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/messages/:type_id/store',
    component: () => import('@/js/components/chat/message/StoreChatMessage.vue'),
    name: 'StoreChatMessage',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/messages/:type_id/:message_id/update',
    component: () => import('@/js/components/chat/message/UpdateChatMessage.vue'),
    name: 'UpdateChatMessage',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/messages/:type_id/:message_id/stat',
    component: () => import('@/js/components/chat/message/StatChatMessage.vue'),
    name: 'StatChatMessage',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]

export const chatMessageFolderRoutes = [
  {
    path: 'chat/:chat_id/message-folders/:type_id',
    component: () => import('@/js/components/chat/message/IndexChatMessage.vue'),
    name: 'IndexChatMessageFolder',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },

  {
    path: 'chat/:chat_id/message-folders/:type_id/store',
    component: () => import('@/js/components/chat/messageFolder/StoreChatMessageFolder.vue'),
    name: 'StoreChatMessageFolder',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/message-folders/:type_id/:folder_id/update',
    component: () => import('@/js/components/chat/messageFolder/UpdateChatMessageFolder.vue'),
    name: 'UpdateChatMessageFolder',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },

  {
    path: 'chat/:chat_id/message-folders/:type_id/:folder_id/settings',
    component: () => import('@/js/components/chat/message/IndexChatMessage.vue'),
    name: 'IndexChatMessageFolderSettings',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/message-folders/:type_id/:folder_id/messages',
    component: () => import('@/js/components/chat/message/IndexChatMessage.vue'),
    name: 'IndexChatMessageFolderMessages',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/message-folders/:type_id/:folder_id/messages/store',
    component: () => import( '@/js/components/chat/message/StoreChatMessage.vue'),
    name: 'StoreChatMessageFolderMessages',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
