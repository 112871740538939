export const chatCourseRoutes = [
    {
        path: 'chat/:chat_id/content/courses/store',
        component: () => import('@/js/components/chat/content/course/StoreChatCourse.vue'),
        name: 'StoreChatCourse',
        meta: {
            requireAuth: true, showBotName: true
        },
        props: true
    },
    {
        path: 'chat/:chat_id/content/courses/:course_id/sections/store',
        component: () => import('@/js/components/chat/content/course/section/StoreChatCourseSection.vue'),
        name: 'StoreChatCourseSection',
        meta: {
            requireAuth: true, showBotName: true
        },
        props: true
    },
    {
        path: 'chat/:chat_id/content/courses/:course_id/sections/:item_id/update',

        component: () => import('@/js/components/chat/content/course/section/UpdateChatCourseSection.vue'),
        name: 'UpdateChatCourseSection',
        meta: {
            requireAuth: true, showBotName: true
        },
        props: true
    },
    {
        path: 'chat/:chat_id/content/courses/:course_id/lessons/store',
        component: () => import('@/js/components/chat/content/course/lesson/StoreChatCourseLesson.vue'),
        name: 'StoreChatCourseLesson',
        meta: {
            requireAuth: true, showBotName: true
        },
        props: true
    },
    {
        path: 'chat/:chat_id/content/courses/:course_id/lessons/:item_id/update',
        component: () => import('@/js/components/chat/content/course/lesson/UpdateChatCourseLesson.vue'),
        name: 'UpdateChatCourseLesson',
        meta: {
            requireAuth: true, showBotName: true
        },
        props: true
    },
    {
        path: 'chat/:chat_id/content/courses/:course_id/:page',
        component: () => import('@/js/components/chat/content/course/IndexChatCourseTab.vue'),
        name: 'IndexChatCourseTab',
        meta: {
            requireAuth: true, showBotName: true
        },
        props: true
    }
]
