import {
    integerRule,
    onlyMenuOrButton,
    onlyUploadOrButton,
    platformTextLengthValidator,
    requiredOnlyTextMessage,
    requiredRule
} from '@/js/services/ValidationRuleService'

import {i18n} from '@/js/plugins/i18n'

import {ChatModel} from '@/js/models/chat/ChatModel'
import {ChatPlatformModel} from '@/js/models/chat/ChatPlatformModel'
import {ChatMessageTypeModel} from '@/js/models/chat/message/ChatMessageTypeModel'
import {ChatMessageScheduleModel} from '@/js/models/chat/message/schedule/ChatMessageScheduleModel'
import {ChatMessageTriggerModel} from '@/js/models/chat/message/trigger/ChatMessageTriggerModel'
import {ChatMessageTimetableModel} from '@/js/models/chat/message/ChatMessageTimetableModel'
import {ChatMessageCommandModel} from '@/js/models/chat/message/ChatMessageCommandModel'
import {ChatMessageFolderModel} from '@/js/models/chat/message/folder/ChatMessageFolderModel'
import {ChatMessageStatusModel} from '@/js/models/chat/message/ChatMessageStatusModel'
import {QuestionAnswerFieldModel} from '@/js/models/question/QuestionAnswerFieldModel'
import {UploadModel} from '@/js/models/upload/UploadModel'
import {UploadFileTypeModel} from '@/js/models/upload/UploadFileTypeModel'
import {ChatMessageTriggerEventModel} from '@/js/models/chat/message/trigger/ChatMessageTriggerEventModel'
import {ChatMessageButtonTypeModel} from '@/js/models/chat/message/button/ChatMessageButtonTypeModel'

export class ChatMessageModel {
    static URL_ADMIN = 'admin/super/messages'
    static SORT_DESC = -1
    static SORT_ASC = 1
    static SORT_BY_NAME = -2
    static SORT_BY_UPLOAD = -3
    static SORT_BY_DATE_SENT_DESC = -4
    static SORT_BY_DATE_SENT_ASC = -5
    static SORT_GROUP_STATUS = {
        label: i18n.t('main-words.status'), items: [{
            id: ChatMessageModel.SORT_BY_NAME, name: i18n.t('models.chat.message.sort.by-name')
        }, {
            id: ChatMessageModel.SORT_BY_UPLOAD, name: i18n.t('models.chat.message.sort.by-upload')
        }]
    }
    static SORT_GROUP_DATE_SENT = {
        label: i18n.t('main-words.sort-.by-date-sent'), items: [{
            id: ChatMessageModel.SORT_BY_DATE_SENT_DESC, name: i18n.t('models.chat.message.sort.by-date-sent-desc')
        }, {
            id: ChatMessageModel.SORT_BY_DATE_SENT_ASC, name: i18n.t('models.chat.message.sort.by-date-sent-asc')
        }]
    }
    static NOT_FILLABLE = ['template_parent_id',

        'redirect_text', 'text', 'text_html', 'text_json',

        'schedule', 'triggers', 'timetable', 'command',

        'keywords',

        'has_interval', 'intervals',

        'has_upload', 'upload',

        'has_button', 'buttons',

        'has_filter', 'filter',

        'points_field', 'folder',

        'has_delay_delete', 'is_one_time']
    static BOOLEAN_KEYS = ['allow_edit', 'questionary_hide', 'menu_hide', 'menu_is_new_line', 'is_one_time', 'is_private', 'has_link_preview']

    constructor(props) {
        this.chat_platform_id = null

        this.searchForm = {
            folder_id: null,
            sort_by_time: '',
            sort_id: null,
            type_id: null,
            lang_id: null,
            platform_id: null,
            search: ''
        }

        this.form = {
            id: null,
            name: '',
            uuid: `uuid-${+new Date()}`,

            text: '',
            text_html: '',
            redirect_text: false,

            type_id: null,

            has_filter: false,
            filter_id: null,
            folder_id: null,
            lang_id: null,
            platform_id: null,
            group_id: null,

            questionary_id: null,
            questionary_hide: null,
            menu_id: null,
            menu_parent_id: null,
            menu_hide: null,
            menu_is_new_line: true,

            has_delay_delete: false,
            delay_delete: null,

            schedule: new ChatMessageScheduleModel().form,
            triggers: [new ChatMessageTriggerModel().form],
            command: new ChatMessageCommandModel().form,

            has_upload: false,
            upload: new UploadModel().form,

            has_button: false,
            buttons: [],

            has_timetable: false,
            timetable: new ChatMessageTimetableModel().form,

            keywords: [],

            has_interval: false,
            intervals: [],

            is_sent: false,
            is_one_time: false,
            is_private: false,
            has_link_preview: true,

            points_field: new QuestionAnswerFieldModel().form,

            folder: new ChatMessageFolderModel().form
        }
    }

    get rules() {
        const textValidator = (rule, value, callback) => {
            if (this.form.type_id === ChatMessageTypeModel.TRIGGER && this.form.triggers.find((trigger) => trigger.event_id === ChatMessageTriggerEventModel.PRIVATE_REPLY) && (this.form.has_button || this.form.has_upload)) {
                return callback(new Error(i18n.t(requiredOnlyTextMessage)))
            }

            const length = this.form.text.html.length || 0

            platformTextLengthValidator(
                length,
                this.chat_platform_id,
                callback,
                this.form.has_button,
                this.form.has_upload ? this.form.upload.file_type_id : null,
                true,
                this.form.buttons,
                !!this.form.buttons.find(({type_id}) => type_id === ChatMessageButtonTypeModel.RECURRING_NOTIFICATION)
            )
        }

        const requiredForSchedule = (rule, value, callback) => {
            if ((value === '' || value === null) && this.form.type_id === ChatMessageTypeModel.SCHEDULE) {
                return callback(new Error(requiredRule().message))
            }

            callback()
        }

        const requiredForTimetable = (rule, value, callback) => {
            if (this.form.type_id !== ChatMessageTypeModel.RIGHT_NOW || !this.form.has_timetable || value) {
                return callback()
            }

            return callback(new Error(requiredRule().message))
        }

        const buttonOrUploadValidator = (rule, value, callback) => {
            switch (this.chat_platform_id) {
                case ChatPlatformModel.TELEGRAM:
                    if (!this.form.has_button) {
                        return callback()
                    }

                    if (this.form.menu_id) {
                        return callback(new Error(i18n.t(onlyMenuOrButton)))
                    }

                    if (this.form.has_upload && this.form.upload && this.form.upload.is_multiple && this.form.upload.remote_uuid && this.form.upload.remote_uuid.length > 1) {
                        return callback(new Error(i18n.t(onlyUploadOrButton)))
                    }

                    return callback()
                case ChatPlatformModel.VK:
                case ChatPlatformModel.SLACK:
                    return callback()
                case ChatPlatformModel.VIBER:
                    return this.form.has_upload && this.form.buttons.length ? callback(new Error(i18n.t(onlyUploadOrButton))) : callback()
                case ChatPlatformModel.FACEBOOK:
                case ChatPlatformModel.INSTAGRAM:
                    return this.form.has_upload && +this.form.upload.file_type_id !== UploadFileTypeModel.PHOTO && +this.form.upload.file_type_id !== UploadFileTypeModel.SUB_AVATAR && this.form.buttons.length ? callback(new Error(i18n.t(onlyUploadOrButton))) : callback()
            }
        }

        return {
            type_id: [requiredRule()],

            name: [{max: 1000}],

            text: [{validator: textValidator, trigger: 'blur'}, {validator: buttonOrUploadValidator, trigger: 'blur'}],

            schedule: {
                day: [{validator: requiredForSchedule, trigger: 'blur'}, integerRule()],
                timezone: [{validator: requiredForSchedule, trigger: 'blur'}],
                time: [{validator: requiredForSchedule, trigger: 'blur'}]
            },

            timetable: {
                timezone: [{validator: requiredForTimetable, trigger: 'blur'}],
                datetime: [{validator: requiredForTimetable, trigger: 'blur'}]
            }
        }
    }

    static url(chat_id) {
        return `${ChatModel.URL}/${chat_id}/messages`
    }

    static getName({name}) {
        return name.length < 35 ? name : name.substr(0, 35) + '...'
    }

    static getLinks(message) {
        let links = message.links || []

        if (message.questionary) {
            message.questionary.questions.forEach((question) => {
                question.links.forEach((link) => {
                    links.push(link)
                })
            })
        }

        if (message.upload && message.upload.link) {
            links.push(message.upload.link)
        }

        return links
    }

    static canToggleStatus({status_id, type_id}) {
        return ([ChatMessageStatusModel.PAUSE, ChatMessageStatusModel.DRAFT].includes(status_id) || ![ChatMessageTypeModel.RIGHT_NOW, ChatMessageTypeModel.QUICK_REPLY].includes(type_id))
    }

    static canCopy({template_parent_id, type_id}) {
        return (!template_parent_id && [ChatMessageTypeModel.SCHEDULE, ChatMessageTypeModel.RIGHT_NOW, ChatMessageTypeModel.TRIGGER, ChatMessageTypeModel.COMMAND, ChatMessageTypeModel.KEYWORD, ChatMessageTypeModel.MENU].includes(type_id))
    }

    static hasStat(message) {
        return (!message.group_id && (![ChatMessageTypeModel.RIGHT_NOW, ChatMessageTypeModel.QUICK_REPLY].includes(message.type_id) || this.hasBeenSent(message)))
    }

    static hasSortOrder({template_parent_id, type_id}) {
        return !template_parent_id && ChatMessageTypeModel.hasSort(type_id)
    }

    static hasBeenSent({is_sent}) {
        return !!is_sent
    }
}
