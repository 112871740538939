import { chatConversationRoutes } from '@/js/routes/chat/conversation/routes'
import { chatMessageRoutes, chatMessageFolderRoutes } from '@/js/routes/chat/message/routes'
import { chatFolderRoutes } from '@/js/routes/chat/folder/routes'

import { chatWidgetRoutes } from '@/js/routes/chat/settings/widget/routes'
import { chatGroupRoutes } from '@/js/routes/chat/settings/group/routes'
import { chatNotificationRoutes } from '@/js/routes/chat/notification/routes'
import { chatLandingDeepLinkRoutes } from '@/js/routes/chat/settings/landing/routes'
import { chatQuestionaryRoutes } from '@/js/routes/chat/questionary/routes'
import { chatGptRoutes } from '@/js/routes/chat/settings/gpt/routes'

export const chatRoutes = [
  ...chatConversationRoutes,
  ...chatFolderRoutes,
  ...chatMessageRoutes,
  ...chatMessageFolderRoutes,
  ...chatGroupRoutes,
  ...chatWidgetRoutes,
  ...chatNotificationRoutes,
  ...chatLandingDeepLinkRoutes,
  ...chatQuestionaryRoutes,
  ...chatGptRoutes,
  ...[
    {
      path: 'chat/index',
      component: () => import('@/js/components/chat/main/IndexChat.vue'),
      name: 'IndexChat',
      meta: {
        requireAuth: true,
        hideBackButton: true
      },
      props: true
    },
    {
      path: 'chat/store/:platform_id?',
      component: () => import('@/js/components/chat/main/StoreChat.vue'),
      name: 'StoreChat',
      meta: {
        requireAuth: true
      },
      props: true
    },
    {
      path: 'chat/store/:platform_id/:chat_id',
      component: () => import('@/js/components/chat/main/StoreChatFinish.vue'),
      name: 'StoreChatFinish',
      meta: {
        requireAuth: true
      },
      props: true
    },
    {
      path: 'chat/:chat_id/guide',
      component: () => import('@/js/components/chat/main/GuideChat.vue'),
      name: 'GuideChat',
      meta: {
        requireAuth: true
      },
      props: true
    },
    {
      path: 'chat/:chat_id/guide/finish',
      component: () => import('@/js/components/chat/main/GuideChatFinish.vue'),
      name: 'GuideChatFinish',
      meta: {
        requireAuth: true
      },
      props: true
    },
    {
      path: 'chat/:chat_id/update/:tab?',
      component: () => import('@/js/components/chat/settings/IndexChatSettings.vue'),
      name: 'IndexChatSettings',
      meta: {
        requireAuth: true,
        showBotName: true
      },
      props: true
    },
    {
      path: 'chat/:chat_id/dashboard',
      component: () => import('@/js/components/chat/dashboard/IndexChatDashboard.vue'),
      name: 'IndexChatDashboard',
      meta: {
        requireAuth: true,
        showBotName: true
      },
      props: true
    }
  ]
]
