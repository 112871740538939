import { ChatModel } from '@/js/models/chat/ChatModel'

export class ChatAdminModel {
  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/admins`
  }

  static urlSubscription(chat_id) {
    return `${ChatModel.URL}/${chat_id}/subscription`
  }

  constructor() {
    this.form = {
      role_id: null,
      user_id: null,
      user: null
    }
  }

  static getName(item) {
    return item.user.name.length < 35
      ? item.user.name
      : item.user.name.substr(0, 35) + '...'
  }
}
