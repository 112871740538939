import { ChatSubscriberTagModel } from '@/js/models/chat/subscriber/tag/ChatSubscriberTagModel'

const initialState = () => {
  return {
    chat_subscriber_tags: [],
    chat_subscriber_tags_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_subscriber_tags_loaded = isLoaded
    },

    setTags(state, tags) {
      state.chat_subscriber_tags = []

      tags.forEach((item) => state.chat_subscriber_tags.push(item))
    },

    addTag(state, tag) {
      if (state.chat_subscriber_tags.find((x) => x.id === tag.id)) return

      state.chat_subscriber_tags.unshift(tag)
    },

    deleteTag(state, id) {
      let index = state.chat_subscriber_tags.findIndex((tag) => tag.id === id)

      if (index === -1) return

      state.chat_subscriber_tags.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchTags({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatSubscriberTagModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setTags', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetTags')

            return reject(error)
          })
      })
    },

    setTags({ commit }, tags) {
      commit('setTags', tags)

      commit('setLoaded', true)
    },

    addTag({ commit }, tag) {
      commit('addTag', tag)
    },

    deleteTag({ commit }, id) {
      commit('deleteTag', id)
    },

    resetTags({ commit }) {
      commit('reset')
    }
  }
}
