<template>
  <el-container direction="vertical" class="min-h-100 p-0">
    <el-main class="d-flex position-relative p-0">
      <el-row class="flex-grow-1 overflow-x-hidden">
        <router-view />
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
  export default {
    name: 'MainPublicReferral'
  }
</script>
<style scoped></style>
