import { ChatCourseModel } from '@/js/models/chat/course/ChatCourseModel'

const initialState = () => {
  return {
    chat_courses: [],
    chat_courses_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_courses_loaded = isLoaded
    },

    setCourses(state, courses) {
      state.chat_courses = []

      courses.forEach(({ id, name }) =>
        state.chat_courses.push({ id: id, name: name })
      )
    },

    deleteCourse(state, id) {
      let index = state.chat_courses.findIndex((item) => item.id === id)

      if (index === -1) return

      state.chat_courses.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchCourses({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatCourseModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setCourses', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetCourses')

            return reject(error)
          })
      })
    },

    setCourses({ commit }, courses) {
      commit('setCourses', courses)

      commit('setLoaded', true)
    },

    deleteCourse({ commit }, id) {
      commit('deleteCourse', id)
    },

    resetCourses({ commit }) {
      commit('reset')
    }
  }
}
