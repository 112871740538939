import {defineStore} from 'pinia';
import {useWebAppSubscriber} from "@/js/store/pinia/webapp/WebAppSubscriber.js";
import {useWebAppChat} from "@/js/store/pinia/webapp/WebAppChat.js";
import {useWebAppTelegram} from "@/js/store/pinia/webapp/WebAppTelegram.js";
import {useWebAppScrolling} from "@/js/store/pinia/webapp/WebAppScrolling.js";
import {useWebAppSettings} from "@/js/store/pinia/webapp/WebAppSettings.js";
import router from '@/js/plugins/router'

export const useWebApp = defineStore('webAppStore', {
    state: () => ({
        subscriber: useWebAppSubscriber(),
        chat: useWebAppChat(),
        telegram: useWebAppTelegram(),
        scrolling: useWebAppScrolling(),
        settings: useWebAppSettings(),
        isInit: false,
    }),
    getters:
    {
        API_BASE_URL: () => '/webapp/',

        isPreloader(state) {
            return state.settings.isPreloader;
        },

        isRawLanding(state) {
            return state.settings.isRawLanding;
        },

        isInitFull(state) {
            return state.subscriber.isInit
                && state.chat.isInit
                && state.telegram.isInit
                && state.isInit;
        },

        styles ()
        {
            const animation = 'duration-150'
            const rounded = 'rounded-lg';

            const wrap = 'bg-gray-50 dark:bg-gray-950 dark:text-gray-200';
            const header = 'bg-gray-100 border-b-2 border-gray-200 dark:bg-gray-950 dark:border-gray-900 dark:text-gray-200';
            const panel = 'border-2 bg-gray-100 dark:border-gray-900 dark:text-gray-200 dark:bg-gray-900 dark:text-gray-200 overflow-hidden';
            const panelActive = 'hover:bg-gray-200 dark:hover:bg-gray-800 cursor-pointer'
            const button = 'p-2 w-full text-center shadow-md text-white bg-blue-500 hover:bg-blue-400 dark:bg-blue-900 hover:dark:bg-blue-800 disabled:hover:bg-blue-500 disabled:hover:dark:bg-blue-900 disabled:cursor-not-allowed disabled:opacity-25';
            const buttonBack = 'hover:text-sky-500 dark:hover:text-sky-700 cursor-pointer';
            const input = 'border-2 w-full p-2 dark:bg-gray-900 dark:border-gray-700 focus:ring-1 focus:ring-gray-400 dark:focus:ring-gray-700 outline-none focus:outline-none focus-visible:outline-none';
            const checkbox = 'w-4 h-4 text-blue-600 dark:text-blue-900 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-700 focus:ring-1';
            const link = 'hover:underline hover:text-sky-500 dark:hover:text-sky-700 cursor-pointer';
            const badge = 'bg-gray-300 dark:bg-gray-800';
            const badgePending = 'bg-sky-500 dark:bg-sky-900';
            const badgeWait = 'bg-yellow-500 dark:bg-yellow-900'
            const badgeComplete = 'bg-green-600 dark:bg-green-900';
            const alertError = 'p-3 border-2 bg-red-50 border-red-600 text-red-600 dark:border-red-400 dark:text-red-400 dark:bg-gray-900';
            const conversationMessage = 'flex text-black flex-col p-3 text-truncate max-w-[70%] shadow-md rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white'
            const conversationMessageReverse = 'flex text-black flex-col p-3 shadow-md rounded-lg max-w-[70%] ml-auto bg-indigo-200 dark:text-white dark:bg-gray-900'

            return {
                wrap: `${wrap} ${animation}`,
                header: `${header} ${animation}`,
                panel: `${panel} ${rounded} ${animation}`,
                panelActive: `${panel} ${panelActive} ${rounded} ${animation}`,
                button: `${button} ${rounded} ${animation}`,
                buttonBack: `${buttonBack} ${animation}`,
                input:`${input} ${rounded} ${animation}`,
                checkbox: `${checkbox} ${rounded} ${animation}`,
                link: `${link} ${animation}`,
                badge: `${badge} ${animation}`,
                badgeWait: `${badgeWait} ${animation}`,
                badgePending: `${badgePending} ${animation}`,
                badgeComplete: `${badgeComplete} ${animation}`,
                alertError: `${alertError} ${rounded} ${animation}`,
                conversationMessage: `${conversationMessage} ${rounded} ${animation}`,
                conversationMessageReverse: `${conversationMessageReverse} ${rounded} ${animation}`,
            };
        },


    },
    actions:
    {
        async init(chatId)
        {
            await this.settings.init();
            await this.chat.init(parseInt(chatId));
            await this.subscriber.init();
            await this.telegram.init();
        },

        async fetch(url, data = {}, toggleIsPreloader = true)
        {
            if (toggleIsPreloader)
            {
                this.updatePreloader(true);
            }

            try
            {
                const response = await axios.get(this.API_BASE_URL + url, { params: data });
                return response.data;
            }
            catch (error)
            {
                throw error;
            }
            finally
            {
                if (toggleIsPreloader) {
                    this.updatePreloader(false);
                }
            }
        },

        updatePreloader(status) {
            this.settings.isPreloader = status;
        },

        updateRawLanding(status) {
            this.settings.isRawLanding = status;
        },

        openLink(url, is_external = false, has_back_button = false, replace = false)
        {
            if(is_external) return window.open(url, '_blank');

            if(this.telegram.isOpenedInWebApp)
            {
                has_back_button
                    ? window.Telegram.WebApp.BackButton.show()
                    : window.Telegram.WebApp.BackButton.hide()

                if(url.includes('tg://') || url.includes('https://t.me/'))
                {
                    return window.Telegram.WebApp.openTelegramLink(
                        url
                            .replace('tg://resolve?domain=', 'https://t.me/')
                            .replace('&start', '?start')
                    )
                }
            }

            if (url.includes(window.location.origin) || !url.includes("http"))
            {
                return replace
                    ? router.replace(url)
                    : router.push(url);
            }

            return window.open(url, '_self');
        }
    },
});
