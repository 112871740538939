export const chatNotificationRoutes = [
  {
    path: 'chat/:chat_id/notifications',
    component: () => import('@/js/components/chat/message/IndexChatMessage.vue'),
    name: 'IndexChatNotification',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/notifications/store',
    component: () => import('@/js/components/chat/settings/notification/StoreChatNotification.vue'),
    name: 'StoreChatNotification',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/notifications/:item_id/update',
    component: () => import('@/js/components/chat/settings/notification/UpdateChatNotification.vue'),
    name: 'UpdateChatNotification',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
