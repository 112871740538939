import { ChatSubscriberFilterModel } from '@/js/models/chat/subscriber/ChatSubscriberFilterModel'

const initialState = () => {
  return {
    chat_subscriber_filters: [],
    chat_subscriber_filters_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_subscriber_filters_loaded = isLoaded
    },

    setFilters(state, filters) {
      state.chat_subscriber_filters = []

      filters.forEach(({ id, name }) =>
        state.chat_subscriber_filters.push({ id: id, name: name })
      )
    },

    addFilter(state, filter) {
      if (state.chat_subscriber_filters.find((x) => x.id === filter.id)) return

      state.chat_subscriber_filters.unshift(filter)
    },

    deleteFilter(state, id) {
      let index = state.chat_subscriber_filters.findIndex(
        (filter) => filter.id === id
      )

      if (index === -1) return

      state.chat_subscriber_filters.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchFilters({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatSubscriberFilterModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setFilters', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch((error) => {
            dispatch('resetFilters')
            return reject(error)
          })
      })
    },

    setFilters({ commit }, filters) {
      commit('setFilters', filters)

      commit('setLoaded', true)
    },

    addFilter({ commit }, filter) {
      commit('addFilter', filter)
    },

    deleteFilter({ commit }, id) {
      commit('deleteFilter', id)
    },

    resetFilters({ commit }) {
      commit('reset')
    }
  }
}
