export class ChatMessageTimetableModel {
  constructor(props) {
    this.form = {
      datetime: null,
      timezone: null
    }
  }

  static get timeOptions() {
    return {
      start: '00:00',
      step: '00:01',
      end: '23:00'
    }
  }
}
