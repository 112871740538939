import { i18n } from '@/js/plugins/i18n'
import { Model } from '@/js/models/Model'

export class ChatMessageTypeModel extends Model {
  static PAGE_NOTIFICATION = -1
  static PAGE_QUESTIONARY = -2

  static SCHEDULE = 1

  static RIGHT_NOW = 2

  static WELCOME = 3

  static TRIGGER = 4

  static COMMAND = 5

  static KEYWORD = 6

  static TEST = 7

  static QUESTIONARY = 8

  static MENU = 9

  static NOTIFICATION = 10

  static SUBSCRIPTION_INFO = 11

  static QUICK_REPLY = 12

  static get types() {
    return [
      {
        value: this.SCHEDULE,
        label: i18n.t('main-words.auto-funnels'),
        iconNew: 'icon-scenarios',
        image: new URL("@/assets/icons/chat/message/form/plan-mail.svg", import.meta.url).href,
        imagePrimary: new URL("@/assets/icons/chat/message/form/plan-mail-primary.svg", import.meta.url).href,
        saveTitle: i18n.t('bot.message.form.save.schedule'),
        saveName: i18n.t('bot.message.form.name.schedule'),
        faq: i18n.t('services.faq.bot.message.type.schedule')
      },
      {
        value: this.TRIGGER,
        label: i18n.t('models.chat.message.type.trigger'),
        iconNew: 'icon-triggers',
        icon: 'el-icon-alarm-clock',
        saveTitle: i18n.t('bot.message.form.save.trigger'),
        saveName: i18n.t('bot.message.form.name.trigger'),
        faq: i18n.t('services.faq.bot.message.type.trigger')
      },
      {
        value: this.KEYWORD,
        label: i18n.t('models.chat.message.type.keyword'),
        iconNew: 'icon-keyWords',
        image: new URL("@/assets/icons/chat/message/form/keyword-mail.svg", import.meta.url).href,
        imagePrimary: new URL("@/assets/icons/chat/message/form/keyword-mail-primary.svg", import.meta.url).href,
        saveTitle: i18n.t('bot.message.form.save.right-now'),
        saveName: i18n.t('bot.message.form.name.right-now'),
        faq: i18n.t('services.faq.bot.message.type.keyword')
      },
      {
        value: this.RIGHT_NOW,
        label: i18n.t('models.chat.message.type.right-now'),
        image: new URL("@/assets/icons/chat/message/form/right-now-mail.svg", import.meta.url).href,
        imagePrimary: new URL("@/assets/icons/chat/message/form/right-now-mail-primary.svg", import.meta.url).href,
        saveTitle: i18n.t('bot.message.form.save.right-now'),
        saveName: i18n.t('bot.message.form.name.right-now'),
        faq: i18n.t('services.faq.bot.message.type.right-now'),
        faqGroup: i18n.t('services.faq.bot.message.type.right-now-group')
      },
      {
        value: this.COMMAND,
        label: i18n.t('models.chat.message.type.command'),
        iconNew: 'icon-menu-command',
        icon: 'el-icon-mobile',
        saveTitle: i18n.t('bot.message.form.save.command'),
        saveName: i18n.t('bot.message.form.name.right-now'),
        faq: i18n.t('services.faq.bot.message.type.command')
      },
      {
        value: this.MENU,
        label: i18n.t('models.chat.message.type.menu'),
        iconNew: 'icon-buttonsNav',
        image: new URL("@/assets/icons/chat/message/form/menu.svg", import.meta.url).href,
        imagePrimary: new URL("@/assets/icons/chat/message/form/menu-primary.svg", import.meta.url).href,
        saveTitle: i18n.t('bot.message.form.save.menu'),
        saveName: i18n.t('bot.message.form.name.menu'),
        faq: i18n.t('services.faq.bot.message.type.menu')
      },
      {
        value: this.QUICK_REPLY,
        label: i18n.t('models.chat.message.type.quick-reply'),
        iconNew: 'icon-quickAnswers',
        image: new URL("@/assets/icons/chat/message/form/quick-reply.svg", import.meta.url).href,
        imagePrimary: new URL("@/assets/icons/chat/message/form/quick-reply-primary.svg", import.meta.url).href,
        saveTitle: i18n.t('bot.message.form.save.quick-reply'),
        saveName: i18n.t('bot.message.form.name.quick-reply'),
        faq: i18n.t('services.faq.bot.message.type.quick-reply')
      }
    ]
  }

  static get typeIdsNotForTemplate() {
    return [
      this.TEST,
      this.QUESTIONARY,
      this.NOTIFICATION,
      this.SUBSCRIPTION_INFO
    ]
  }

  static get typesIdsSystem() {
    return [
      this.TEST,
      this.QUESTIONARY,
      this.NOTIFICATION,
      this.SUBSCRIPTION_INFO
    ]
  }

  static getSaveName(typeId) {
    return typeId
      ? this.types.find((x) => x.value === +typeId).saveName
      : i18n.t('main-words.title')
  }

  static getSaveTitle(typeId) {
    return typeId
      ? this.types.find((x) => x.value === +typeId).saveTitle
      : i18n.t('main-words.save')
  }

  static getFolderTitle(typeId) {
    switch (+typeId) {
      case this.SCHEDULE:
        return i18n.t('main-words.auto-funnel')
      case this.MENU:
        return i18n.t('main-words.menu')
      default:
        return i18n.t('main-words.folder')
    }
  }

  static hasInterval(typeId) {
    return [this.SCHEDULE, this.TRIGGER, this.KEYWORD].includes(+typeId)
  }

  static hasSort(typeId) {
    return [this.TRIGGER, this.COMMAND, this.MENU].includes(+typeId)
  }

  static hasFolderPage(typeId) {
    return [this.SCHEDULE, this.MENU].includes(+typeId)
  }

  static hasFolderFilter(typeId) {
    return [
      this.RIGHT_NOW,
      this.TRIGGER,
      this.COMMAND,
      this.KEYWORD,
      this.QUICK_REPLY
    ].includes(+typeId)
  }

  static canChangeFolder(typeId) {
    return [
      this.RIGHT_NOW,
      this.TRIGGER,
      this.COMMAND,
      this.KEYWORD,
      this.QUICK_REPLY
    ].includes(+typeId)
  }
}
