export const chatSubscriberBaseStageRoutes = [
  {
    path: 'chat/:chat_id/subscriber-base/stages/store',
    component: () => import('@/js/components/chat/subscriberBase/stage/StoreChatSubscriberStage.vue'),
    name: 'StoreChatSubscriberStage',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/subscriber-base/stages/:item_id/update',
    component: () => import('@/js/components/chat/subscriberBase/stage/UpdateChatSubscriberStage.vue'),
    name: 'UpdateChatSubscriberStage',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
