import {
    chatRoutes
} from '@/js/routes/chat/routes'
import {
    chatSubscriberBaseRoutes
} from '@/js/routes/chat/subscriberBase/routes'
import {
    chatContentRoutes
} from '@/js/routes/chat/content/routes'
import {
    authRoutes
} from '@/js/routes/auth/routes'
import {
    userRoutes
} from '@/js/routes/user/routes'

import {
    adminSuperRoutes
} from '@/js/routes/admin/super/routes'
import {
    adminWpRoutes
} from '@/js/routes/admin/wp/routes'
import {
    adminWhiteLabelRoutes
} from '@/js/routes/admin/whitelabel/routes'
import {
    adminSupportRoutes
} from '@/js/routes/admin/support/routes'
import {
    adminGuideRoutes
} from '@/js/routes/admin/guide/routes'

import {
    publicRoutes
} from '@/js/routes/chat/public/routes'
import {
    conferenceRoutes
} from '@/js/routes/conference/routes'
import {
    webappRoutes
} from '@/js/routes/webapp/routes'

export const mainRoutes = [
    ...publicRoutes,
    ...conferenceRoutes,
    ...webappRoutes,
    ...[{
            path: '/p/:promocode',
            component: () => import('@/js/components/auth/RegisterRef.vue'),
            name: 'RegisterRefRU',
            meta: {
                requireGuest: true
            },
            props: true
        },
        {
            path: '/:lang',
            component: {
                template: '<router-view></router-view>'
            },
            children: [
                ...adminSuperRoutes,
                ...adminWpRoutes,
                ...adminWhiteLabelRoutes,
                ...adminSupportRoutes,
                ...adminGuideRoutes,

                ...authRoutes,
                ...userRoutes,

                ...chatRoutes,
                ...chatSubscriberBaseRoutes,
                ...chatContentRoutes,
                ...[{
                    path: 'test/browser',
                    component: () => import('@/js/components/test/TestBrowser.vue'),
                    name: 'TestBrowser',
                    meta: {
                        requireAuth: true
                    },
                    props: true
                },
                    {
                        path: 'partnership/:page',
                        component: () => import('@/js/components/partnership/IndexPartnership.vue'),
                        name: 'IndexPartnership',
                        meta: {
                            requireAuth: true,
                            requirePartnership: true,
                            requireNoWp: true
                        },
                        props: true
                    },
                    {
                        path: 'p/:promocode',
                        component: () => import('@/js/components/auth/RegisterRef.vue'),
                        name: 'RegisterRef',
                        meta: {
                            requireGuest: true
                        },
                        props: true
                    }
                ]
            ]
        }
    ]
]
