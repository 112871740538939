import { ChatWebAppModel } from '@/js/models/chat/webapp/ChatWebAppModel'

const initialState = () => {
  return {
    chat_web_apps: [],
    chat_web_apps_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_web_apps_loaded = isLoaded
    },

    setWebApps(state, webApps) {
      state.chat_web_apps = []

      webApps.forEach(({ id, name }) =>
        state.chat_web_apps.push({ id: id, name: name })
      )
    },

    deleteWebApp(state, id) {
      let index = state.chat_web_apps.findIndex((item) => item.id === id)

      if (index === -1) return

      state.chat_web_apps.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchWebApps({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatWebAppModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setWebApps', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetWebApps')

            return reject(error)
          })
      })
    },

    setWebApps({ commit }, webApps) {
      commit('setWebApps', webApps)

      commit('setLoaded', true)
    },

    deleteWebApp({ commit }, id) {
      commit('deleteWebApp', id)
    },

    resetWebApps({ commit }) {
      commit('reset')
    }
  }
}
