import {ChatModel} from '@/js/models/chat/ChatModel'
import {LandingTypeModel} from '@/js/models/landing/LandingTypeModel'
import {LandingEditorTypeModel} from '@/js/models/landing/LandingEditorTypeModel'

export class LandingModel {
    static URL = 'landings'
    static JSON_KEYS = ['actions']
    static BOOLEAN_KEYS = ['allow_edit', 'has_lesson_description']

    constructor() {
        this.searchForm = {
            folder_id: null
        }

        this.form = {
            id: null,
            chat_id: null,
            folder_id: null,
            filter_id: null,
            redirect_landing_id: null,
            editor_type_id: LandingEditorTypeModel.QUILL,
            type_id: LandingTypeModel.DEFAULT,
            name: null,
            description: null,
            html: null,
            allow_edit: false,
            actions: [],
            is_inside: 0,
            has_lesson_description: false
        }
    }

    static urlForWebApp()
    {
        return '/webapp/landing';
    }

    static url(chat_id) {
        return `${ChatModel.URL}/${chat_id}/landings`
    }

    static getName({name}) {
        return name.length < 35 ? name : name.substr(0, 35) + '...'
    }

    static isPrivate({type_id}) {
        return type_id === LandingTypeModel.PRIVATE
    }

    static getPublicUrl(uuid, chatDomain = null) {
        const host = chatDomain ? chatDomain.url : window.location.host

        return `https://${host}/landing/${uuid}`
    }
}
