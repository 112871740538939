import { i18n } from '@/js/plugins/i18n'
import { Model } from '@/js/models/Model'

export class LandingEditorTypeModel extends Model {
  static CONTENT_BOX = 1

  static QUILL = 2

  static CONTENT_BOX_CSS_SCRIPTS = [
    'https://my.winwinbot.com/content-box-js/box/box.css',
    'https://my.winwinbot.com/content-box-js/assets/minimalist-blocks/content.css',
    'https://my.winwinbot.com/content-box-js/assets/scripts/simplelightbox/simplelightbox.css'
  ]

  static CONTENT_BOX_CSS_EDIT_SCRIPTS = [
    'https://my.winwinbot.com/content-box-js/contentbuilder/contentbuilder.css',
    'https://my.winwinbot.com/content-box-js/contentbox/contentbox.css'
  ]

  static CONTENT_BOX_JS_SCRIPTS = [
    'https://my.winwinbot.com/content-box-js/contentbuilder/jquery.min.js',
    'https://my.winwinbot.com/content-box-js/box/box.js',
    'https://my.winwinbot.com/content-box-js/assets/scripts/simplelightbox/simple-lightbox.min.js'
  ]

  static QUILL_CSS_SCRIPTS = [
    'https://cdn.quilljs.com/1.3.6/quill.snow.css',
    'https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.7.1/katex.min.css',
    'https://fonts.googleapis.com/css?family=Roboto',
    'https://fonts.googleapis.com/css?family=Tinos',
    'https://fonts.googleapis.com/css?family=Open+Sans',
    'https://fonts.googleapis.com/css?family=Montserrat'
  ]

  static QUILL_JS_SCRIPTS = [
    'https://cdn.quilljs.com/1.3.6/quill.js',
    'https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.7.1/katex.min.js'
  ]

  static get types() {
    return [
      {
        label: i18n.t('models.landing.editor-type.content-box'),
        value: this.CONTENT_BOX
      },
      {
        label: i18n.t('models.landing.editor-type.quill'),
        value: this.QUILL
      }
    ]
  }
}
