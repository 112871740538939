export const chatGroupRoutes = [
  {
    path: 'chat/:chat_id/groups/:group_id/subs',
    component: () => import('@/js/components/chat/settings/group/subs/IndexChatGroupSubscriber.vue'),
    name: 'IndexChatGroupSubscriber',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/groups/:group_id/invites',
    component: () => import('@/js/components/chat/settings/group/invite/IndexChatGroupInvite.vue'),
    name: 'IndexChatGroupInvite',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/groups',
    component: () => import('@/js/components/chat/settings/group/IndexChatGroup.vue'),
    name: 'IndexChatGroup',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/groups/:item_id/update',
    component: () => import('@/js/components/chat/settings/group/UpdateChatGroup.vue'),
    name: 'UpdateChatGroup',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
