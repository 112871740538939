import { UserModel } from '@/js/models/user/UserModel'
import { SubscriptionTypeModel } from '@/js/models/subscription/SubscriptionTypeModel'

export class SubscriptionModel {
  static URL = `${UserModel.URL}/subscription`

  constructor() {
    this.form = {
      id: null,
      user_id: null,
      type_id: null,
      type: new SubscriptionTypeModel().form
    }
  }
}
