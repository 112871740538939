import { ChatModel } from '@/js/models/chat/ChatModel'

export class ChatTournamentModel {
  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/tournaments`
  }
  constructor() {
    this.form = {
      id: null,
      chat_id: null,
      name: null,
      prop: null,
      segment_id: null,
      limit: 1,
      prize_limit: 1
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }

  static getPublicUrl(uuid, chatDomain = null) {
    const host = chatDomain ? chatDomain.url : window.location.host

    return `https://${host}/tournament/${uuid}`
  }
}
