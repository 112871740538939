function initialState() {
  return {
    pwa: null,
    pwaFail: localStorage.getItem('pwa_fail'),
    pwaSuccess: localStorage.getItem('pwa_success')
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setPwa(state, value) {
      state.pwa = value
    },

    setSuccess(state) {
      state.pwaSuccess = 1

      localStorage.setItem('pwa_success', state.pwaSuccess)
    },

    setFail(state) {
      state.pwaFail = 1

      localStorage.setItem('pwa_fail', state.pwaFail)
    }
  },
  actions: {
    setPwa({ commit }, data) {
      commit('setPwa', data)
    },

    setPwaFail({ commit }) {
      commit('setFail')
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  }
}
