export const adminWhiteLabelSubscriptionPreviewRoutes = [
  {
    path: 'admin-wl/subscription/previews',
    component: () => import('@/js/components/admin/whitelabel/subscription/preview/IndexAdminWhiteLabelSubscriptionPreview.vue'),
    name: 'IndexAdminWhiteLabelSubscriptionPreview',
    meta: {
      requireAdminWhiteLabel: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-wl/subscription/previews/store',
    component: () => import('@/js/components/admin/whitelabel/subscription/preview/StoreAdminWhiteLabelSubscriptionPreview.vue'),
    name: 'StoreAdminWhiteLabelSubscriptionPreview',
    meta: {
      requireAdminWhiteLabel: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-wl/subscription/previews/:item_id/update',
    component: () => import('@/js/components/admin/whitelabel/subscription/preview/UpdateAdminWhiteLabelSubscriptionPreview.vue'),
    name: 'UpdateAdminWhiteLabelSubscriptionPreview',
    meta: {
      requireAdminWhiteLabel: true,
      requireAuth: true
    },
    props: true
  }
]
