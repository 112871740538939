import { i18n } from '@/js/plugins/i18n'

import {
  maxLengthCountMessage,
  maxNewLinesMessage,
  platformTextLengthValidator,
  requiredTextMessage
} from '@/js/services/ValidationRuleService'

import { QuestionTypeModel } from '@/js/models/question/QuestionTypeModel'
import { ChatPlatformModel } from '@/js/models/chat/ChatPlatformModel'
import { DataTypeModel } from '@/js/models/dataType/DataTypeModel'
import { QuestionAnswerModel } from '@/js/models/question/QuestionAnswerModel'
import { UploadModel } from '@/js/models/upload/UploadModel'
import { ChatMessageButtonModel } from '@/js/models/chat/message/button/ChatMessageButtonModel'
import { ChatMessageModel } from '@/js/models/chat/message/ChatMessageModel'

export class QuestionModel {
  static MAX_COUNT = 500

  constructor(type_id) {
    let messageButton = null

    let messages = []

    let answer = new QuestionAnswerModel().form

    let answers = []

    let answerField = null

    switch (type_id) {
      case QuestionTypeModel.SINGLE:
      case QuestionTypeModel.MULTIPLE:
        answers = [answer]

        answerField = {
          prop: null,
          type_id: DataTypeModel.TEXT
        }

        break
      case QuestionTypeModel.QUIZ:
        let answerOne = new QuestionAnswerModel().form

        let answerTwo = new QuestionAnswerModel().form

        answers = [answerOne, answerTwo]

        break
      case QuestionTypeModel.FINAL:
      case QuestionTypeModel.TIMEOUT:
        answerField = {
          prop: null,
          type_id: DataTypeModel.TEXT
        }

        break
      case QuestionTypeModel.REQUEST_PHONE:
        answer.text = i18n.t('main-words.send-phone-number')

        answers = [answer]

        answerField = {
          prop: ChatPlatformModel.PROP_PHONE,
          type_id: DataTypeModel.TEXT
        }

        break
      case QuestionTypeModel.REQUEST_EMAIL:
        answers = [answer]

        answerField = {
          prop: ChatPlatformModel.PROP_EMAIL,
          type_id: DataTypeModel.TEXT
        }

        break
      case QuestionTypeModel.REQUEST_UPLOAD:
        answers = [answer]

        answerField = {
          prop: null,
          type_id: DataTypeModel.UPLOAD
        }

        break
      case QuestionTypeModel.MESSAGE_LANDING:
      case QuestionTypeModel.MESSAGE_LINK:
        messageButton = new ChatMessageButtonModel().form

        answerField = {
          prop: null,
          type_id: DataTypeModel.TEXT
        }

        break
      case QuestionTypeModel.MESSAGE_RANDOM:
        let message = new ChatMessageModel().form

        messages.push(message)

        answerField = {
          prop: null,
          type_id: DataTypeModel.TEXT
        }

        break
    }

    let upload = new UploadModel().form

    upload.fileList = null

    this.form = {
      id: Date.now(),
      type_id: type_id,

      text: {
        html: null,
        json: null
      },

      has_link_preview: false,
      is_private: false,

      has_upload: false,
      upload: upload,

      text_explanation: null,

      answer_field: answerField,

      correct_answer: null,

      answers: answers,

      points_lower_limit: null,
      points_upper_limit: null,

      message: { buttons: messageButton ? [messageButton] : [] },
      messages: messages,

      delay: null,
      delay_typing: null,

      next_questionary_id: null,
      filter_id: null,
      gpt_id: null,
      gpt_has_sub_info: false,

      timeout: null,
      timeout_next_question: null,

      proposal_complete: true,
      proposal_field_uuid: null
    }
  }

  static TEXT_RULES = (platformId, question = null, questionMessage = null) => {
    return [
      {
        validator: (rule, value, callback) => {
          const length = value ? value.length : 0
          let textLimit = 0

          switch (platformId) {
            case ChatPlatformModel.TELEGRAM:
              textLimit = length - 255

              if (
                question &&
                question.type_id === QuestionTypeModel.QUIZ &&
                textLimit > 0
              ) {
                return callback(
                  new Error(
                    i18n.t(maxLengthCountMessage, {
                      count: textLimit,
                      word: i18n.tc('tc.characters', textLimit)
                    })
                  )
                )
              }

              textLimit = length - 4000

              if (textLimit > 0) {
                return callback(
                  new Error(
                    i18n.t(maxLengthCountMessage, {
                      count: textLimit,
                      word: i18n.tc('tc.characters', textLimit)
                    })
                  )
                )
              }

              break
            case ChatPlatformModel.VIBER:
            case ChatPlatformModel.VK:
              textLimit = length - 4000

              if (textLimit > 0) {
                return callback(
                  new Error(
                    i18n.t(maxLengthCountMessage, {
                      count: textLimit,
                      word: i18n.tc('tc.characters', textLimit)
                    })
                  )
                )
              }

              break
            case ChatPlatformModel.SLACK:
              textLimit = length - 145

              if (
                question &&
                question.type_id === QuestionTypeModel.MULTIPLE &&
                textLimit > 0
              ) {
                return callback(
                  new Error(
                    i18n.t(maxLengthCountMessage, {
                      count: textLimit,
                      word: i18n.tc('tc.characters', textLimit)
                    })
                  )
                )
              }

              textLimit = length - 4000

              if (textLimit > 0) {
                return callback(
                  new Error(
                    i18n.t(maxLengthCountMessage, {
                      count: textLimit,
                      word: i18n.tc('tc.characters', textLimit)
                    })
                  )
                )
              }

              break
            case ChatPlatformModel.FACEBOOK:
            case ChatPlatformModel.INSTAGRAM:
              textLimit = length - 1000

              if (textLimit > 0) {
                return callback(
                  new Error(
                    i18n.t(maxLengthCountMessage, {
                      count: textLimit,
                      word: i18n.tc('tc.characters', textLimit)
                    })
                  )
                )
              }

              break
          }

          const hasUpload = question?.has_upload || questionMessage?.has_upload
          const uploadFileTypeId =
            question?.upload?.file_type_id ||
            questionMessage?.upload?.file_type_id

          const hasButton =
            questionMessage?.has_button ||
            QuestionTypeModel.hasMessageButton(question?.type_id)

          platformTextLengthValidator(
            length,
            platformId,
            callback,
            hasButton,
            hasUpload ? uploadFileTypeId : null,
            value
          )
        },
        trigger: 'blur'
      }
    ]
  }

  static TEXT_EXPLANATION_RULES = [
    {
      validator: (rule, value, callback) => {
        if (!value) {
          return callback()
        }

        if (value.length > 200) {
          return callback(new Error(i18n.t(requiredTextMessage)))
        }

        if (value.split('\n').length > 2) {
          return callback(new Error(i18n.t(maxNewLinesMessage)))
        }

        callback()
      },
      trigger: 'blur'
    }
  ]

  static hasDelay({ delay }) {
    return delay !== null && delay !== '00:00:00'
  }

  static formatDelayText(delay) {
    const time = delay.split(':')

    const hours = +time[0]

    const minutes = +time[1]

    const seconds = +time[2]

    let text = ''

    if (hours > 0) {
      text += `${+hours} ${i18n.t('main-words.hour-short')} `
    }

    if (minutes > 0) {
      text += `${+minutes} ${i18n.t('main-words.minute-short')} `
    }

    if (seconds > 0) {
      text += `${+seconds} ${i18n.t('main-words.sec')} `
    }

    return text
  }

  static updateUploadUrl(question) {
    if (
      question.upload &&
      question.upload.fileList &&
      question.upload.fileList[0].raw
    ) {
      question.upload.fileList[0].url = URL.createObjectURL(
        question.upload.fileList[0].raw
      )
    }

    question.messages.forEach((message) => {
      if (
        message.upload &&
        message.upload.fileList &&
        message.upload.fileList[0].raw
      ) {
        message.upload.fileList[0].url = URL.createObjectURL(
          message.upload.fileList[0].raw
        )
      }
    })

    return question
  }
}
