export const adminUserGoalRoutes = [
  {
    path: 'admin-guide/goals',
    component: () => import('@/js/components/admin/super/user/goal/IndexAdminUserGoal.vue'),
    name: 'IndexAdminUserGoal',
    meta: {
      requireAdminUserGuide: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-guide/goals/store',
    component: () => import('@/js/components/admin/super/user/goal/StoreAdminUserGoal.vue'),
    name: 'StoreAdminUserGoal',
    meta: {
      requireAdminUserGuide: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-guide/goals/:item_id/update',
    component: () => import('@/js/components/admin/super/user/goal/UpdateAdminUserGoal.vue'),
    name: 'UpdateAdminUserGoal',
    meta: {
      requireAdminUserGuide: true,
      requireAuth: true
    },
    props: true
  }
]
