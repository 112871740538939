import Vue from 'vue'
import ElementUI from 'element-ui'
import '@/sass/_variables.scss'
import '@/assets/style-UI-winwinbot/theme/index.css'

import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import store from '@/js/store/index'
import router from '@/js/plugins/router'
import '@/js/plugins/axios'

import MainApp from '@/js/components/main/MainApp.vue'
import MainPublic from '@/js/components/main/MainPublic.vue'
import MainPublicReferral from '@/js/components/main/MainPublicReferral.vue'
import MainConference from '@/js/components/main/MainConference.vue'
import WebApp from "@/js/wrappers/WebApp.vue";

import Clipboard from 'v-clipboard'
import VueChatScroll from 'vue-chat-scroll'
import vClickOutside from 'v-click-outside'
import AVPlugin from "vue2-audio-visual";

import { i18n } from '@/js/plugins/i18n'
import { Trans } from '@/js/plugins/translation'

import { SubDomainModel } from '@/js/models/SubDomainModel'

import * as Sentry from "@sentry/vue";

const reloadErrors = [
    /Failed to fetch dynamically imported module/i,
    /Failed to load module script/i,
    /ChunkLoadError/i,
    /Unable to preload CSS/i,
    /Importing a module script failed/i,
    /error loading dynamically imported module/i,
    /Unexpected token/i,
    /is not a valid JavaScript MIME type/i,
    /Expected a JavaScript module script but the server responded with a MIME type/i,
    /SyntaxError/i,
    /is not a valid JavaScript MIME type/i,
];

const mutedErrors = [
    /not validate/i,
    /Request failed with status code/i,
    /__hs is not a function/i,
    /Non-Error promise rejection captured with value/i,
    /NavigationDuplicated/i,
    /Avoided redundant navigation to current location/i,
    /window.TelegramGameProxy.receiveEvent/i,
];

function handleErrorReload(error)
{
    if(error)
    {
        const text = error.message ?? error.reason ?? error.toString();
        if (reloadErrors.some(regex => regex.test(text)))
        {
            window.location.reload();
        }
    }
}

window.addEventListener('error', event => {
    if (event.error) handleErrorReload(event.error);
});
window.addEventListener('unhandledrejection', event => {
    if (event.reason) handleErrorReload(event.reason);
});

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.use(VueChatScroll)
Vue.use(Clipboard)
Vue.use(vClickOutside)
Vue.use(PiniaVuePlugin)
Vue.use(AVPlugin)

Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
})

Vue.directive('cancelReadOnly', {
    inserted: function(el) {
        const input = el.querySelector('.el-input__inner')
        input.removeAttribute('readonly')
    }
})

Sentry.init({
    Vue,
    dsn: "https://f96d3deb64854cbdb9849cdf7bfbc07a@glitch.winwinbot.com/2",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/my\.winwinbot\.com\/api/],
    ignoreErrors: [
        ...reloadErrors,
        ...mutedErrors
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [
        /^https?:\/\/127\.0\.0\.1(:\d+)?$/,
        /^https?:\/\/(www\.)?testovoe-zadanie\.ru/
    ]
});

window.baseOrigin = () =>
    window.location.host === SubDomainModel.WINWINBOT_MOBILE_URL
        ? `https://${SubDomainModel.WINWINBOT_URL}`
        : window.location.origin

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    components: { MainApp, MainPublic, MainPublicReferral, MainConference, WebApp },
    pinia
})
