import { i18n } from '@/js/plugins/i18n'
import { Model } from '@/js/models/Model'

export class ChatMessageStatusModel extends Model {
  static ACTIVE = 1

  static PAUSE = 2

  static DELETED_FILTER = 3

  static DELETED_DEPENDENCIES = 4

  static DRAFT = 5

  static get types() {
    return [
      {
        value: this.ACTIVE,
        label: i18n.t('models.chat.message.status.active')
      },
      {
        value: this.PAUSE,
        label: i18n.t('models.chat.message.status.pause')
      },
      {
        value: this.DELETED_FILTER,
        label: i18n.t('models.chat.message.status.deleted-filter')
      },
      {
        value: this.DELETED_DEPENDENCIES,
        label: i18n.t('models.chat.message.status.deleted-dependencies')
      },
      {
        value: this.DRAFT,
        label: i18n.t('models.chat.message.status.draft')
      }
    ]
  }

  static getIconById(id) {
    switch (id) {
      case this.ACTIVE:
        return 'el-icon-video-pause'
      case this.PAUSE:
      case this.DRAFT:
        return 'el-icon-video-play'
      case this.DELETED_FILTER:
      case this.DELETED_DEPENDENCIES:
        return 'el-icon-warning'
    }
  }

  static getColorById(id) {
    switch (id) {
      case this.ACTIVE:
        return 'success'
      case this.PAUSE:
        return 'warning'
      case this.DELETED_FILTER:
      case this.DELETED_DEPENDENCIES:
        return 'danger'
    }
  }

  static getColorHexById(id) {
    switch (id) {
      case this.ACTIVE:
        return '#51C639'
      case this.PAUSE:
        return '#FFBA18'
      case this.DELETED_FILTER:
      case this.DELETED_DEPENDENCIES:
        return '#FF4C4B'
    }
  }
}
