import { i18n } from '@/js/plugins/i18n'
import { Model } from '@/js/models/Model'

export class DataTypeModel extends Model {
  static TEXT = 1

  static INT = 2

  static DATE = 3

  static UPLOAD = 4

  static get types() {
    return [
      {
        value: this.TEXT,
        label: i18n.t('models.chat.subscriber.form.type.text')
      },
      {
        value: this.INT,
        label: i18n.t('models.chat.subscriber.form.type.number')
      },
      {
        value: this.DATE,
        label: i18n.t('models.chat.subscriber.form.type.date')
      },
      {
        value: this.UPLOAD,
        label: i18n.t('models.chat.subscriber.form.type.upload')
      }
    ]
  }
}
