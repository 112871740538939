import { ticketMap, ticketUpgradeMap } from '@/js/constants/ticketsInfo'
import {
  availableIntensivesByTicket,
  intensivesMap
} from '@/js/constants/productInfo'

const initialState = () => {
  return {
    subscriber: {},
    products: [],
    conference_loaded: false,
    visitedProducts: JSON.parse(
      localStorage.getItem('visited_products') || '[]'
    ),
    isGoFromMyProducts: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    purchasedProducts(state) {
      return state.products.filter((item) => item.payments_count)
    },
    purchasedTickets(state, getters) {
      const result = []
      if (
        getters.purchasedProducts.find(
          (item) =>
            item.name === ticketMap.premium ||
            item.name === ticketUpgradeMap.vip_premium ||
            item.name === ticketUpgradeMap.standart_premium
        )
      ) {
        result.push('premium')
      }
      if (
        getters.purchasedProducts.find(
          (item) =>
            item.name === ticketMap.vip ||
            item.name === ticketUpgradeMap.standart_vip
        )
      ) {
        result.push('vip')
      }
      if (
        getters.purchasedProducts.find(
          (item) => item.name === ticketMap.standart
        )
      ) {
        result.push('standart')
      }
      result.push('free')
      return result
    },
    activeTicket(state, getters) {
      return getters.purchasedTickets.length
        ? getters.purchasedTickets[0]
        : 'free'
    },
    intensives(state) {
      const intensives = []
      state.products.forEach((item) => {
        Object.keys(intensivesMap).forEach((key) => {
          if (item.name === intensivesMap[key]) {
            intensives.push({ ...item, idMap: key })
          }
        })
      })
      return intensives
    },
    isAvailableIntensive: (state, getters) => (id) => {
      const currentIntensive = getters.intensives.find(
        (item) => item.idMap === id
      )
      return (
        availableIntensivesByTicket[getters.activeTicket]?.includes(id) ||
        !!currentIntensive?.payments_count
      )
    }
  },
  mutations: {
    setLoaded(state, isLoaded) {
      state.conference_loaded = isLoaded
    },

    setConference(state, { subscriber, products }) {
      state.subscriber = subscriber
      state.products = products
    },

    setVisitedProduct(state, id) {
      if (!state.visitedProducts.includes(id)) {
        state.visitedProducts.push(id)
        localStorage.setItem(
          'visited_products',
          JSON.stringify(state.visitedProducts)
        )
      }
    },
    setIsGoFromMyProducts(state, value) {
      state.isGoFromMyProducts = value
    }
  },
  actions: {
    setConference({ commit }, conference) {
      commit('setConference', conference)
      commit('setLoaded', true)
    },
    setVisitedProduct({ commit }, id) {
      commit('setVisitedProduct', id)
    },
    setIsGoFromMyProducts({ commit }, value) {
      commit('setIsGoFromMyProducts', value)
    }
  }
}
