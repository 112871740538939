import { ChatTournamentModel } from '@/js/models/chat/tournament/ChatTournamentModel'

const initialState = () => {
  return {
    chat_tournaments: [],
    chat_tournaments_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_tournaments_loaded = isLoaded
    },

    setTournaments(state, tournaments) {
      state.chat_tournaments = tournaments.map(({ id, name }) => ({
        id,
        name
      }))
    },

    deleteTournament(state, id) {
      let index = state.chat_tournaments.findIndex((item) => item.id === id)

      if (index === -1) {
        return
      }

      state.chat_tournaments.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchTournaments({ commit, dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatTournamentModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setTournaments', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetTournaments')

            return reject(error)
          })
      })
    },

    setTournaments({ commit }, tournaments) {
      commit('setTournaments', tournaments)

      commit('setLoaded', true)
    },

    deleteTournament({ commit }, id) {
      commit('deleteTournament', id)
    },

    resetTournaments({ commit }) {
      commit('reset')
    }
  }
}
