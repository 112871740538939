import { ChatProposalModel } from '@/js/models/chat/proposal/ChatProposalModel'

const initialState = () => {
  return {
    chat_proposals: [],
    chat_proposals_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_proposals_loaded = isLoaded
    },

    setProposals(state, proposals) {
      state.chat_proposals = []

      proposals.forEach(({ id, name, folder_id, fields }) =>
        state.chat_proposals.push({
          id: id,
          name: name,
          folder_id: folder_id,
          fields: fields
        })
      )
    },

    deleteProposal(state, id) {
      let index = state.chat_proposals.findIndex((item) => item.id === id)

      if (index === -1) return

      state.chat_proposals.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchProposals({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatProposalModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setProposals', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetProposals')
            return reject(error)
          })
      })
    },

    setProposals({ commit }, proposals) {
      commit('setProposals', proposals)

      commit('setLoaded', true)
    },

    deleteProposal({ commit }, id) {
      commit('deleteProposal', id)
    },

    resetProposals({ commit }) {
      commit('reset')
    }
  }
}
