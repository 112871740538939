import { defineStore } from 'pinia';
import { useWebApp } from "@/js/store/pinia/webapp/WebApp.js";
import { useWebAppSubscriber } from "@/js/store/pinia/webapp/WebAppSubscriber.js";
import router from '@/js/plugins/router'

export const useWebAppTelegram = defineStore('webAppTelegramStore', {
    state: () => ({
        timer: null,
        user: null,
        params: {},
        isFullscreen: false,
        apiVersion: 6.0,
        isOpenedInWebApp: false,
        sefaPaddingTop: {
            content: 0,
            navigation: 0
        },
        initialRoute: null,
        isInit: false,
    }),
    getters:
    {
        paddingTop() {
            return this.isOpenedInWebApp ? this.sefaPaddingTop.content : 0;
        }
    },
    actions:
    {
        async init()
        {
            this.initialRoute = router.currentRoute.path;
            this.timer = setInterval(() => this.checkTelegramJsApi(), 500);
            await this.checkTelegramJsApi();
        },

        async checkTelegramJsApi()
        {
            if (this.isInit)
            {
                if(this.timer) clearInterval(this.timer);
                return;
            }

            const subscriber = useWebAppSubscriber();
            const telegramUser = window.Telegram?.WebApp?.initDataUnsafe;

            if (telegramUser)
            {
                try
                {
                    clearInterval(this.timer);
                    if (telegramUser.hasOwnProperty('user'))
                    {
                        this.setTelegramParams();
                        await subscriber.init(telegramUser.user.id);
                        this.user = telegramUser.user;
                        this.parseParams();
                        this.allowMessage();
                    }
                }
                catch (e) {}
                finally
                {
                    this.isInit = true;
                }
            }
        },

        parseParams()
        {
            let query = window.Telegram?.WebView?.initParams.tgWebAppData;
            const params = new URLSearchParams(query);
            for (const [key, value] of params.entries()) {
                this.params[key] = value;
            }
        },

        setTelegramParams()
        {
            try
            {
                this.apiVersion = parseFloat(window.Telegram.WebApp.version);
                this.isOpenedInWebApp = ['android','ios'].includes(window.Telegram.WebApp.platform);

                this.isOpenedInWebApp && this.apiVersion >= 8.0
                    ? this.fullscreenEnable()
                    : this.expand();

                window.Telegram.WebApp.onEvent('backButtonClicked', () => {
                    router.currentRoute.path === this.initialRoute
                        ? window.Telegram.WebApp.close()
                        : router.go(-1);
                });

                if(this.apiVersion >= 8.0)
                {
                    window.Telegram.WebApp.onEvent('fullscreenChanged', () => {
                        this.isFullscreen = window.Telegram.WebApp.isFullscreen ?? false;
                    });
                    this.sefaPaddingTop.navigation = window.Telegram.WebApp.ContentSafeAreaInset?.top ?? 40;
                    this.sefaPaddingTop.content = window.Telegram.WebApp.SafeAreaInset?.top ?? (this.sefaPaddingTop.navigation + 25);
                }
            }
            catch (e) {}
        },

        allowMessage()
        {
            if(!this.user.id) return;

            let allow = this.user.allows_write_to_pm ?? false;

            if(allow)
            {
                this.sendAllowMessage();
            }
            else
            {
                window.Telegram.WebApp.requestWriteAccess((allow) => {
                    if (allow) this.sendAllowMessage();
                });
            }
        },

        sendAllowMessage()
        {
            let webApp = useWebApp();

            webApp.fetch('subscriber/allow-messages', {
                chat_id: webApp.chat.id,
                subscriber_id: this.user.id,
                ym_id: webApp.subscriber.ym_id
            }, false);
        },

        fullscreenEnable()
        {
            if(
                this.isOpenedInWebApp
                && this.apiVersion >= 8.0
                && typeof window?.Telegram?.WebApp?.requestFullscreen === 'function'
            )
            {
                window.Telegram.WebApp.requestFullscreen()
            }
        },

        fullscreenDisable()
        {
            if(
                this.isOpenedInWebApp
                && this.apiVersion >= 8.0
                && typeof window?.Telegram?.WebApp?.exitFullscreen === 'function'
            )
            {
                window.Telegram.WebApp.exitFullscreen()
            }
        },

        expand()
        {
            if(typeof window?.Telegram?.WebApp?.expand === 'function')
            {
                window.Telegram.WebApp.expand()
            }
        }
    },
});
