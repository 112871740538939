export const conferenceRoutes = [
  {
    path: '/conference/:chatUuid',
    component: () => import('@/js/components/conference/ConferenceIndex.vue'),
    name: 'ConferenceIndex',
    meta: {
      isPublic: true
    },
    props: true
  },
  {
    path: '/conference/:chatUuid/ticket/:ticketName',
    component: () => import('@/js/components/conference/components/ConferenceTicket.vue'),
    name: 'ConferenceTicket',
    meta: {
      isPublic: true
    },
    props: true
  },
  {
    path: '/conference/:chatUuid/products',
    component: () => import('@/js/components/conference/components/ConferenceProducts.vue'),
    name: 'ConferenceProducts',
    meta: {
      isPublic: true
    },
    props: true
  },
  {
    path: '/conference/:chatUuid/product/:productId',
    component: () => import('@/js/components/conference/components/ConferenceProduct.vue'),
    name: 'ConferenceProduct',
    meta: {
      isPublic: true
    },
    props: true
  },
  {
    path: '/conference/:chatUuid/product/:productId/content/:contentId',
    component: () => import('@/js/components/conference/components/ConferenceContent.vue'),
    name: 'ConferenceContent',
    meta: {
      isPublic: true
    },
    props: true
  },
  {
    path: '/conference/:chatUuid/speakers',
    component: () => import('@/js/components/conference/components/ConferenceSpeaker.vue'),
    name: 'ConferenceSpeaker',
    meta: {
      isPublic: true
    },
    props: true
  },
  {
    path: '/conference/:chatUuid/support',
    component: () => import('@/js/components/conference/components/ConferenceSupport.vue'),
    name: 'ConferenceSupport',
    meta: {
      isPublic: true
    },
    props: true
  }
]
