<template>
  <div
    class="main-container"
    :class="[
      { 'main-container_chat': hasChatMenu },
      { 'main-container_chat_open': isSidebarOpen && hasChatMenu }
    ]"
  >
    <main-header
      v-show="hasHeader && is_logged_in"
      :has-chat-menu="hasChatMenu"
      class="main-container__header"
      :class="[
        { 'bg-white': bg_path || bg_color },
        { 'main-container__header_hide': isHeaderHide },
        { 'main-container__header_with-back': hasChatMenu && !mobileSize }
      ]"
    />
    <main-sidebar
      v-if="hasChatMenu && !mobileSize"
      class="main-container__sidebar"
    />
    <sidebar-submenu v-if="hasChatMenu && submenuName" />
    <div
      v-if="hasMain"
      id="my-main"
      :class="[
        { 'custom-bg': hasOpacity },
        { 'main-container__main_without-padding': hasNoPadding }
      ]"
      class="main-container__main"
    >
      <el-row
        class="main-container__content"
        :class="{ 'main-container__content_without-header': !hasHeader }"
      >
        <back-button v-if="hasBackButton && !hasChatMenu" />

        <router-view :key="viewId" />
      </el-row>
    </div>
  </div>
</template>
<script>
import {
    ALERT_TIMEOUT
} from '@/js/store/modules/Alert'

import {
    mapActions,
    mapState
} from 'vuex'

import {
    bgColorMixin
} from '@/js/mixins/bg/bgMixin'
import {
    iosMixin
} from '@/js/mixins/pwa/pwaMixin'
import {
    subscriptionEndMessageMixin
} from '@/js/mixins/user/userSubscriptionMixin'

import {
    capacitorInitMixin
} from '@/js/mixins/capacitor/capacitorMixin'

import {
    SubDomainModel
} from '@/js/models/SubDomainModel'
import {
    ChatModel
} from '@/js/models/chat/ChatModel'

import { sendMixin } from '@/js/mixins/send/sendMixin'
import {authTokenMixin} from "@/js/mixins/auth/authMixin";

export default {
    name: 'MainApp',
    components: {
        MainHeader: () => import('@/js/components/main/header/MainHeader.vue'),
        MainSidebar: () => import('@/js/components/main/sidebar/MainSidebar.vue'),
        SidebarSubmenu: () => import('@/js/components/main/sidebar/SidebarSubmenu.vue'),
        BackButton: () => import('@/js/components/main/components/actions/BackButton.vue')
    },
    mixins: [
        iosMixin,
        bgColorMixin,
        subscriptionEndMessageMixin,
        capacitorInitMixin,
        sendMixin,
        authTokenMixin
    ],
    data() {
        return {
            lastScroll: 0,
            defaultOffset: 60,
            isHeaderHide: false
        };
    },
    computed: {
        ...mapState([
            'mobileSmallSize',
            'mobileSize',
            'laptopSize',
            'hasOpacity',
            'subDomainName',
            'subDomain',
            'learme',
            'winwinbotMobile',
            'viewId',
            'isSidebarOpen',
            'submenuName'
        ]),
        ...mapState('conversation', ['subscriber_id', 'isFullscreen']),
        ...mapState('pwa', ['pwaFail', 'pwaSuccess']),
        ...mapState('auth', [
            'bg_path',
            'bg_color',
            'is_logged_in',
            'is_subscription_active',
            'subscriber_limit'
        ]),

        chatId() {
            return +this.$route.params.chat_id
        },

        hasChatMenu() {
            return (
                !!this.chatId &&
                ![
                    'StoreChat',
                    'StoreChatFinish',
                    'GuideChat',
                    'GuideChatFinish'
                ].includes(this.$route.name)
            )
        },

        hideForConversation() {
            return (
                (this.mobileSmallSize && !!this.subscriber_id) || this.isFullscreen
            )
        },

        openMobileConversation() {
            return this.mobileSmallSize && !!this.subscriber_id
        },

        hasBackButton() {
            return (
                !this.hideForConversation &&
                this.$route.name !== 'IndexChat' &&
                this.$route.name !== 'Login' &&
                this.$route.name !== 'Register' &&
                this.$route.name !== 'Profile' &&
                !this.$route.query.hasOwnProperty(ChatModel.IFRAME)
            )
        },

        canUse() {
            return SubDomainModel.canUse(this.subDomain())
        },

        hasHeader() {
            return this.canUse &&
                !this.hideForConversation &&
                this.$route.name !== 'Profile';
        },

        hasMain() {
            return this.canUse || this.is_logged_in || this.$route.query.token
        },

        hasFooter() {
            return this.canUse && !this.openMobileConversation && !this.isFullscreen
        },
        hasNoPadding() {
            return ['IndexChatDashboard'].includes(this.$route.name)
        }
    },
    async created() {
        if (this.winwinbotMobile) {
            await this.capacitorCheckVersion()

            this.capacitorInit()
        }

        this.authInit()
    },
    async mounted()
    {
        window.addEventListener('resize', this.resize)
        window.addEventListener('scroll', this.scroll)
        this.pwaInstall()
        this.collectYmId()
    },
    destroyed() {
        window.removeEventListener('resize', this.resize)
        window.removeEventListener('scroll', this.scroll)
    },
    methods: {
        ...mapActions(['changeWidthSize']),
        ...mapActions('auth', ['initUser']),
        ...mapActions('pwa', ['setPwa', 'setPwaFail']),
        ...mapActions('alert', ['showAlert']),

        collectYmId()
        {
            if (!this.is_logged_in || parseInt(localStorage.getItem('ym_id')) > 0) return;

            window.ym(88888348, 'getClientID', (clientID) => {
                this.sendData(
                    '/callbacks/yandex-metrika/collect',
                    'post',
                    {
                        'ym_id': clientID,
                        'token': this.authToken
                    }
                );
            });
        },

        authInit() {
            if (!this.is_logged_in) return

            this.initUser().then((res) => {
                if (!this.is_subscription_active) {
                    return this.showAlertSubscriptionEnd()
                }

                if (this.subscriber_limit) {
                    return this.showAlertSubscriptionLimit()
                }
            })
        },

        showAlertSubscriptionEnd() {
            this.showAlert({
                text: this.$t('alert.subscription-expired'),
                isHidden: true
            })

            this.$message({
                iconClass: 'icon-subscription-ended',
                center: true,
                message: this.subscriptionEndMessage,
                customClass: 'subscription-ended',
                duration: ALERT_TIMEOUT
            })
        },

        showAlertSubscriptionLimit() {
            this.$message({
                iconClass: 'icon-subscription-ended',
                center: true,
                message: this.$t('alert.subscriber-limit') +
                    ' ' +
                    this.$t('alert.subscriber-limit-date', {
                        date: this.subscriber_limit
                    }),
                customClass: 'subscription-ended',
                duration: ALERT_TIMEOUT
            })
        },

        pwaInstall() {
            if (this.isMacSafari && !this.isIos) return

            this.isIos ?
                this.pwaInit() :
                window.addEventListener('beforeinstallprompt', (e) => {
                    // Prevent the mini-infobar from appearing on mobile
                    e.preventDefault()

                    this.pwaInit(e)
                })
        },

        pwaInit(event = '') {
            this.setPwa(event)

            return

            if (this.learme) return

            // detect ios pwa
            if ('standalone' in navigator && navigator.standalone) return

            if (matchMedia('(display-mode: standalone)').matches) return

            if (!this.is_logged_in || this.pwaSuccess || this.pwaFail) return

            this.pwaPromt()
        },

        pwaPromt() {
            this.$confirm(
                this.$t('services.faq.pwa.promt', {
                    app: this.subDomainName()
                }),
                this.$t('main-words.app', {
                    app: this.subDomainName()
                }), {
                    confirmButtonText: this.$t('main-words.install'),
                    cancelButtonText: this.$t('main-words.cancel'),
                    type: 'info',
                    center: true,
                    customClass: 'width-300px'
                }
            )
                .then(() => this.$router.push({
                    name: 'UserPwa'
                }))
                .catch(() => this.setPwaFail())
        },

        resize() {
            this.changeWidthSize(window.innerWidth)
        },
        scroll() {
            const scrollPosition = () =>
                window.pageYOffset || document.documentElement.scrollTop
            if (
                scrollPosition() > this.lastScroll &&
                !this.isHeaderHide &&
                scrollPosition() > this.defaultOffset
            ) {
                //scroll down
                this.isHeaderHide = true
            } else if (scrollPosition() < this.lastScroll && this.isHeaderHide) {
                //scroll up
                this.isHeaderHide = false
            }

            this.lastScroll = scrollPosition()
        }
    }
}
</script>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

  .width-300px {
    width: 300px !important;
  }

  .custom-bg > div {
    opacity: 0.96;
  }
  .main-container {
    background: #f7f8fa;
    min-height: 100vh;

    &__header {
      font-family: 'Inter', sans-serif;
      width: 100%;
      position: sticky;
      top: 0;
      left: 0;
      height: 64px;
      z-index: 1;
      border-bottom: 1px solid #eaeff4;
      background: #ffffff;
      transition: transform 0.1s linear;
      &_hide {
        transform: translateY(-100px);
      }
      &_with-back {
        height: 100px;
      }
    }
    &__sidebar {
      font-family: 'Inter', sans-serif;
    }
    &__main {
      display: flex;
      position: relative;
      max-width: 1514px;
      width: 100%;
      margin: 0 auto;
      padding: 14px 32px 32px;
      &_without-padding {
        padding: 0;
        max-width: unset;
      }
    }
    &__content {
      flex-grow: 1;
      overflow-x: hidden;
      height: 100%;
      min-height: calc(100vh - 100px - 14px - 32px);
      &_without-header {
        min-height: calc(100vh - 14px - 32px);
      }
    }
  }
  .main-container_chat {
    padding-left: 74px;
    transition: padding-left 0.3s;
    &_open {
      padding-left: 250px;
    }
  }

  @media (max-width: 1024px) {
    .main-container {
      background: #fafbfc;
      &__main {
        padding: 14px 16px 16px;
        &_without-padding {
          padding: 0;
        }
      }
      &__content {
        min-height: calc(100vh - 64px - 16px - 16px);
        &_without-header {
          min-height: calc(100vh - 16px - 16px);
        }
      }
    }
    .main-container_chat,
    .main-container_chat_open {
      padding-left: 0 !important;
    }
  }
</style>
