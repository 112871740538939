export const formatDateMixin = {
    methods:
    {
        formatDate(date, hasLocaleFormat = true, hasLocaleOffset = false) {

            if(date === undefined || date === null) return '';

            date = new Date(date.replace(/-/g, '/'))

            if (hasLocaleOffset) {
                const offset = new Date().getTimezoneOffset()

                date = new Date(date.getTime() - offset * 60000)
            }

            return hasLocaleFormat ? date.toLocaleDateString() : date
        },

        formatTime(date, hasLocaleOffset = false) {
            date = new Date(date.replace(/-/g, '/'))

            if (hasLocaleOffset) {
                const offset = new Date().getTimezoneOffset()

                date = new Date(date.getTime() - offset * 60000)
            }

            date = date.toLocaleTimeString()

            // slice seconds from end
            let sliceCount = 3

            const lastChars = date.substr(-2)

            if (lastChars === 'AM' || lastChars === 'PM') {
                sliceCount = 6
            }

            return date.slice(0, -sliceCount)
        }
    }
}
