export class Model {
    static get types() {
        return []
    }

    static getNameById(id) {
        const type = this.types.find((x) => x.value === id)

        return type ? type.label : ''
    }
}
