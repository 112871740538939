import { defineStore } from 'pinia';
import { useWebApp } from "@/js/store/pinia/webapp/WebApp.js";
import { Trans } from "@/js/plugins/translation.js";

export const useWebAppSettings = defineStore('webAppSettingsStore', {
    state: () => ({
        isDark: false,
        isDarkChecked: false,
        isPreloader: false,
        isRawLanding: false,
    }),
    persist:
    {
        enabled: true,
        afterHydrate: (ctx) => {
            ctx.store.isRawLanding = false;
        }
    },
    getters:
    {
        systemIsDark()
        {
            return window.matchMedia('(prefers-color-scheme: dark)').matches;
        }
    },
    actions:
    {
        async init()
        {
            if(!this.isDarkChecked)
            {
                this.isDark = this.systemIsDark;
                this.isDarkChecked = true;
            }
        }
    },
});
