export class UserTypeModel {
  static USER = 1

  static ADMIN = 2

  static USER_WP = 3

  static ADMIN_WHITE_LABEL = 4

  static ADMIN_SUPPORT = 5

  static ADMIN_USER_GUIDE = 6

  static ADMIN_USER_GOAL = 7

  static APP_SUMO = 8

  static APP_SUMO_ADMIN_WHITE_LABEL = 9
}
