import { i18n } from '@/js/plugins/i18n'
import { SUPPORTED_LANGUAGES } from '@/js/constants/trans'

export class ChatMessageScheduleModel {
  static TYPE_WELCOME = 1
  static TYPE_SPECIFIC_TIME = 2
  static TYPE_IF = 3
  static TYPE_CHAIN = 4

  static TIME_HOURS_MORE = 1

  static TIME_HOURS_LESS = 2

  static TIME_HOURS_NOW = 3

  static TIME_HOURS_END = 4

  constructor(props) {
    this.form = {
      day: 0,
      time: null,
      time_hours: null,
      time_hours_type: ChatMessageScheduleModel.TIME_HOURS_MORE,
      timezone: null,
      webinar_session_id: null,
      webinar_session_date: null,
      webinar_type_id: null,
      chain_message_id: null,
      delay: null,
      delay_days: null
    }
  }

  static get timezones() {
    return [
      {
        label: i18n.t('models.chat.message.timezones.12'),
        value: 12,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.11'),
        value: 11,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.10'),
        value: 10,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.9'),
        value: 9,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.8'),
        value: 8,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.7'),
        value: 7,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.6'),
        value: 6,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.5'),
        value: 5,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.4'),
        value: 4,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.3'),
        value: 3,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.2'),
        value: 2,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.1'),
        value: 1,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.0'),
        value: 0,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.-1'),
        value: -1,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.-2'),
        value: -2,
        locales: ['ru']
      },
      {
        label: i18n.t('models.chat.message.timezones.-3'),
        value: -3,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.-4'),
        value: -4,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.-5'),
        value: -5,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.-6'),
        value: -6,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.-7'),
        value: -7,
        locales: SUPPORTED_LANGUAGES
      },
      {
        label: i18n.t('models.chat.message.timezones.-8'),
        value: -8,
        locales: ['en', 'es', 'hi']
      },
      {
        label: i18n.t('models.chat.message.timezones.-9'),
        value: -9,
        locales: ['en', 'es', 'hi']
      },
      {
        label: i18n.t('models.chat.message.timezones.-10'),
        value: -10,
        locales: ['en', 'es', 'hi']
      },
      {
        label: i18n.t('models.chat.message.timezones.-11'),
        value: -11,
        locales: ['en', 'es', 'hi']
      },
      {
        label: i18n.t('models.chat.message.timezones.-12'),
        value: -12,
        locales: ['en', 'es', 'hi']
      }
    ]
  }
}
