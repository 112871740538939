export const chatSubscriberBaseTagRoutes = [
  {
    path: 'chat/:chat_id/subscriber-base/tags/store',
    component: () => import('@/js/components/chat/subscriberBase/tag/StoreChatSubscriberTag.vue'),
    name: 'StoreChatSubscriberTag',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/subscriber-base/tags/:item_id/update',
    component: () => import( '@/js/components/chat/subscriberBase/tag/UpdateChatSubscriberTag.vue'),
    name: 'UpdateChatSubscriberTag',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
