import { ChatSubscriberFormModel } from '@/js/models/chat/subscriber/ChatSubscriberFormModel'

const initialState = () => {
  return {
    chat_subscriber_form: new ChatSubscriberFormModel().form,
    chat_subscriber_form_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_subscriber_form_loaded = isLoaded
    },

    setForm(state, { form, chat_platform_id }) {
      state.chat_subscriber_form = form

      state.chat_subscriber_form.info = ChatSubscriberFormModel.setFormInfoLocalization(
        chat_platform_id,
        form.info
      )
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchSubForm({ commit, dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatSubscriberFormModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setSubForm', {
              form: data.form,
              chat_platform_id: data.chat.platform_id
            })

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch((error) => {
            dispatch('resetSubForm')

            return reject(error)
          })
      })
    },

    setSubForm({ commit }, { form, chat_platform_id }) {
      commit('setForm', { form: form, chat_platform_id: chat_platform_id })

      commit('setLoaded', true)
    },

    resetSubForm({ commit }) {
      commit('reset')
    }
  }
}
