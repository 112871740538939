import { i18n } from '@/js/plugins/i18n'

import { ChatModel } from '@/js/models/chat/ChatModel'

export class ChatSubscriberFilterModel {
  static LIMIT = 100

  static LOGIC_TYPE_AND = 1
  static LOGIC_TYPE_OR = 2
  static LOGIC_TYPE_EXCEPT = 3

  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/filters`
  }

  constructor() {
    this.param = {
      logic_type_id: 2,
      type_id: null,
      range: null,
      message_id: null,
      stage_id: null,
      tag_id: null,
      subscriber_id: null,
      sub_form: {
        name: null,
        prop: null,
        prop_value: null,
        type_id: null,
        compare_type_id: null,
        is_lowercase: false
      },
      button: {
        id: null,
        message_id: null
      },
      link: {
        id: null,
        message_id: null
      },
      webinar: {
        id: null,
        session_id: null,
        session_date: null
      },
      homework: {
        id: null,
        status_id: null
      },
      questionary: {
        id: null,
        status_id: null
      },
      product: {
        id: null,
        status_id: null
      },
      password: {
        value: null
      },
      group: {
        id: null
      },
      folder: {
        id: null,
        schedule_id: null,
        schedule_day: null,
        schedule_day_type: null
      },
      subscriber_ids: [],
      operator_ids: []
    }

    this.form = {
      name: null,
      allow_edit: false,
      params: [this.param]
    }
  }

  static get logicTypes() {
    return [
      {
        value: ChatSubscriberFilterModel.LOGIC_TYPE_AND,
        label: i18n.t('models.chat.subscriber.filter.logic-type.and')
      },
      {
        value: ChatSubscriberFilterModel.LOGIC_TYPE_OR,
        label: i18n.t('models.chat.subscriber.filter.logic-type.or')
      },
      {
        value: ChatSubscriberFilterModel.LOGIC_TYPE_EXCEPT,
        label: i18n.t('models.chat.subscriber.filter.logic-type.except')
      }
    ]
  }

  static exceptLabel(typeId, label, index) {
    if (typeId !== this.LOGIC_TYPE_EXCEPT) {
      return label
    }

    return index
      ? i18n.t('models.chat.subscriber.filter.logic-type.and') +
          ' ' +
          label.toLowerCase()
      : label
  }

  static logicTypeSymbol(typeId, index = null) {
    let symbol = ''

    switch (typeId) {
      case this.LOGIC_TYPE_AND:
        symbol = i18n.t('models.chat.subscriber.filter.logic-type.and')

        break
      case this.LOGIC_TYPE_OR:
        symbol = i18n.t('models.chat.subscriber.filter.logic-type.or')

        break
      case this.LOGIC_TYPE_EXCEPT:
        symbol = i18n.t('models.chat.subscriber.filter.logic-type.except')

        if (index !== null) {
          symbol = this.exceptLabel(this.LOGIC_TYPE_EXCEPT, symbol, index)
        }

        break
    }

    return symbol
  }
}
