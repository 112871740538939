import { ChatModel } from '@/js/models/chat/ChatModel'
import { ChatWebinarSessionModel } from '@/js/models/chat/webinar/ChatWebinarSessionModel'

export class ChatWebinarModel {
  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/webinars`
  }

  static FILLABLE = [
    'uuid',
    'name',
    'description_code',
    'session_days',
    'allow_edit',
    'folder_id'
  ]

  static UUID_DOMAINS = ['winwinbot.school', 'wwb.click']

  static UUID_PREFIX = '/webinar/'

  constructor() {
    this.searchForm = {
      folder_id: null
    }

    let session = new ChatWebinarSessionModel().form

    this.form = {
      id: null,
      folder_id: null,
      uuid: null,
      uuid_domain: null,
      name: null,
      description: null,
      description_code: null,
      description_no_sessions: null,
      image_header: null,
      image_bg: null,
      session_days: null,
      allow_edit: false,
      sessions: [session]
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }

  static getPublicUrl(webinar, chatDomain = null) {
    let host = window.location.host

    if (webinar.uuid_domain) {
      host = webinar.uuid_domain
    } else if (chatDomain) {
      host = chatDomain.url
    }

    return `https://${host}${this.UUID_PREFIX}${webinar.uuid}?`
  }
}
