export const ticketMap = {
  premium: 'Билет premium',
  vip: 'Билет vip',
  standart: 'Билет standart'
}

export const ticketUpgradeMap = {
  standart_vip: 'Повысить тариф standart до vip',
  vip_premium: 'Повысить тариф vip до premium',
  standart_premium: 'Повысить тариф standart до premium'
}

export const ticketsInfo = {
  premium: {
    title: 'Premium',
    img: new URL("@/assets/images/conference/ticket/premium.png", import.meta.url).href,
    background: '#B68793',
    main: [
      {
        text: 'Онлайн-трансляция 10\u201112\u00A0ноября \n2 сцены',
        isActive: true
      },
      {
        text: 'Видеозаписи всех выступлений \nна 1 год (2 сцены)',
        isActive: true
      },
      {
        text:
          'Чат VIP-участников в телеграме с доступом на 1\u00A0год где можно найти клиентов, коллег или партнеров',
        isActive: true
        //   addText:
        //     'После конференции в течении двух недель мы откроем тебе доступ к закрытому каналу с конспектамм и транскрибациями всех выступлений\n\nЭто сильно экономит время, так ты самые лучшие фишки и стратегии сможешь узнать буквально за 1-2 часа изучив конспекты и выжимку'
      },
      {
        text:
          'Доступ и запись к практикуму «1» «Метасообщения. Речевые обещания»',
        isActive: true
      },
      {
        text:
          'Доступ и запись к практикуму «2» «Блок-схема микроворонки на миллион»',
        isActive: true
      },
      {
        text:
          'Доступ и запись к практикуму «3» «Рефералка, где 100\u00A0человек приводит\u00A01000»',
        isActive: true
      },
      {
        text: 'Доступ к закрытому каналу «Extract» — конспекты и выжимка',
        isActive: true
      }
    ],
    bonuses: [
      {
        text: 'Консультации Гена Бабинцев 1\u00A0час стоимостью 40\u00A0000₽',
        isActive: true
      },
      {
        text: 'Интенсив — «Глубина»',
        isActive: true
      },
      {
        text: 'Бонусный продукт «Killzoom» – Продажи на консультациях',
        isActive: true
      },
      {
        text: 'Интенсив — «Упаковка трансляции на миллион» (оборудование)',
        isActive: true
      },
      {
        text:
          'Материал «Внутрянка клуба, который стабильно приносит 12\u00A0млн в месяц с чеком от 3000 до 5000\u00A0рублей»',
        isActive: true
      },
      {
        text:
          'Материал «Будущее уже наступило! Как нейросети генерят нам 1,5 млн в месяц?»',
        isActive: true
      },
      {
        text:
          'Материал «Метод, с помощью которого я создал свыше сотни офферов, проданных на 150\u00A0млн.\u00A0рублей»',
        isActive: true
      }
    ]
  },
  vip: {
    title: 'VIP',
    img: new URL("@/assets/images/conference/ticket/vip.png", import.meta.url).href,
    background: '#767B82',
    main: [
      {
        text: 'Онлайн-трансляция 10\u201112\u00A0ноября \n2 сцены',
        isActive: true
      },
      {
        text: 'Видеозаписи всех выступлений \nна 6 мес (2 сцены)',
        isActive: true
      },
      {
        text:
          'Чат VIP-участников в телеграме с доступом на 1\u00A0год где можно найти клиентов, коллег или партнеров',
        isActive: true
      },
      {
        text: 'Доступ к закрытому каналу «Extract» — конспекты и выжимка',
        isActive: true
      },
      {
        text:
          'Доступ и запись к практикуму «1» «Метасообщения. Речевые обещания»',
        isActive: false
      },
      {
        text:
          'Доступ и запись к практикуму «2» «Блок-схема микроворонки на миллион»',
        isActive: false
      },
      {
        text:
          'Доступ и запись к практикуму «3» «Рефералка, где 100\u00A0человек приводит\u00A01000»',
        isActive: false
      }
    ],
    bonuses: [
      {
        text: 'Бонусный продукт «Killzoom» – Продажи на консультациях',
        isActive: true
      },
      {
        text:
          'Материал «Внутрянка клуба, который стабильно приносит 12\u00A0млн в месяц с чеком от 3000 до 5000\u00A0рублей»',
        isActive: true
      },
      {
        text: 'Консультации Гена Бабинцев 1\u00A0час стоимостью 40\u00A0000₽',
        isActive: false
      },
      {
        text: 'Интенсив — «Глубина»',
        isActive: false
      },
      {
        text: 'Интенсив — «Упаковка трансляции на миллион» (оборудование)',
        isActive: false
      },
      {
        text:
          'Материал «Будущее уже наступило! Как нейросети генерят нам 1,5 млн в месяц?»',
        isActive: false
      },
      {
        text:
          'Материал «Метод, с помощью которого я создал свыше сотни офферов, проданных на 150\u00A0млн.\u00A0рублей»',
        isActive: false
      }
    ]
  },
  standart: {
    title: 'Standart',
    img: new URL("@/assets/images/conference/ticket/standart.png", import.meta.url).href,
    background: '#68887E',
    main: [
      {
        text: 'Онлайн-трансляция 10\u201112\u00A0ноября \n2 сцены',
        isActive: true
      },
      {
        text: 'Общий чат в телеграме',
        isActive: true
      },
      {
        text: 'Видеозаписи всех выступлений \nна 2 месяца (2 сцены)',
        isActive: true
      },
      {
        text:
          'Доступ и запись к практикуму «1» «Метасообщения. Речевые обещания»',
        isActive: false
      },
      {
        text:
          'Доступ и запись к практикуму «2» «Блок-схема микроворонки на миллион»',
        isActive: false
      },
      {
        text:
          'Доступ и запись к практикуму «3» «Рефералка, где 100\u00A0человек приводит\u00A01000»',
        isActive: false
      },
      {
        text: 'Доступ к закрытому каналу «Extract» — конспекты и выжимка',
        isActive: false
      }
    ],
    bonuses: [
      {
        text: 'Консультации Гена Бабинцев 1\u00A0час стоимостью 40\u00A0000₽',
        isActive: false
      },
      {
        text: 'Интенсив — «Глубина»',
        isActive: false
      },
      {
        text: 'Бонусный продукт «Killzoom» – Продажи на консультациях',
        isActive: false
      },
      {
        text: 'Интенсив — «Упаковка трансляции на миллион» (оборудование)',
        isActive: false
      },
      {
        text:
          'Материал «Внутрянка клуба, который стабильно приносит 12\u00A0млн в месяц с чеком от 3000 до 5000\u00A0рублей»',
        isActive: false
      },
      {
        text:
          'Материал «Будущее уже наступило! Как нейросети генерят нам 1,5 млн в месяц?»',
        isActive: false
      },
      {
        text:
          'Материал «Метод, с помощью которого я создал свыше сотни офферов, проданных на 150\u00A0млн.\u00A0рублей»',
        isActive: false
      }
    ]
  },
  free: {
    title: 'Free',
    img: new URL("@/assets/images/conference/ticket/free.png", import.meta.url).href,
    background: '#2C2C2C',
    main: [
      {
        text: 'Доступ на просмотр выступлений спикеров только on-line',
        isActive: true
      },
      {
        text: 'Общий чат в телеграме',
        isActive: false
      },
      {
        text: 'Видеозаписи всех выступлений \nна 2 месяца (2 сцены)',
        isActive: false
      },
      {
        text:
          'Доступ и запись к практикуму «1» «Метасообщения. Речевые обещания»',
        isActive: false
      },
      {
        text:
          'Доступ и запись к практикуму «2» «Блок-схема микроворонки на миллион»',
        isActive: false
      },
      {
        text:
          'Доступ и запись к практикуму «3» «Рефералка, где 100\u00A0человек приводит\u00A01000»',
        isActive: false
      },
      {
        text: 'Доступ к закрытому каналу «Extract» — конспекты и выжимка',
        isActive: false
      }
    ],
    bonuses: [
      {
        text: 'Консультации Гена Бабинцев 1\u00A0час стоимостью 40\u00A0000₽',
        isActive: false
      },
      {
        text: 'Интенсив — «Глубина»',
        isActive: false
      },
      {
        text: 'Бонусный продукт «Killzoom» – Продажи на консультациях',
        isActive: false
      },
      {
        text: 'Интенсив — «Упаковка трансляции на миллион» (оборудование)',
        isActive: false
      },
      {
        text:
          'Материал «Внутрянка клуба, который стабильно приносит 12\u00A0млн в месяц с чеком от 3000 до 5000\u00A0рублей»',
        isActive: false
      },
      {
        text:
          'Материал «Будущее уже наступило! Как нейросети генерят нам 1,5 млн в месяц?»',
        isActive: false
      },
      {
        text:
          'Материал «Метод, с помощью которого я создал свыше сотни офферов, проданных на 150\u00A0млн.\u00A0рублей»',
        isActive: false
      }
    ]
  }
}
