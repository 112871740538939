export const chatLandingRoutes = [
  {
    path: 'chat/:chat_id/content/longreads/store',
    component: () => import('@/js/components/chat/content/landing/StoreChatLanding.vue'),
    name: 'StoreChatLanding',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/content/longreads/:item_id/update',
    component: () => import('@/js/components/chat/content/landing/UpdateChatLanding.vue'),
    name: 'UpdateChatLanding',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
