import { chatSubscriberBaseStageRoutes } from '@/js/routes/chat/subscriberBase/stage/routes'
import { chatSubscriberBaseTasksRoutes } from '@/js/routes/chat/subscriberBase/task/routes'
import { chatSubscriberBaseTagRoutes } from '@/js/routes/chat/subscriberBase/tag/routes'
import { chatSubscriberBaseFilterRoutes } from '@/js/routes/chat/subscriberBase/filter/routes'

export const chatSubscriberBaseRoutes = [
  ...chatSubscriberBaseStageRoutes,
  ...chatSubscriberBaseTasksRoutes,
  ...chatSubscriberBaseTagRoutes,
  ...chatSubscriberBaseFilterRoutes,
  {
    path: 'chat/:chat_id/subscriber-base/subs/:subscriber_id',
    component: () => import( '@/js/components/chat/subscriberBase/subs/profile/ProfileChatSubscriber.vue'),
    name: 'ProfileChatSubscriber',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/subscriber-base/:page',
    component: () => import('@/js/components/chat/subscriberBase/IndexChatSubscriberBase.vue'),
    name: 'IndexChatSubscriberBase',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
