import { i18n } from '@/js/plugins/i18n'
import { maxLengthMessage } from '@/js/services/ValidationRuleService'

import { DataTypeModel } from '@/js/models/dataType/DataTypeModel'
import { ChatPlatformModel } from '@/js/models/chat/ChatPlatformModel'
import { ChatMessageButtonTypeModel } from '@/js/models/chat/message/button/ChatMessageButtonTypeModel'

export class ChatMessageButtonModel {
  static MAX_COUNT = (platform_id) => {
    switch (platform_id) {
      case ChatPlatformModel.TELEGRAM:
        return 99
      case ChatPlatformModel.VIBER:
        return 7
      case ChatPlatformModel.VK:
        return 5
      case ChatPlatformModel.SLACK:
        return 10
      case ChatPlatformModel.FACEBOOK:
      case ChatPlatformModel.INSTAGRAM:
        return 3
    }
  }

  constructor() {
    this.form = {
      id: null,
      name: null,
      url: null,
      type_id: null,
      landing_id: null,
      homework_id: null,
      course_id: null,
      product_id: null,
      stage_id: null,
      tag_id: null,
      webinar_id: null,
      hasWebinarText: 0,
      folder_id: null,
      video_id: null,
      questionary_id: null,
      web_app_id: null,
      sub_form: {
        prop: null,
        prop_value: null,
        type_id: DataTypeModel.TEXT
      },
      show_after_click: false,
      is_inside: false
    }
  }

  static nameValidator = (platform_id, rule, value, callback) => {
    let length = value.length

    switch (platform_id) {
      case ChatPlatformModel.VK:
        return length > 40
          ? callback(new Error(i18n.t(maxLengthMessage)))
          : callback()
      case ChatPlatformModel.SLACK:
        return length > 75
          ? callback(new Error(i18n.t(maxLengthMessage)))
          : callback()
      default:
        return length > 255
          ? callback(new Error(i18n.t(maxLengthMessage)))
          : callback()
    }
  }

  static create(typeId, button = null) {
    button = button || new ChatMessageButtonModel().form

    button.type_id = typeId

    const keys = [
      'url',
      'sub_form',
      'landing',
      'landing_id',
      'stage_id',
      'homework_id',
      'course_id',
      'product_id',
      'webinar_id',
      'tag_id',
      'folder_id',
      'video_id',
      'questionary_id',
      'web_app_id'
    ]

    let excludeKeys = []

    switch (button.type_id) {
      case ChatMessageButtonTypeModel.LANDING:
        excludeKeys = ['landing', 'landing_id']

        break
      case ChatMessageButtonTypeModel.LINK:
        excludeKeys = ['url']

        break
      case ChatMessageButtonTypeModel.SUB_FORM:
        excludeKeys = ['sub_form']

        break
      case ChatMessageButtonTypeModel.STAGE:
        excludeKeys = ['stage_id']

        break
      case ChatMessageButtonTypeModel.HOMEWORK:
        excludeKeys = ['homework_id']

        break
      case ChatMessageButtonTypeModel.PRODUCT:
      case ChatMessageButtonTypeModel.PRODUCT_RECURRENT:
        excludeKeys = ['product_id']

        break
      case ChatMessageButtonTypeModel.WEBINAR_SESSION:
        excludeKeys = ['webinar_id']

        button.hasWebinarText = +!!button.name

        break
      case ChatMessageButtonTypeModel.COURSE:
        excludeKeys = ['course_id']

        break
      case ChatMessageButtonTypeModel.TAG:
        excludeKeys = ['tag_id']

        break
      case ChatMessageButtonTypeModel.FOLDER_SCHEDULE:
        excludeKeys = ['folder_id']

        break
      case ChatMessageButtonTypeModel.VIDEO:
        excludeKeys = ['video_id']

        break
      case ChatMessageButtonTypeModel.QUESTIONARY:
        excludeKeys = ['questionary_id']

        break
      case ChatMessageButtonTypeModel.RECURRING_NOTIFICATION:
        button.name = i18n.t('bot.message.form.tabs.button.get-messages')

        break
      case ChatMessageButtonTypeModel.WEB_APP:
        excludeKeys = ['web_app_id']

        break
    }

    keys.forEach((key) => {
      if (excludeKeys.includes(key)) {
        return
      }

      button[key] = undefined
    })

    button.show_after_click = +button.show_after_click

    return button
  }
}
