export const adminSuperSubscriptionTypeRoutes = [
  {
    path: 'admin/subscription/types',
    component: () => import('@/js/components/admin/super/subscription/type/IndexAdminSubscriptionType.vue'),
    name: 'IndexAdminSubscriptionType',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/subscription/types/store',
    component: () => import('@/js/components/admin/super/subscription/type/StoreAdminSubscriptionType.vue'),
    name: 'StoreAdminSubscriptionType',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/subscription/types/:item_id/update',
    component: () => import('@/js/components/admin/super/subscription/type/UpdateAdminSubscriptionType.vue'),
    name: 'UpdateAdminSubscriptionType',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },

  {
    path: 'admin/subscription/types/:type_id/subtypes',
    component: () => import('@/js/components/admin/super/subscription/type/subtype/IndexAdminSubscriptionSubType.vue'),
    name: 'IndexAdminSubscriptionSubType',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/subscription/types/:type_id/subtypes/store',
    component: () => import('@/js/components/admin/super/subscription/type/subtype/StoreAdminSubscriptionSubType.vue'),
    name: 'StoreAdminSubscriptionSubType',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/subscription/types/:type_id/subtypes/:item_id/update',
    component: () => import('@/js/components/admin/super/subscription/type/subtype/UpdateAdminSubscriptionSubType.vue'),
    name: 'UpdateAdminSubscriptionSubType',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  }
]
