import { Model } from '@/js/models/Model'

import { i18n } from '@/js/plugins/i18n'

export class ChatCreateTypeModel extends Model {
  static YOURSELF = 1

  static TEMPLATE = 2

  static INSTRUCTOR = 3

  static SPECIALIST = 4

  static get types() {
    return [
      {
        value: this.INSTRUCTOR,
        label: i18n.t('models.chat.create-type.instructor'),
        icon: 'el-icon-service'
      },
      {
        value: this.YOURSELF,
        label: i18n.t('models.chat.create-type.yourself'),
        icon: 'el-icon-thumb',
        allowWL: true,
        allowEn: true
      },
      {
        value: this.TEMPLATE,
        label: i18n.t('models.chat.create-type.template'),
        icon: 'el-icon-files',
        allowWL: true,
        allowEn: true
      },
      {
        value: this.SPECIALIST,
        label: i18n.t('models.chat.create-type.specialist'),
        icon: 'el-icon-magic-stick'
      }
    ]
  }

  static getIconById(id) {
    return this.types.find((x) => x.value === id).icon
  }
}
