export class SubscriptionTypeModel {
  static DEPRECATED_DATE = '2022-09-30 00:00:01'

  static TRIAL = 1
  static YOUNG = 2
  static PROFI = 3
  static MASTER = 4

  static WHITE_LABEL = 8

  static STANDART = 9
  static PRO = 10
  static ENTERPRISE = 11

  static DEMO = 18

  static ALLOW_PERMISSIONS = [
    'allow_message_type_right_now',
    'allow_message_type_schedule',
    'allow_message_type_trigger',
    'allow_message_type_command',
    'allow_message_type_keyword',
    'allow_questionary',
    'allow_longread',
    'allow_stat',
    'allow_conversation',

    'allow_notification',
    'allow_bot_landing',
    'allow_sub_export',
    'allow_sub_filter',
    'allow_bot_password',
    'allow_sub_form',
    'allow_sub_action',
    'allow_widget',
    'allow_sub_referral',
    'allow_bot_template',
    'allow_bot_sub_domain',

    'allow_homework',
    'allow_longread_private',
    'allow_video',
    'allow_webinar',
    'allow_shop',

    'allow_api',
    'allow_widget_code',
    'allow_webhook',

    'allow_manager',
    'allow_sla',
    'allow_contract',
    'allow_branding',
    'allow_improvement'
  ]

  constructor() {
    let form = {
      id: null,
      sub_domain_id: null,
      lang_id: null,
      name_ru: null,
      name_en: null,
      name_es: null,

      description_ru: null,
      description_en: null,
      description_es: null,

      limit_bot_count: null,
      limit_sub_count: null,
      limit_video_count: null,
      limit_admin_count: null,
      limit_gpt_count: null,
      limit_referral_level_count: null,

      is_agreement: 0,
      is_private: 0,
      url: null
    }

    SubscriptionTypeModel.ALLOW_PERMISSIONS.forEach((item) => {
      form[item] = 0
    })

    this.form = form
  }

  static isDeprecated(id) {
    return [this.TRIAL, this.YOUNG, this.PROFI, this.MASTER].includes(id)
  }

  static demoIds() {
    return [SubscriptionTypeModel.TRIAL, SubscriptionTypeModel.DEMO]
  }
}
