export const sendMixin = {
    data: () => ({
        loading: false,
        loadingProgress: 0
    }),
    methods: {
        sendData(
            url,
            type,
            params = {},
            headers = {},
            validateLoading = true,
            toggleLoading = true,
            progressLoading = false
        ) {
            return new Promise((resolve, reject) => {
                if (validateLoading && this.loading) {
                    return reject(new Error('loading'))
                }

                if (toggleLoading) {
                    this.loading = true
                }

                const config = {
                    headers
                }

                if (progressLoading) {
                    config.onUploadProgress = (event) => {
                        this.loadingProgress = Math.round(
                            (event.loaded * 100) / event.total
                        )

                        if (this.loadingProgress === 100) {
                            this.loadingProgress = null
                        }
                    }
                }

                axios[type](url, params, config)
                    .then((res) => {
                        if (toggleLoading) {
                            this.loading = false
                        }

                        resolve(res.data)
                    })
                    .catch((error) => {
                        if (toggleLoading) {
                            this.loading = false
                        }

                        reject(error)
                    })
            })
        }
    }
}

export const sendPutMixin = {
    methods: {
        appendPutMethod(form) {
            form.append('_method', 'PUT')
        }
    }
}
