export const chatGptRoutes = [
  {
    path: 'chat/:chat_id/gpts/store',
    component: () => import( '@/js/components/chat/settings/gpt/StoreChatGpt.vue'),
    name: 'StoreChatGpt',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/gpts/:item_id/update',
    component: () => import('@/js/components/chat/settings/gpt/UpdateChatGpt.vue'),
    name: 'UpdateChatGpt',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
