import { Message } from 'element-ui'

export const ALERT_TIMEOUT = 3000

function initialState() {
  return {
    message: null
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setMessage(state, data) {
      state.message = data
    }
  },
  actions: {
    showAlert({ state, commit }, data) {
      if (state.message === data.text) return

      setTimeout(
        () => (state.message === data.text ? commit('setMessage', null) : null),
        ALERT_TIMEOUT
      )

      commit('setMessage', data.text)

      if (data.isHidden) return

      Message({ showClose: true, message: data.text, type: data.type })
    }
  }
}
