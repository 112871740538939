import {ChatModel} from '@/js/models/chat/ChatModel'

export class ChatSubscriberModel {
    constructor(props) {
        this.searchForm = {
            filter_id: null,
            filter_result_uuid: null,
            stage_id: null,
            schedule_id: null,
            search: null
        }

        this.sortForm = {
            sort_by: {}
        }

        this.form = {
            id: null,
            first_name: '',
            last_name: '',
            username: '',
            language_code: '',
            avatar: '',
            info: [],
            deleted_at: null
        }
    }

    static urlForWebapp() {
        return `/webapp/subscriber`;
    }

    static urlForWebappAllowMessage() {
        return `/webapp/allow-message`;
    }

    static url(chatId) {
        return `${ChatModel.URL}/${chatId}/subscribers`
    }

    static urlShort(chatId, subId) {
        return `${ChatModel.URL}/${chatId}/subs/${subId}`
    }

    static urlPublic(chatUuid, subUuid) {
        return `${ChatModel.URL_PUB}/${chatUuid}/${subUuid}`
    }

    static getName(sub, hasOnlyName = false) {
        if (sub.username && !hasOnlyName) {
            return sub.username
        }

        let name = ''

        if (sub.first_name) {
            name += sub.first_name + ' '
        }

        if (sub.last_name) {
            name += sub.last_name
        }

        return name
    }
}
