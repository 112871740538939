import { i18n } from '@/js/plugins/i18n'

import { mapState } from 'vuex'

import {
  chatAllowContentMixin,
  chatAllowPromotionMixin,
  chatAllowQuestionaryMixin
} from '@/js/mixins/chat/chatMixin'
import { formatDateMixin } from '@/js/mixins/date/dateMixin'

import { ChatLangModel } from '@/js/models/chat/ChatLangModel'
import { ChatPlatformModel } from '@/js/models/chat/ChatPlatformModel'
import { ChatMessageTypeModel } from '@/js/models/chat/message/ChatMessageTypeModel'
import { SubscriptionTypeModel } from '@/js/models/subscription/SubscriptionTypeModel'
import { MoneyReplenishmentCurrencyModel } from '@/js/models/money/MoneyReplenishmentCurrencyModel'

export const subscriptionNameMixin = {
  methods: {
    getSubscriptionName(subscription) {
      let key = `name_${this.$i18n.locale}`

      return subscription && subscription.type ? subscription.type[key] : ''
    }
  },

  computed: {
    ...mapState('auth', ['subscription']),

    subscriptionTypeName() {
      return this.getSubscriptionName(this.subscription)
    }
  }
}

export const subscriptionDescriptionMixin = {
  methods: {
    getSubscriptionDescription(subscription) {
      const result = {
        first: '',
        second: [],
        third: []
      }
      let key = `description_${this.$i18n.locale}`
      if (!subscription || !subscription.type) {
        return result
      }
      const fullDescription = subscription.type[key]
      const fullDescriptionArray = fullDescription
        .split('\n\n')
        .filter((item) => item !== '')

      if (fullDescriptionArray.length === 3) {
        result.first = fullDescriptionArray[0]
        result.second = fullDescriptionArray[1].split('\n')
        result.third = fullDescriptionArray[2].split('\n')
      }
      if (fullDescriptionArray.length === 2) {
        result.second = fullDescriptionArray[0].split('\n')
        result.third = fullDescriptionArray[1].split('\n')
      }
      if (fullDescriptionArray.length === 1) {
        result.third = fullDescriptionArray[0].split('\n')
      }

      return result
    }
  }
}

export const subscriptionCurrencySymbolMixin = {
  computed: {
    ...mapState('auth', ['lang_id']),

    currencySymbol() {
      return +this.lang_id === ChatLangModel.EN
        ? MoneyReplenishmentCurrencyModel.getSymbolById(
            MoneyReplenishmentCurrencyModel.USD
          )
        : MoneyReplenishmentCurrencyModel.getSymbolById(
            MoneyReplenishmentCurrencyModel.RUB
          )
    }
  }
}

export const subscriptionEndMessageMixin = {
  mixins: [formatDateMixin],
  computed: {
    ...mapState('auth', ['subscribed_until_end', 'subscription']),

    subscriptionEndMessage() {
      return this.subscription &&
        this.subscription.type_id === SubscriptionTypeModel.DEMO
        ? i18n.t('alert.subscription-end-demo')
        : i18n.t('alert.subscription-end-full', {
            date: this.subscribed_until_end
              ? this.formatDate(this.subscribed_until_end, true, true)
              : ''
          })
    }
  }
}

export const subscriptionAllowConversationMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowConversation() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_conversation
      )
    },

    allowConversationPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_conversation === null
      )
    }
  }
}

export const subscriptionAllowStatMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowStat() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_stat
      )
    }
  }
}

export const subscriptionAllowSubExportMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowSubExport() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_sub_export
      )
    },

    allowSubExportPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_sub_export === null
      )
    }
  }
}

export const subscriptionAllowSubActionMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowSubAction() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_sub_action
      )
    },

    allowSubActionPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_sub_action === null
      )
    }
  }
}

export const subscriptionAllowSubFilterMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowSubFilter() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_sub_filter
      )
    },

    allowSubFilterPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_sub_filter === null
      )
    }
  }
}

export const subscriptionAllowSubFormMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowSubForm() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_sub_form
      )
    },

    allowSubFormPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_sub_form === null
      )
    }
  }
}

export const subscriptionAllowQuestionaryMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowQuestionary() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_questionary
      )
    },

    allowQuestionaryPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_questionary === null
      )
    }
  }
}

export const subscriptionAllowHomeworkMixin = {
  computed: {
    ...mapState('auth', ['subscription']),
    ...mapState('chat', ['chat_platform_id'])
  },
  methods: {
    allowHomework(platform_id = null) {
      platform_id = platform_id || this.chat_platform_id

      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_homework &&
        ChatPlatformModel.hasPrivateAccess(platform_id)
      )
    },

    allowHomeworkPreview(platform_id = null) {
      platform_id = platform_id || this.chat_platform_id

      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_homework === null &&
        ChatPlatformModel.hasPrivateAccess(platform_id)
      )
    }
  }
}

export const subscriptionAllowLongreadMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowLongreadPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_longread === null
      )
    },

    allowLongread() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_longread
      )
    }
  }
}

export const subscriptionAllowLongreadPrivateMixin = {
  computed: {
    ...mapState('auth', ['subscription']),
    ...mapState('chat', ['chat_platform_id'])
  },
  methods: {
    allowLongreadPrivate(platformId = null) {
      platformId = platformId || this.chat_platform_id

      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_longread_private &&
        ChatPlatformModel.hasPrivateAccess(platformId)
      )
    },

    allowLongreadPrivatePreview(platformId = null) {
      platformId = platformId || this.chat_platform_id

      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_longread_private === null &&
        ChatPlatformModel.hasPrivateAccess(platformId)
      )
    }
  }
}

export const subscriptionAllowWebinarMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowWebinar() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_webinar
      )
    },

    allowWebinarPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_webinar === null
      )
    }
  }
}
export const subscriptionAllowVideoMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowVideo() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_video
      )
    },

    allowVideoPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_video === null
      )
    }
  }
}

export const subscriptionAllowShopMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowShop() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_shop
      )
    },

    allowShopPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_shop === null
      )
    }
  }
}

export const subscriptionAllowWebhookMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowWebhook() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_webhook
      )
    }
  }
}

export const subscriptionAllowMessageTypeScheduleMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowMessageTypeSchedule() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_message_type_schedule
      )
    },

    allowMessageTypeSchedulePreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_message_type_schedule === null
      )
    }
  }
}

export const subscriptionAllowMessageTypeRightNowMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowMessageTypeRightNow() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_message_type_right_now
      )
    },

    allowMessageTypeRightNowPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_message_type_right_now === null
      )
    }
  }
}

export const subscriptionAllowMessageTypeTriggerMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowMessageTypeTrigger() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_message_type_trigger
      )
    },

    allowMessageTypeTriggerPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_message_type_trigger === null
      )
    }
  }
}

export const subscriptionAllowMessageTypeCommandMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowMessageTypeCommand() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_message_type_command
      )
    },

    allowMessageTypeCommandPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_message_type_command === null
      )
    }
  }
}

export const subscriptionAllowMessageTypeKeywordMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowMessageTypeKeyword() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_message_type_keyword
      )
    },

    allowMessageTypeKeywordPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_message_type_keyword === null
      )
    }
  }
}

export const subscriptionAllowBotLandingMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowBotLanding() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_bot_landing
      )
    },

    allowBotLandingPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_bot_landing === null
      )
    }
  }
}

export const subscriptionAllowBotSubDomainMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowBotSubDomain() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_bot_sub_domain
      )
    },

    allowBotSubDomainPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_bot_sub_domain === null
      )
    }
  }
}

export const subscriptionAllowBotPasswordMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowBotPassword() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_bot_password
      )
    },

    allowBotPasswordPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_bot_password === null
      )
    }
  }
}

export const subscriptionAllowBotTemplateMixin = {
  computed: {
    ...mapState(['winwinbot', 'aunite']),
    ...mapState('auth', ['subscription']),

    allowBotTemplate() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_bot_template
      )
    },

    allowBotTemplatePreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_bot_template === null
      )
    }
  }
}

export const subscriptionAllowNotificationMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowNotification() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_notification
      )
    },

    allowNotificationPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_notification === null
      )
    }
  }
}

export const subscriptionAllowMessageTypesMixin = {
  mixins: [
    subscriptionAllowMessageTypeScheduleMixin,
    subscriptionAllowMessageTypeRightNowMixin,
    subscriptionAllowMessageTypeTriggerMixin,
    subscriptionAllowMessageTypeCommandMixin,
    subscriptionAllowMessageTypeKeywordMixin,
    subscriptionAllowQuestionaryMixin,
    subscriptionAllowNotificationMixin,
    chatAllowQuestionaryMixin
  ],
  computed: {
    ...mapState('auth', ['subscription']),
    ...mapState('chat', ['chat_platform_id']),

    allowMessageTypes() {
      if (!this.subscription || !this.subscription.id) {
        return []
      }

      return ChatMessageTypeModel.types.filter((type) => {
        switch (type.value) {
          case ChatMessageTypeModel.SCHEDULE:
            return (
              this.allowMessageTypeSchedule ||
              this.allowMessageTypeSchedulePreview
            )
          case ChatMessageTypeModel.RIGHT_NOW:
            return (
              this.allowMessageTypeRightNow ||
              this.allowMessageTypeRightNowPreview
            )
          case ChatMessageTypeModel.TRIGGER:
            return (
              this.allowMessageTypeTrigger ||
              this.allowMessageTypeTriggerPreview
            )
          case ChatMessageTypeModel.COMMAND:
            return (
              ChatPlatformModel.hasCommand(this.chat_platform_id) &&
              (this.allowMessageTypeCommand || this.allowMessageTypeCommand)
            )
          case ChatMessageTypeModel.KEYWORD:
            return (
              this.allowMessageTypeKeyword ||
              this.allowMessageTypeKeywordPreview
            )
          case ChatMessageTypeModel.MENU:
            return ChatPlatformModel.hasMenu(this.chat_platform_id)
          default:
            return true
        }
      })
    },

    automationMessageTypes() {
      let types = this.allowMessageTypes.filter(
        ({ value }) => value !== ChatMessageTypeModel.RIGHT_NOW
      )

      if (
        this.chatAllowQuestionary() &&
        (this.allowQuestionary || this.allowQuestionaryPreview)
      ) {
        const index = types.findIndex(
          ({ value }) => value === ChatMessageTypeModel.SCHEDULE
        )

        const type = {
          label: this.$t('main-words.questionaries'),
          value: ChatMessageTypeModel.PAGE_QUESTIONARY,
          faq: this.$t('services.faq.bot.content.questionary.tip'),
          iconNew: 'icon-dialogs',
          image: new URL("@/assets/icons//chat/message/form/command-mail.svg", import.meta.url).href,
          imagePrimary: new URL("@/assets/icons//chat/message/form/command-mail-primary.svg", import.meta.url).href
        }

        index === -1 ? types.unshift(type) : types.splice(index + 1, 0, type)
      }

      if (this.allowNotification || this.allowNotificationPreview) {
        types.push({
          label: this.$t('bot.settings.notification.title'),
          value: ChatMessageTypeModel.PAGE_NOTIFICATION,
          iconNew: 'icon-notifications',
          faq: this.$t('services.faq.bot.settings.notifications'),
          image: new URL("@/assets/icons//chat/message/form/notification-mail.svg", import.meta.url).href,
          imagePrimary: new URL("@/assets/icons//chat/message/form/notification-mail-primary.svg", import.meta.url).href
        })
      }

      return types
    }
  }
}

export const subscriptionAllowWidgetMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowWidget() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_widget
      )
    },

    allowWidgetPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_widget === null
      )
    }
  }
}

export const subscriptionAllowWidgetCodeMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowWidgetCode() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_widget_code
      )
    },

    allowWidgetCodePreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_widget_code === null
      )
    }
  }
}

export const subscriptionAllowSubReferralMixin = {
  computed: {
    ...mapState('auth', ['subscription']),

    allowSubReferral() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_sub_referral
      )
    },

    allowSubReferralPreview() {
      return (
        this.subscription &&
        this.subscription.id &&
        this.subscription.type.allow_sub_referral === null
      )
    },

    subReferralLevelLimit() {
      return this.subscription?.type?.limit_referral_level_count
    }
  }
}

export const subscriptionAllowContentMixin = {
  mixins: [
    subscriptionAllowLongreadMixin,
    subscriptionAllowLongreadPrivateMixin,
    subscriptionAllowHomeworkMixin,
    subscriptionAllowVideoMixin,
    chatAllowContentMixin
  ],
  computed: {
    allowContent() {
      return (
        this.allowLongread ||
        this.allowLongreadPreview ||
        this.allowHomework() ||
        this.allowHomeworkPreview() ||
        this.allowVideo ||
        this.allowVideoPreview
      )
    },
    contentPage() {
      if (
        this.chatAllowLongread(this.role_id) &&
        (this.allowLongread || this.allowLongreadPreview)
      ) {
        return 'longreads'
      }

      if (
        (this.chatAllowHomework(this.role_id) ||
          this.chatAllowHomeworkResult(this.role_id)) &&
        (this.allowHomework(this.platform_id) ||
          this.allowHomeworkPreview(this.platform_id))
      ) {
        return 'homeworks'
      }

      if (
        this.chatAllowVideo(this.role_id) &&
        (this.allowVideo || this.allowVideoPreview)
      ) {
        return 'videos'
      }

      return ''
    }
  }
}

export const subscriptionAllowPromotionMixin = {
  mixins: [
    subscriptionAllowWebinarMixin,
    subscriptionAllowBotLandingMixin,
    subscriptionAllowWidgetMixin,
    subscriptionAllowSubReferralMixin,
    chatAllowPromotionMixin
  ],
  computed: {
    allowPromotion() {
      return (
        this.allowWebinar ||
        this.allowWebinarPreview ||
        this.allowBotLanding ||
        this.allowBotLandingPreview ||
        this.allowWidget ||
        this.allowWidgetPreview ||
        this.allowSubReferral ||
        this.allowSubReferralPreview
      )
    },
    promotionPage() {
      if (
        this.chatAllowBotLanding(this.role_id) &&
        (this.allowBotLanding || this.allowBotLandingPreview)
      ) {
        return 'landings'
      }

      if (
        this.chatAllowWebinar(this.role_id) &&
        (this.allowWebinar || this.allowWebinarPreview)
      ) {
        return 'webinars'
      }

      if (
        this.chatAllowWidget(this.role_id) &&
        (this.allowWidget || this.allowWidgetPreview)
      ) {
        return 'widgets'
      }

      if (
        this.chatAllowSubReferral(this.role_id) &&
        (this.allowSubReferral || this.allowSubReferralPreview)
      ) {
        return 'referral'
      }
    }
  }
}

export const subscriptionAllowPaymentMixin = {
  mixins: [subscriptionAllowShopMixin],
  computed: {
    allowPayment() {
      return this.allowShop || this.allowShopPreview
    }
  }
}
