export const adminUserGuideRoutes = [
  {
    path: 'admin-guide/guides',
    component: () => import('@/js/components/admin/super/user/guide/IndexAdminUserGuide.vue'),
    name: 'IndexAdminUserGuide',
    meta: {
      requireAdminUserGuide: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-guide/guides/store',
    component: () => import('@/js/components/admin/super/user/guide/StoreAdminUserGuide.vue'),
    name: 'StoreAdminUserGuide',
    meta: {
      requireAdminUserGuide: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-guide/guides/:item_id/update',
    component: () => import('@/js/components/admin/super/user/guide/UpdateAdminUserGuide.vue'),
    name: 'UpdateAdminUserGuide',
    meta: {
      requireAdminUserGuide: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-guide/guides/:guide_id/landings',
    component: () => import('@/js/components/admin/super/user/guide/landing/IndexAdminUserGuideLanding.vue'),
    name: 'IndexAdminUserGuideLanding',
    meta: {
      requireAdminUserGuide: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-guide/guides/:guide_id/landings/store',
    component: () => import('@/js/components/admin/super/user/guide/landing/StoreAdminUserGuideLanding.vue'),
    name: 'StoreAdminUserGuideLanding',
    meta: {
      requireAdminUserGuide: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-guide/guides/:guide_id/landings/:item_id/update',
    component: () => import('@/js/components/admin/super/user/guide/landing/UpdateAdminUserGuideLanding.vue'),
    name: 'UpdateAdminUserGuideLanding',
    meta: {
      requireAdminUserGuide: true,
      requireAuth: true
    },
    props: true
  }
]
