import { i18n } from '@/js/plugins/i18n'
import { Model } from '@/js/models/Model'

export class ChatWidgetTypeModel extends Model {
  static BIG = 1

  static MEDIUM = 2

  static MINI = 3

  static get types() {
    return [
      {
        value: this.BIG,
        label: i18n.t('models.chat.widget.type.big')
      },
      {
        value: this.MEDIUM,
        label: i18n.t('models.chat.widget.type.medium')
      },
      {
        value: this.MINI,
        label: i18n.t('models.chat.widget.type.mini')
      }
    ]
  }
}
