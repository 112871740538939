export const adminSuperJournalRoutes = [
  {
    path: 'admin/journal/posts',
    component: () => import('@/js/components/admin/super/journal/post/IndexAdminJournalPost.vue'),
    name: 'IndexAdminJournalPost',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/journal/posts/store',
    component: () => import('@/js/components/admin/super/journal/post/StoreAdminJournalPost.vue'),
    name: 'StoreAdminJournalPost',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/journal/posts/:item_id/update',
    component: () => import('@/js/components/admin/super/journal/post/UpdateAdminJournalPost.vue'),
    name: 'UpdateAdminJournalPost',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  }
]
