export const adminSuperSubscriptionPreviewRoutes = [
  {
    path: 'admin/subscription/previews',
    component: () => import('@/js/components/admin/super/subscription/preview/IndexAdminSubscriptionPreview.vue'),
    name: 'IndexAdminSubscriptionPreview',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/subscription/previews/store',
    component: () => import('@/js/components/admin/super/subscription/preview/StoreAdminSubscriptionPreview.vue'),
    name: 'StoreAdminSubscriptionPreview',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/subscription/previews/:item_id/update',
    component: () => import('@/js/components/admin/super/subscription/preview/UpdateAdminSubscriptionPreview.vue'),
    name: 'UpdateAdminSubscriptionPreview',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  }
]
