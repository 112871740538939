export const chatFolderRoutes = [
  {
    path: 'chat/:chat_id/folders/:type_id',
    component: () => import('@/js/components/chat/folder/IndexChatFolder.vue'),
    name: 'IndexChatFolder',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/folders/:type_id/store',
    component: () => import('@/js/components/chat/folder/StoreChatFolder.vue'),
    name: 'StoreChatFolder',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/folders/:type_id/:item_id/update',
    component: () => import('@/js/components/chat/folder/UpdateChatFolder.vue'),
    name: 'UpdateChatFolder',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
