import {ChatModel} from '@/js/models/chat/ChatModel'
import {ChatSubscriberModel} from '@/js/models/chat/subscriber/ChatSubscriberModel'
import { ChatCourseModel } from '@/js/models/chat/course/ChatCourseModel.js'
import { ChatCourseLessonModel } from '@/js/models/chat/course/ChatCourseLessonModel.js'

export class ChatHomeworkModel {
    static URL = 'homeworks'
    static BOOLEAN_KEYS = ['allow_edit', 'allow_mutual_check', 'allow_autocheck']
    static JSON_KEYS = ['actions_result_accepted']

    static urlForWebApp(homework_url) {
        return `homeworks/${homework_url}`;
    }

    constructor() {
        this.searchForm = {
            folder_id: null
        }

        this.form = {
            id: null,
            chat_id: null,
            folder_id: null,
            name: null,
            description: null,
            description_success: null,
            allow_edit: false,
            allow_mutual_check: false,
            allow_autocheck: false,
            is_inside: 0,
            actions_result_accepted: []
        }
    }

    static url(chatId) {
        return `${ChatModel.URL}/${chatId}/homeworks`
    }

    static urlStat(chatId) {
        return `${this.url(chatId)}/stat`
    }

    static urlPublic(chatId, subUid) {
        return `${ChatSubscriberModel.urlPublic(chatId, subUid)}/homeworks`
    }

    static webAppUrl(homework_id=null) {
        let url = `${ChatCourseLessonModel.urlForWebApp()}/homeworks`

        if(homework_id) {
            url += `/${homework_id}`
        }

        return url
    }

    static getName(item) {
        return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
    }
}
