import { ChatWebinarSessionRepeatModel } from '@/js/models/chat/webinar/ChatWebinarSessionRepeatModel'
import { ChatWebinarModel } from '@/js/models/chat/webinar/ChatWebinarModel'

export class ChatWebinarSessionModel {
  static url(chat_id, webinar_id) {
    return `${ChatWebinarModel.url(chat_id)}/${webinar_id}/sessions`
  }

  constructor() {
    this.form = {
      datetime: null,
      timezone: null,
      time_stop: null,
      days_stop: null,
      duration: null,
      repeat_id: null,
      registration_limit: null
    }
  }

  static get timeOptions() {
    return {
      start: '00:00',
      step: '00:01',
      end: '23:59'
    }
  }

  static name(item) {
    let name = item.datetime

    if (item.repeat_id) {
      name +=
        ', ' +
        ChatWebinarSessionRepeatModel.getNameById(item.repeat_id).toLowerCase()
    }

    return name
  }
}
