import { ChatProductPaymentSettingModel } from '@/js/models/chat/product/ChatProductPaymentSettingModel'

const initialState = () => {
  return {
    chat_product_payment_settings: [],
    chat_product_payment_settings_loaded: false,
    chat_product_payment_recurrent_settings: [],
    chat_product_payment_recurrent_settings_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, { isLoaded, is_recurrent }) {
      let key = is_recurrent
        ? 'chat_product_payment_recurrent_settings_loaded'
        : 'chat_product_payment_settings_loaded'

      state[key] = isLoaded
    },

    setPaymentSettings(state, { settings, is_recurrent }) {
      let key = is_recurrent
        ? 'chat_product_payment_recurrent_settings'
        : 'chat_product_payment_settings'

      state[key] = []

      settings.forEach(({ id, name, aggregator_id }) =>
        state[key].push({ id: id, name: name, aggregator_id: aggregator_id })
      )
    },

    deletePaymentSetting(state, id) {
      let keys = [
        'chat_product_payment_settings',
        'chat_product_payment_recurrent_settings'
      ]

      keys.forEach((key) => {
        let index = state[key].findIndex((item) => item.id === id)

        if (index === -1) return

        state[key].splice(index, 1)
      })
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchPaymentSettings({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatProductPaymentSettingModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setPaymentSettings', {
              settings: data.items,
              is_recurrent: params.is_recurrent
            })

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetPaymentSettings')

            return reject(error)
          })
      })
    },

    setPaymentSettings({ commit }, { settings, is_recurrent }) {
      commit('setPaymentSettings', {
        settings: settings,
        is_recurrent: is_recurrent
      })

      commit('setLoaded', { isLoaded: true, is_recurrent: is_recurrent })
    },

    deletePaymentSetting({ commit }, id) {
      commit('deletePaymentSetting', id)
    },

    resetPaymentSettings({ commit }) {
      commit('reset')
    }
  }
}
