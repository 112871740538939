import { chatLandingRoutes } from '@/js/routes/chat/content/landing/routes'
import { chatWebinarRoutes } from '@/js/routes/chat/content/webinar/routes'
import { chatCourseRoutes } from '@/js/routes/chat/content/course/routes'
import { chatHomeworkRoutes } from '@/js/routes/chat/content/homework/routes'
import { chatProposalRoutes } from '@/js/routes/chat/content/proposal/routes'
import { chatVideoRoutes } from '@/js/routes/chat/content/video/routes'
import { chatShopRoutes } from '@/js/routes/chat/content/shop/routes'
import { chatTournamentRoutes } from '@/js/routes/chat/content/tournament/routes'
import { chatDynamicImagesRoutes } from '@/js/routes/chat/content/dynamic-images/routes'
import { chatWebAppRoutes } from '@/js/routes/chat/content/webapp/routes'

export const chatContentRoutes = [
  ...chatLandingRoutes,
  ...chatWebinarRoutes,
  ...chatCourseRoutes,
  ...chatHomeworkRoutes,
  ...chatProposalRoutes,
  ...chatVideoRoutes,
  ...chatShopRoutes,
  ...chatTournamentRoutes,
  ...chatDynamicImagesRoutes,
  ...chatWebAppRoutes,
  {
    path: 'chat/:chat_id/content/:page/:sub_page?',
    component: () => import('@/js/components/chat/content/IndexChatContent.vue'),
    name: 'IndexChatContent',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/promotion/:page/:sub_page?',
    component: () => import('@/js/components/chat/content/IndexChatPromotion.vue'),
    name: 'IndexChatPromotion',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/payment/:page/:sub_page?',
    component: () => import('@/js/components/chat/content/IndexChatPayment.vue'),
    name: 'IndexChatPayment',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
