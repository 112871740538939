import { UploadFileTypeModel } from '@/js/models/upload/UploadFileTypeModel'
import { ChatModel } from '@/js/models/chat/ChatModel'

export class UploadModel {
  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/uploads`
  }

  constructor(props) {
    this.form = {
      remote_uuid: null,
      is_remote: false,
      is_multiple: false,
      file_name: null,
      file_type_id: UploadFileTypeModel.PHOTO
    }
  }
}
