import { adminSuperPromocodeRoutes } from '@/js/routes/admin/super/promocode/routes'
import { adminSuperMoneyRoutes } from '@/js/routes/admin/super/replenishment/routes'
import { adminSuperSubscriptionPreviewRoutes } from '@/js/routes/admin/super/subscription/preview/routes'
import { adminSuperSubscriptionTypeRoutes } from '@/js/routes/admin/super/subscription/type/routes'
import { adminSuperVideoRoutes } from '@/js/routes/admin/super/video/routes'
import { adminSuperJournalRoutes } from '@/js/routes/admin/super/journal/routes'
import { adminSuperChatMessageRoutes } from '@/js/routes/admin/super/chat/message/routes'

export const adminSuperRoutes = [
  ...adminSuperPromocodeRoutes,
  ...adminSuperMoneyRoutes,
  ...adminSuperSubscriptionPreviewRoutes,
  ...adminSuperSubscriptionTypeRoutes,
  ...adminSuperVideoRoutes,
  ...adminSuperJournalRoutes,
  ...adminSuperChatMessageRoutes,
  ...[
    {
      path: 'admin',
      component: () => import('@/js/components/admin/super/IndexAdmin.vue'),
      name: 'IndexAdmin',
      meta: {
        requireAdmin: true,
        requireAuth: true
      },
      props: true
    },

    {
      path: 'admin/users',
      component: () => import('@/js/components/admin/super/user/IndexAdminUser.vue'),
      name: 'IndexAdminUser',
      meta: {
        requireAdmin: true,
        requireAuth: true
      },
      props: true
    },
    {
      path: 'admin/backgrounds',
      component: () => import('@/js/components/admin/super/background/IndexAdminBackground.vue'),
      name: 'IndexAdminBackground',
      meta: {
        requireAdmin: true,
        requireAuth: true
      },
      props: true
    },
    {
      path: 'admin/request-webhooks',
      component: () => import('@/js/components/admin/super/request/IndexAdminRequestWebhook.vue'),
      name: 'IndexAdminRequestWebhook',
      meta: {
        requireAdmin: true,
        requireAuth: true
      },
      props: true
    }
  ]
]
