export const adminSuperMoneyRoutes = [
  {
    path: 'admin/users/:user_id/rollback-replenishment',
    component: () => import('@/js/components/admin/super/money/StoreAdminReplenishmentRollback.vue'),
    name: 'StoreAdminRollbackReplenishment',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/users/:user_id/replenishment',
    component: () => import('@/js/components/admin/super/money/StoreAdminReplenishment.vue'),
    name: 'StoreAdminReplenishment',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/users/:user_id/withdraw',
    component: () => import('@/js/components/admin/super/money/StoreAdminWithdraw.vue'),
    name: 'StoreAdminWithdraw',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  }
]
