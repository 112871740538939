import { ChatModel } from '@/js/models/chat/ChatModel'
import { ChatProposalFieldModel } from '@/js/models/chat/proposal/ChatProposalFieldModel'

export class ChatProposalModel {
  static url(chatId) {
    return `${ChatModel.URL}/${chatId}/proposals`
  }

  static urlStat(chatId) {
    return `${this.url(chatId)}/stat`
  }

  static BOOLEAN_KEYS = ['allow_edit']

  static JSON_KEYS = ['fields']

  constructor() {
    this.searchForm = {
      folder_id: null
    }

    let field = new ChatProposalFieldModel().form

    this.form = {
      id: null,
      chat_id: null,
      folder_id: null,
      name: null,
      allow_edit: false,
      fields: [field]
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }
}
