export const adminSuperChatMessageRoutes = [
  {
    path: 'admin/chat/messages/:type_id',
    component: () => import('@/js/components/admin/super/chat/message/IndexAdminChatMessage.vue'),
    name: 'IndexAdminChatMessage',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/chat/messages/:type_id/store',
    component: () => import('@/js/components/admin/super/chat/message/StoreAdminChatMessage.vue'),
    name: 'StoreAdminChatMessage',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/chat/messages/:type_id/:message_id/update',
    component: () => import('@/js/components/admin/super/chat/message/UpdateAdminChatMessage.vue'),
    name: 'UpdateAdminChatMessage',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  }
]
