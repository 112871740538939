import { i18n } from '@/js/plugins/i18n'

import { Model } from '@/js/models/Model'

export class ChatMessageButtonTypeModel extends Model {
  static LINK = 1

  static LANDING = 2

  static SUB_FORM = 3

  static STAGE = 4

  static HOMEWORK = 5

  static PRODUCT = 6

  static PRODUCT_RECURRENT = 7

  static WEBINAR_SESSION = 8

  static COURSE = 9

  static TAG = 10

  static FOLDER_SCHEDULE = 11

  static VIDEO = 12

  static QUESTIONARY = 13

  static RECURRING_NOTIFICATION = 14

  static WEB_APP = 15

  static get types() {
    return [
      {
        value: this.LANDING,
        label: i18n.t('models.chat.message.button.type.landing'),
        faq: i18n.t('services.faq.bot.message.button.type.landing'),
        icon: 'el-icon-connection'
      },
      {
        value: this.LINK,
        label: i18n.t('models.chat.message.button.type.link'),
        faq: i18n.t('services.faq.bot.message.button.type.link'),
        icon: 'el-icon-connection'
      },
      {
        value: this.SUB_FORM,
        label: i18n.t('models.chat.message.button.type.sub-form'),
        faq: i18n.t('services.faq.bot.message.button.type.sub-form'),
        icon: 'el-icon-set-up'
      },
      {
        value: this.STAGE,
        label: i18n.t('models.chat.message.button.type.stage'),
        faq: i18n.t('services.faq.bot.message.button.type.stage'),
        icon: 'el-icon-files'
      },
      {
        value: this.HOMEWORK,
        label: i18n.t('models.chat.message.button.type.homework'),
        faq: i18n.t('services.faq.bot.message.button.type.homework'),
        icon: 'el-icon-reading'
      },
      {
        value: this.PRODUCT,
        label: i18n.t('models.chat.message.button.type.product'),
        faq: i18n.t('services.faq.bot.message.button.type.product'),
        icon: 'el-icon-shopping-cart-2'
      },
      {
        value: this.PRODUCT_RECURRENT,
        label: i18n.t('models.chat.message.button.type.product-recurrent'),
        faq: i18n.t('services.faq.bot.message.button.type.product-recurrent'),
        icon: 'el-icon-check'
      },
      {
        value: this.WEBINAR_SESSION,
        label: i18n.t('models.chat.message.button.type.webinar-session'),
        faq: i18n.t('services.faq.bot.message.button.type.webinar-session'),
        icon: 'el-icon-video-camera'
      },
      {
        value: this.COURSE,
        label: i18n.t('models.chat.message.button.type.course'),
        faq: i18n.t('services.faq.bot.message.button.type.course'),
        icon: 'el-icon-star-off'
      },
      {
        value: this.TAG,
        label: i18n.t('models.chat.message.button.type.tag'),
        faq: i18n.t('services.faq.bot.message.button.type.tag'),
        icon: 'el-icon-price-tag'
      },
      {
        value: this.FOLDER_SCHEDULE,
        label: i18n.t('models.chat.message.button.type.folder-schedule'),
        faq: i18n.t('services.faq.bot.message.button.type.folder-schedule'),
        icon: 'el-icon-chat-line-square'
      },
      {
        value: this.VIDEO,
        label: i18n.t('models.chat.message.button.type.video'),
        faq: i18n.t('services.faq.bot.message.button.type.video'),
        icon: 'el-icon-video-camera-solid'
      },
      {
        value: this.QUESTIONARY,
        label: i18n.t('models.chat.message.button.type.questionary'),
        faq: i18n.t('services.faq.bot.message.button.type.questionary'),
        icon: 'el-icon-s-order'
      },
      {
        value: this.RECURRING_NOTIFICATION,
        label: i18n.t('models.chat.message.button.type.recurring-notification'),
        faq: i18n.t(
          'services.faq.bot.message.button.type.recurring-notification'
        ),
        icon: 'el-icon-unlock'
      },
      {
        value: this.WEB_APP,
        label: i18n.t('models.chat.message.button.type.web-app'),
        faq: i18n.t('services.faq.bot.message.button.type.web-app'),
        icon: 'el-icon-menu'
      }
    ]
  }

  static hasAfterClick(typeId) {
    return [
      this.SUB_FORM,
      this.STAGE,
      this.TAG,
      this.FOLDER_SCHEDULE,
      this.QUESTIONARY
    ].includes(typeId)
  }

  static hasInside(typeId) {
    return [this.LINK].includes(typeId)
  }
}
