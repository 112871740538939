import { i18n } from '@/js/plugins/i18n'

import { requiredFinalMessage } from '@/js/services/ValidationRuleService'

import { ChatModel } from '@/js/models/chat/ChatModel'
import { QuestionAnswerFieldModel } from '@/js/models/question/QuestionAnswerFieldModel'
import { QuestionModel } from '@/js/models/question/QuestionModel'
import { QuestionTypeModel } from '@/js/models/question/QuestionTypeModel'

export class ChatQuestionaryModel {
  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/questionaries`
  }

  static FILLABLE = [
    'name',
    'allow_edit',
    'redirect_text',
    'timeout',
    'folder_id',
    'proposal_id'
  ]

  constructor() {
    this.searchForm = {
      folder_id: null
    }

    let question = new QuestionModel(QuestionTypeModel.FINAL).form
    question.text.html = i18n.t(
      'bot.message.form.tabs.questionary.final-default-text'
    )

    let questions = [question]

    this.form = {
      id: null,
      folder_id: null,
      has_proposal: false,
      proposal_id: null,
      proposal: null,
      name: null,
      has_stat: false,
      has_timeout: false,
      timeout: null,
      allow_edit: false,
      redirect_text: true,
      questions: questions,
      has_points: false,
      points_field: new QuestionAnswerFieldModel().form,
      points_sum_field: new QuestionAnswerFieldModel().form,
      points_start: new QuestionAnswerFieldModel(true).form
    }
  }

  get rules() {
    const finalQuestionValidator = (rule, value, callback) => {
      this.form.questions.forEach((item) => {
        if (item.type_id === QuestionTypeModel.FINAL) {
          return callback()
        }
      })

      callback(new Error(i18n.t(requiredFinalMessage)))
    }

    return {
      name: [{ validator: finalQuestionValidator, trigger: 'blur' }]
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }
}
