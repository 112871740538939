export const adminWhiteLabelSubscriptionTypeRoutes = [
  {
    path: 'admin-wl/subscription/types',
    component: () => import('@/js/components/admin/whitelabel/subscription/type/IndexAdminWhiteLabelSubscriptionType.vue'),
    name: 'IndexAdminWhiteLabelSubscriptionType',
    meta: {
      requireAdminWhiteLabel: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-wl/subscription/types/store',
    component: () => import('@/js/components/admin/whitelabel/subscription/type/StoreAdminWhiteLabelSubscriptionType.vue'),
    name: 'StoreAdminWhiteLabelSubscriptionType',
    meta: {
      requireAdminWhiteLabel: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/subscription/types/:item_id/update',
    component: () => import('@/js/components/admin/whitelabel/subscription/type/UpdateAdminWhiteLabelSubscriptionType.vue'),
    name: 'UpdateAdminWhiteLabelSubscriptionType',
    meta: {
      requireAdminWhiteLabel: true,
      requireAuth: true
    },
    props: true
  },

  {
    path: 'admin-wl/subscription/types/:type_id/subtypes',
    component: () => import('@/js/components/admin/whitelabel/subscription/type/subtype/IndexAdminWhiteLabelSubscriptionSubType.vue'),
    name: 'IndexAdminWhiteLabelSubscriptionSubType',
    meta: {
      requireAdminWhiteLabel: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-wl/subscription/types/:type_id/subtypes/store',
    component: () => import('@/js/components/admin/whitelabel/subscription/type/subtype/StoreAdminWhiteLabelSubscriptionSubType.vue'),
    name: 'StoreAdminWhiteLabelSubscriptionSubType',
    meta: {
      requireAdminWhiteLabel: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-wl/subscription/types/:type_id/subtypes/:item_id/update',
    component: () => import('@/js/components/admin/whitelabel/subscription/type/subtype/UpdateAdminWhiteLabelSubscriptionSubType.vue'),
    name: 'UpdateAdminWhiteLabelSubscriptionSubType',
    meta: {
      requireAdminWhiteLabel: true,
      requireAuth: true
    },
    props: true
  }
]
