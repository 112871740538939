import { ChatModel } from '@/js/models/chat/ChatModel'
import { ChatGptConditionModel } from '@/js/models/chat/gpt/ChatGptConditionModel'

export class ChatGptModel {
  static PLATFORM_OPENAI = 1

  static MODEL_GPT_3_5_TURBO = 1
  static MODEL_GPT_4 = 2
  static MODEL_GPT_4_TURBO = 3
  static MODEL_GPT_4_O = 4
    static MODEL_GPT_4_O_MINI = 5
    static MODEL_GPT_O_1 = 6
    static MODEL_GPT_O_1_MINI = 7

  static CONDITION_TYPE_TEXT = 1
  static CONDITION_TYPE_ANSWER_COUNT = 2

  static get platformTypes() {
    return [
      {
        label: 'OpenAI',
        value: this.PLATFORM_OPENAI
      }
    ]
  }

  static get modelTypes() {
    return [
      {
        label: 'gpt-3.5-turbo',
        value: this.MODEL_GPT_3_5_TURBO
      },
      {
        label: 'gpt-4',
        value: this.MODEL_GPT_4
      },
      {
        label: 'gpt-4-turbo-preview',
        value: this.MODEL_GPT_4_TURBO
      },
      {
        label: 'gpt-4o',
        value: this.MODEL_GPT_4_O
      },
        {
            label: 'gpt-4o-mini',
            value: this.MODEL_GPT_4_O_MINI
        },
        {
            label: 'gpt-o1',
            value: this.MODEL_GPT_O_1
        },
        {
            label: 'gpt-o1-mini',
            value: this.MODEL_GPT_O_1_MINI
        }
    ]
  }

  static url(chatId) {
    return `${ChatModel.URL}/${chatId}/gpts`
  }

  static urlFiles(chatId) {
    return `${ChatModel.URL}/${chatId}/gpt-files`
  }

  constructor() {
    this.metadataForm = {
      key: '',
      value: ''
    }

    this.form = {
      id: null,
      chat_id: null,
      platform_id: ChatGptModel.PLATFORM_OPENAI,
      name: null,
      description: null,
      token: null,
      params: {
        has_history: false,
        tools: {
          code_interpreter: true,
          retrieval: false
        },
        conditions: [new ChatGptConditionModel().form],
        metadata: [],
        files: []
      }
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }
}
