import { ChatModel } from '@/js/models/chat/ChatModel'

export class ChatSubscriberTagModel {
  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/tags`
  }

  constructor() {
    this.form = {
      name: null,
      color: 'rgba(41, 112, 255, 1)',
      allow_edit: false
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }
}
