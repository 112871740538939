import { i18n } from '@/js/plugins/i18n'

import {
  maxLengthMessage,
  requiredTextMessage
} from '@/js/services/ValidationRuleService'

import { ChatPlatformModel } from '@/js/models/chat/ChatPlatformModel'
import { DataTypeModel } from '@/js/models/dataType/DataTypeModel'
import { QuestionTypeModel } from '@/js/models/question/QuestionTypeModel'

export class QuestionAnswerModel {
  constructor() {
    this.form = {
      text: null,
      next_question: null,
      correct: false,
      points: null,
      tag_id: null,
      tag_remove: false,
      url: null
    }
  }

  static DONT_SAVE_ANSWER = {
    prop: null,
    name: i18n.t('bot.message.form.tabs.questionary.dont-save-answer'),
    type_id: DataTypeModel.TEXT
  }

  static MAX_COUNT_QUIZ = 10

  static MAX_COUNT = (platform_id) => {
    switch (platform_id) {
      case ChatPlatformModel.TELEGRAM:
        return 100
      case ChatPlatformModel.VIBER:
        return 24
      case ChatPlatformModel.VK:
        return 5
      case ChatPlatformModel.SLACK:
        return 10
      case ChatPlatformModel.FACEBOOK:
      case ChatPlatformModel.INSTAGRAM:
        return 3
    }
  }

  static TEXT_RULES = (platform_id, type_id = null) => {
    return [
      {
        validator: (rule, value, callback) => {
          let length = value.length

          if (!length) {
            return callback(new Error(i18n.t(requiredTextMessage)))
          }

          if (type_id === QuestionTypeModel.QUIZ) {
            return length > 100
              ? callback(new Error(i18n.t(maxLengthMessage)))
              : callback()
          }

          switch (platform_id) {
            case ChatPlatformModel.TELEGRAM:
              length = new Blob([value]).size

              return length > 64
                ? callback(new Error(i18n.t(maxLengthMessage)))
                : callback()

            case ChatPlatformModel.VIBER:
            case ChatPlatformModel.FACEBOOK:
            case ChatPlatformModel.INSTAGRAM:
              return length > 256
                ? callback(new Error(i18n.t(maxLengthMessage)))
                : callback()

            case ChatPlatformModel.VK:
              return length > 40
                ? callback(new Error(i18n.t(maxLengthMessage)))
                : callback()

            case ChatPlatformModel.SLACK:
              return length > 75
                ? callback(new Error(i18n.t(maxLengthMessage)))
                : callback()
          }
        },
        trigger: 'blur'
      }
    ]
  }

  static QUIZ_CORRECT_RULES = (answers) => {
    return [
      {
        validator: (rule, value, callback) => {
          answers.forEach((item) => {
            if (item.correct) return callback()
          })

          return callback(new Error(''))
        },
        trigger: 'blur'
      }
    ]
  }
}
