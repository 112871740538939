import {
  maxLengthMessage,
  minLengthMessage
} from '@/js/services/ValidationRuleService'

import { i18n } from '@/js/plugins/i18n'

import { ChatPlatformModel } from '@/js/models/chat/ChatPlatformModel'

export class ChatMessageCommandModel {
  static COMMAND_START = 'start'

  static COMMAND_SUBSCRIPTION = 'subscription'

  static COMMAND_PAIR = 'pair'

  static PATTERN = /^[0-9a-z_]+$/

  static DESCRIPTION_TELEGRAM_LENGTH = 255

  static DESCRIPTION_FACEBOOK_LENGTH = 30

  constructor(props) {
    this.form = {
      name: null,
      description: null
    }
  }

  static get sysCommands() {
    return [this.COMMAND_START, this.COMMAND_SUBSCRIPTION, this.COMMAND_PAIR]
  }

  static nameValidator = (rule, value, callback) => {
    let length = value.length

    if (length > 32) {
      return callback(new Error(i18n.t(maxLengthMessage)))
    }

    ChatMessageCommandModel.sysCommands.forEach((command) => {
      if (value.substr(0, command.length) === command) {
        return callback(
          new Error(i18n.t('services.validation.command.invalid'))
        )
      }
    })

    if (!value.match(this.PATTERN)) {
      return callback(new Error(i18n.t('services.validation.command.pattern')))
    }

    callback()
  }

  static descriptionValidator = (rule, value, callback, platformId) => {
    let length = value.length

    if (length < 3) {
      return callback(new Error(i18n.t(minLengthMessage, { count: 3 })))
    }

    switch (platformId) {
      case ChatPlatformModel.TELEGRAM:
        if (length > ChatMessageCommandModel.DESCRIPTION_TELEGRAM_LENGTH) {
          return callback(new Error(i18n.t(maxLengthMessage)))
        }

        break
      case ChatPlatformModel.FACEBOOK:
        if (length > ChatMessageCommandModel.DESCRIPTION_FACEBOOK_LENGTH) {
          return callback(new Error(i18n.t(maxLengthMessage)))
        }

        break
    }

    callback()
  }
}
