export const chatWebAppRoutes = [
  {
    path: 'chat/:chat_id/content/webapps/store',
    component: () => import('@/js/components/chat/content/webapp/StoreChatWebApp.vue'),
    name: 'StoreChatWebApp',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/content/webapps/:item_id/update',
    component: () => import('@/js/components/chat/content/webapp/UpdateChatWebApp.vue'),
    name: 'UpdateChatWebApp',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
