export const chatHomeworkRoutes = [
  {
    path: 'chat/:chat_id/content/homeworks/store',
    component: () => import('@/js/components/chat/content/homework/StoreChatHomework.vue'),
    name: 'StoreChatHomework',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/content/homeworks/:homework_id/pairs/store',
    component: () => import('@/js/components/chat/content/homework/pair/StoreChatHomeworkPair.vue'),
    name: 'StoreChatHomeworkPair',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/content/homeworks/:homework_id/:page',
    component: () => import('@/js/components/chat/content/homework/IndexChatHomeworkTab.vue'),
    name: 'IndexChatHomeworkTab',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
