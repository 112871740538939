function initialState() {
  return {
    message_type_id: null
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setCurrentTypeId(state, data) {
      state.message_type_id = +data
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    setCurrentTypeId({ commit }, data) {
      commit('setCurrentTypeId', data)
    },

    reset({ commit }) {
      commit('reset')
    }
  }
}
