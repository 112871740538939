import { ChatGptModel } from '@/js/models/chat/gpt/ChatGptModel'

const initialState = () => {
  return {
    chat_gpts: [],
    chat_gpts_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_gpts_loaded = isLoaded
    },

    setGpts(state, gpts) {
      state.chat_gpts = []

      gpts.forEach((gpt) => state.chat_gpts.push(gpt))
    },

    deleteGpt(state, id) {
      let index = state.chat_gpts.findIndex((item) => item.id === id)

      if (index === -1) return

      state.chat_gpts.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchGpts({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatGptModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setGpts', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('reset')

            return reject(error)
          })
      })
    },

    setGpts({ commit }, gpts) {
      commit('setGpts', gpts)

      commit('setLoaded', true)
    },

    deleteGpt({ commit }, id) {
      commit('deleteGpt', id)
    },

    resetGpts({ commit }) {
      commit('reset')
    }
  }
}
