import { UserTypeModel } from '@/js/models/user/UserTypeModel'
import { UserModel } from '@/js/models/user/UserModel'
import { LogoutModel } from '@/js/models/auth/LogoutModel'
import { SubscriptionModel } from '@/js/models/subscription/SubscriptionModel'
import { ChatAdminModel } from '@/js/models/chat/admin/ChatAdminModel'
import { Trans } from '@/js/plugins/translation'

function initialState() {
  return {
    loading: false,

    is_logged_in: !!localStorage.getItem('token'),
    is_subscription_active: localStorage.getItem('is_subscription_active'),
    is_admin: localStorage.getItem('is_admin'),
    is_admin_wl: localStorage.getItem('is_admin_wl'),
    is_admin_support: localStorage.getItem('is_admin_support'),
    is_admin_user_guide: localStorage.getItem('is_admin_user_guide'),
    is_admin_user_goal: localStorage.getItem('is_admin_user_goal'),
    is_wp: localStorage.getItem('is_wp'),
    is_app_sumo: localStorage.getItem('is_app_sumo'),

    id: localStorage.getItem('id'),
    ym_id: localStorage.getItem('ym_id'),
    name: localStorage.getItem('name'),
    tel: localStorage.getItem('tel'),
    avatar: localStorage.getItem('avatar'),
    balance: localStorage.getItem('balance'),
    email: localStorage.getItem('email'),
    lang_id: localStorage.getItem('lang_id'),
    type_id: localStorage.getItem('type_id'),
    goal_id: localStorage.getItem('goal_id'),
    sub_domain_id: localStorage.getItem('sub_domain_id'),
    created_at: localStorage.getItem('created_at'),
    show_guide: localStorage.getItem('show_guide'),
    has_guide_landing: localStorage.getItem('has_guide_landing'),
    has_goal_questions: localStorage.getItem('has_goal_questions'),
    has_roles: localStorage.getItem('has_goal_questions'),
    guide_id: localStorage.getItem('guide_id'),
    guide_landing_id: localStorage.getItem('guide_landing_id'),

    subscription: localStorage.getItem('subscription') || new SubscriptionModel().form,
    subscription_tmp: null,
    subscribed_until: localStorage.getItem('subscribed_until'),
    subscribed_until_end: localStorage.getItem('subscribed_until_end'),
    subscriber_limit: localStorage.getItem('subscriber_limit'),

    bg: localStorage.getItem('bg'),
    bg_path: localStorage.getItem('bg_path'),
    bg_color: localStorage.getItem('bg_color'),

    zapier_token: localStorage.getItem('bg_color')
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setChatAdmin(state, data = null) {
      if (data) {
        state.subscription_tmp = state.subscription

        return (state.subscription = data.subscription)
      }

      if (state.subscription_tmp) {
        state.subscription = state.subscription_tmp
      }
    },

    setLoading(state, data) {
      state.loading = data
    },

    login(state, data) {
      state.is_logged_in = true

      localStorage.setItem('token', data.token)

      localStorage.setItem('token_type', data.token_type)
    },

    initUser(state, data) {
      for (let key in state) {
        if (!data.user.hasOwnProperty(key)) continue

        state[key] = data.user[key]

        if (data.user[key] === null) {
          data.user[key] = ''
        }

        localStorage.setItem(key, data.user[key])
      }

      state.has_goal_questions = state.has_goal_questions ? 1 : ''
      localStorage.setItem('has_goal_questions', state.has_goal_questions)

      state.has_roles = state.has_roles ? 1 : ''
      localStorage.setItem('has_roles', state.has_roles)
      /**
       * Required for router.beforeEach middleware
       * If we open new browser tab, state.subscription can not get in time
       */
      state.is_admin = state.type_id === UserTypeModel.ADMIN ? 1 : ''
      localStorage.setItem('is_admin', state.is_admin)

      state.is_admin_wl = [
        UserTypeModel.ADMIN_WHITE_LABEL,
        UserTypeModel.APP_SUMO_ADMIN_WHITE_LABEL
      ].includes(state.type_id)
        ? 1
        : ''
      localStorage.setItem('is_admin_wl', state.is_admin_wl)

      state.is_admin_support =
        state.type_id === UserTypeModel.ADMIN_SUPPORT ? 1 : ''
      localStorage.setItem('is_admin_support', state.is_admin_support)

      state.is_admin_user_guide =
        state.type_id === UserTypeModel.ADMIN_USER_GUIDE ? 1 : ''
      localStorage.setItem('is_admin_user_guide', state.is_admin_user_guide)

      state.is_admin_user_goal =
        state.type_id === UserTypeModel.ADMIN_USER_GOAL ? 1 : ''
      localStorage.setItem('is_admin_user_goal', state.is_admin_user_goal)

      state.is_wp = state.type_id === UserTypeModel.USER_WP ? 1 : ''
      localStorage.setItem('is_wp', state.is_wp)

      state.is_app_sumo = [
        UserTypeModel.APP_SUMO,
        UserTypeModel.APP_SUMO_ADMIN_WHITE_LABEL
      ].includes(state.type_id)
        ? 1
        : ''
      localStorage.setItem('is_app_sumo', state.is_app_sumo)

      state.is_subscription_active = state.subscribed_until !== false
      localStorage.setItem(
        'is_subscription_active',
        state.is_subscription_active
      )

      state.show_guide = state.show_guide ? 1 : ''
      localStorage.setItem('show_guide', state.show_guide)

      state.has_guide_landing = state.has_guide_landing ? 1 : ''
      localStorage.setItem('has_guide_landing', state.has_guide_landing)

      state.bg_path = state.bg ? state.bg.path : ''
      localStorage.setItem('bg_path', state.bg_path)

      state.bg_color = state.bg ? state.bg.color : ''
      localStorage.setItem('bg_color', state.bg_color)
    },

    updateUser(state, data) {
      state[data.prop] = data.value

      if (data.prop === 'bg') {
        state.bg_path = state.bg.path

        state.bg_color = state.bg.color
      }

      if (data.has_local_storage) {
        localStorage.setItem(data.prop, data.value)
      }
    },

    logout(state) {
      localStorage.clear()

      state.is_logged_in = false
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async logout({ commit, dispatch }) {
      try {
        await axios.get(LogoutModel.URL)
      } catch (e) {}

      const token = localStorage.getItem('main_token')

      const lang = localStorage.getItem('main_lang')

      const tokenType = localStorage.getItem('token_type')

      localStorage.clear()

      commit('logout')

      let data = { token: token, token_type: tokenType }

      if (lang) {
        await Trans.changeLanguage(lang)

        data.params = { lang: lang }
      }

        router.push({
            name: 'Login'
        })
    },

    login({ commit, dispatch }, data) {
      let cookies = document.cookie.split(';')

      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i]

        let eqPos = cookie.indexOf('=')

        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie

        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
      }

      commit('login', data)

      dispatch('initUser').then(() => {
        if (data.query && data.query.hasOwnProperty('redirect_to')) {
          return router.push(data.query.redirect_to)
        }

        if (data.params && data.params.hasOwnProperty('lang')) {
          const to = router.resolve({
            name: 'IndexChat',
            params: data.params,
            query: data.query
          })

          return router.push(to.location)
        }

        let fields = ['name','email','tel'];
        let auth = this.state.auth;
        let route = 'IndexChat'

        fields.forEach(function(field)
        {
            if(auth[field] === undefined || auth[field] === null || auth[field].length === 0) route = 'Profile';
        })

        router.push({
          name: route,
          params: data.params,
          query: data.query
        })
      })
    },

    initUser({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(UserModel.URL)
          .then((res) => {
            commit('initUser', { user: res.data })
          })
          .catch((error) => {
            reject(error)
          })
          .then(() => {
            resolve()
          })
      })
    },

    updateUser({ commit }, data) {
      commit('updateUser', data)
    },

    setChatAdmin({ commit }, chat_id) {
      commit('setLoading', true)

      axios
        .get(ChatAdminModel.urlSubscription(chat_id), {
          params: { chat_admin: 1 }
        })
        .then((res) => {
          commit('setChatAdmin', res.data)
        })
        .then(() => {
          commit('setLoading', false)
        })
    },

    resetChatAdmin({ commit }) {
      commit('setChatAdmin')
    },

    reset({ commit }) {
      commit('logout')

      commit('reset')

      localStorage.clear()
    }
  }
}
