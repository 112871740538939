import { i18n } from '@/js/plugins/i18n'

import { ChatModel } from '@/js/models/chat/ChatModel'

export class ChatProductPaymentSettingModel {
  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/payment-settings`
  }

  static publicPrefix(setting, chat) {
    return `https://${window.location.host}/api/chats/${chat.uuid}/shops/${setting.id}`
  }

  static publicUrlCheck(setting, chat) {
    return this.publicPrefix(setting, chat) + '/check'
  }

  static publicUrlPay(setting, chat) {
    return this.publicPrefix(setting, chat) + '/pay'
  }

  static publicUrlRecurrent(setting, chat) {
    return this.publicPrefix(setting, chat) + '/recurrent'
  }

  static get prodamusNpdTypes() {
    return [
      {
        label: i18n.t('main-words.individual'),
        value: 'FROM_INDIVIDUAL'
      },
      {
        label: i18n.t('main-words.entity'),
        value: 'FROM_LEGAL_ENTITY'
      },
      {
        label: i18n.t('main-words.foreign-organization'),
        value: 'FROM_FOREIGN_AGENCY'
      }
    ]
  }

  static get tinkoffTaxationTypes() {
    return [
      {
        label: i18n.t('bot.content.shop.setting.form.tinkoff.osn'),
        value: 'osn'
      },
      {
        label: i18n.t('bot.content.shop.setting.form.tinkoff.usn-income'),
        value: 'usn_income'
      },
      {
        label: i18n.t(
          'bot.content.shop.setting.form.tinkoff.usn-income-outcome'
        ),
        value: 'usn_income_outcome'
      },
      {
        label: i18n.t('bot.content.shop.setting.form.tinkoff.patent'),
        value: 'patent'
      },
      {
        label: i18n.t('bot.content.shop.setting.form.tinkoff.envd'),
        value: 'envd'
      },
      {
        label: i18n.t('bot.content.shop.setting.form.tinkoff.esn'),
        value: 'esn'
      }
    ]
  }

  constructor() {
    this.prodamus = {
      npd_income_type: null,
      npd_income_inn: null,
      npd_income_company: null
    }

    this.tinkoff = {
      taxation: null,
      additionalCheckProps: null
    }

    this.form = {
      id: null,
      chat_id: null,
      aggregator_id: null,
      name: null,
      is_recurrent: false,
      enable_email_or_tel: false,
      enable_email: false,
      enable_tel: false,
      enable_receipt: false,
      info: {
        domain: null,
        public_key: null,
        secret_key: null,
        secret_key_two: null,
        hash_type: null,
        prodamus: this.prodamus,
        tinkoff: this.tinkoff
      }
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }
}
