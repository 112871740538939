export const chatConversationRoutes = [
  {
    path: 'chat/:chat_id/conversation/:item_id?',
    component: () => import('@/js/components/chat/conversation/IndexChatConversation.vue'),
    name: 'IndexChatConversation',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/conversation-group/:item_id?',
    component: () => import('@/js/components/chat/conversation/IndexChatConversation.vue'),
    name: 'IndexChatGroupConversation',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/conversation-channel/:item_id?',
    component: () => import('@/js/components/chat/conversation/IndexChatConversation.vue'),
    name: 'IndexChatChannelConversation',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
