import { adminUserGoalRoutes } from '@/js/routes/admin/guide/goal/routes'
import { adminUserGuideRoutes } from '@/js/routes/admin/guide/guide/routes'

export const adminGuideRoutes = [
  ...adminUserGoalRoutes,
  ...adminUserGuideRoutes,
  ...[
    {
      path: 'admin-guide',
      component: () => import('@/js/components/admin/guide/IndexAdminGuide.vue'),
      name: 'IndexAdminGuide',
      meta: {
        requireAdminUserGuide: true,
        requireAuth: true
      },
      props: true
    },
    {
      path: 'admin-users-stat',
      component: () => import('@/js/components/admin/super/user/IndexAdminUserStat.vue'),
      name: 'IndexAdminUserStat',
      meta: {
        requireAdminUserGuide: true,
        requireAuth: true
      },
      props: true
    }
  ]
]
