export const chatVideoRoutes = [
  {
    path: 'chat/:chat_id/content/videos/:item_id/update',
    component: () => import('@/js/components/chat/content/video/UpdateChatVideo.vue'),
    name: 'UpdateChatVideo',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
