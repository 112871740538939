import {
    i18n
} from '@/js/plugins/i18n'

import {
    Model
} from '@/js/models/Model'
import {
    DataTypeModel
} from '@/js/models/dataType/DataTypeModel'
import {
    UploadFileTypeModel
} from '@/js/models/upload/UploadFileTypeModel'

export class ChatPlatformModel extends Model {
    static PROP_PHONE = 'tel'
    static PROP_EMAIL = 'email'

    static TELEGRAM = 1
    static VIBER = 2
    static VK = 3
    static FACEBOOK = 4
    static SLACK = 5
    static INSTAGRAM = 6

    static DEEP_LINK_FOLDER_SCHEDULE = 'wwb_fs'

    static get types() {
        return [{
            label: 'Telegram',
            label_short: 'TG',
            value: this.TELEGRAM,
            image: new URL("@/assets/icons/platform/telegram.svg", import.meta.url).href,
            help_video: 'https://www.youtube.com/embed/KAgvPrvlI7Q',
            help_text_command: '/newbot',
            help_link: 'https://t.me/botfather',
            help_link_text: '@BotFather.',
            help_text_1: i18n.t('services.faq.bot.token.tg.1'),
            help_text_2: i18n.t('services.faq.bot.token.tg.2'),
            help_text_3: i18n.t('services.faq.bot.token.tg.3')
        },
            {
                label: 'Viber',
                label_short: 'VB',
                value: this.VIBER,
                image: new URL("@/assets/icons/platform/viber.svg", import.meta.url).href,
                help_video: 'https://www.youtube.com/embed/SZD1VapeChc',
                help_text_command: '',
                help_link: 'https://partners.viber.com',
                help_link_text: 'https://partners.viber.com,',
                help_text_1: i18n.t('services.faq.bot.token.viber.1'),
                help_text_2: i18n.t('services.faq.bot.token.viber.2')
            },
            {
                label: 'VK',
                label_short: 'VK',
                value: this.VK,
                image: new URL("@/assets/icons/platform/vk.svg", import.meta.url).href,
                help_video: '',
                help_text_command: '',
                help_link: '',
                help_link_text: '',
                help_text_1: '',
                help_text_2: ''
            },
            {
                label: 'Facebook',
                value: this.FACEBOOK,
                image: new URL("@/assets/icons/platform/facebook.svg", import.meta.url).href,
                help_video: '',
                help_text_command: '',
                help_link: '',
                help_link_text: '',
                help_text_1: '',
                help_text_2: ''
            },
            {
                label: 'Slack',
                label_short: 'SL',
                value: this.SLACK,
                image: new URL("@/assets/icons/platform/slack.svg", import.meta.url).href
            },
            {
                label: 'Instagram',
                label_short: 'IG',
                value: this.INSTAGRAM,
                image: new URL("@/assets/icons/platform/instagram.svg", import.meta.url).href,
                help_video: '',
                help_text_command: '',
                help_link: '',
                help_link_text: '',
                help_text_1: '',
                help_text_2: ''
            }
        ]
    }

    static get activeTypes() {
        return this.types.filter((type) => [
            this.TELEGRAM,
            this.VIBER,
            this.VK,
            this.FACEBOOK,
            this.INSTAGRAM
        ].includes(type.value))
    }

    static get activeTypesByLocale() {
        let platforms = []

        this.activeTypes.forEach((type) => {
            if (type.value !== ChatPlatformModel.VK || i18n.locale === 'ru') {
                platforms.push(type)
            }
        })

        return platforms
    }

    static getHelpVideo(type_id) {
        return this.types.find((x) => x.value === type_id).help_video
    }

    static getHelpText1(type_id) {
        return this.types.find((x) => x.value === type_id).help_text_1
    }

    static getHelpText2(type_id) {
        return this.types.find((x) => x.value === type_id).help_text_2
    }

    static getHelpText3(type_id) {
        return this.types.find((x) => x.value === type_id).help_text_3
    }

    static getHelpLink(type_id) {
        return this.types.find((x) => x.value === type_id).help_link
    }

    static getHelpLinkText(type_id) {
        return this.types.find((x) => x.value === type_id).help_link_text
    }

    static getHelpTextCommand(type_id) {
        return this.types.find((x) => x.value === type_id).help_text_command
    }

    static getShortName(type_id) {
        return this.types.find((x) => x.value === type_id).label_short
    }

    static getMessengerLink(platformId, username, usernameId, linkUuid = '') {
        switch (+platformId) {
            case this.TELEGRAM:
                return `https://t.me/${username}?start=${linkUuid}`
            case this.VIBER:
                return `viber://pa?chatURI=${username}&context=${linkUuid}`
            case this.VK:
                return `https://vk.com/write-${usernameId}?ref=${linkUuid}`
            case this.FACEBOOK:
                return `http://m.me/${username}?ref=${linkUuid}`
            case this.SLACK:
                return `https://slack.com/app_redirect?channel=${usernameId}`
            case this.INSTAGRAM:
                return `https://ig.me/m/${username}`
        }
    }

    static getWebLink(platformId, username, usernameId) {
        switch (+platformId) {
            case this.TELEGRAM:
                return `https://t.me/${username}`
            case this.VIBER:
                return ''
            case this.VK:
                return `https://vk.com/write-${usernameId}`
            case this.FACEBOOK:
                return `https://m.me/${username}`
            case this.SLACK:
                return `https://slack.com/app_redirect?channel=${usernameId}`
            case this.INSTAGRAM:
                return `https://ig.me/m/${username}`
        }
    }

    static getImage(platformId, isGrey = false, isPrimary = false) {
        let type = this.types.find((x) => x.value === +platformId)

        if (!type) {
            return ''
        }

        let image = type.image

        /**
         * remove ".svg"
         */
        if (isPrimary) {
            return image.slice(0, -4) + '-primary.svg'
        }

        if (isGrey) {
            return image.slice(0, -4) + '-grey.svg'
        }

        return image
    }

    static getDefaultSubForm(platformId = null) {
        let defaultForm = [{
            name: () => i18n.t('main-words.name'),
            prop: 'name',
            type_id: DataTypeModel.TEXT,
            faq: () => i18n.t('services.faq.bot.subs.form.fields.name')
        },
            {
                name: () => i18n.t('main-words.last-name'),
                prop: 'last_name',
                type_id: DataTypeModel.TEXT,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.last-name')
            },
            {
                name: () => i18n.t('main-words.avatar'),
                prop: 'avatar',
                type_id: DataTypeModel.UPLOAD,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.avatar')
            },
            {
                name: () => i18n.t('main-words.tel'),
                prop: this.PROP_PHONE,
                type_id: DataTypeModel.TEXT,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.tel')
            },
            {
                name: () => i18n.t('main-words.email'),
                prop: 'email',
                type_id: DataTypeModel.TEXT,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.email')
            },
            {
                name: () => i18n.t('main-words.subscriber_id'),
                prop: 'uuid',
                type_id: DataTypeModel.TEXT,
                disabled: 1,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.uuid')
            },
            {
                name: () => i18n.t('main-words.custom_id'),
                prop: 'custom_id',
                type_id: DataTypeModel.TEXT,
                disabled: 1,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.custom_id')
            },
            {
                name: () => i18n.t('main-words.ym_id'),
                prop: 'ym_id',
                type_id: DataTypeModel.TEXT,
                disabled: 1,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.ym_id')
            },
            {
                name: () => i18n.t('main-words.ga_id'),
                prop: 'ga_id',
                type_id: DataTypeModel.TEXT,
                disabled: 1,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.ga_id')
            },
            {
                name: () => i18n.t('main-words.bdate'),
                prop: 'bdate',
                type_id: DataTypeModel.DATE,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.bdate')
            },
            {
                name: () => i18n.t('main-words.ref-code'),
                prop: 'ref',
                type_id: DataTypeModel.TEXT,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.ref')
            },
            {
                name: () => i18n.t('main-words.ref-id'),
                prop: 'REFID',
                type_id: DataTypeModel.TEXT,
                disabled: 1,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.ref-id')
            },
            {
                name: () => i18n.t('main-words.ref-count'),
                prop: 'REFCOUNT',
                type_id: DataTypeModel.INT,
                disabled: 1,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.ref-count')
            },
            {
                name: () => i18n.t('main-words.ref-balance'),
                prop: 'ref_balance',
                type_id: DataTypeModel.INT,
                disabled: 1,
                faq: () => i18n.t('services.faq.bot.subs.form.fields.ref-balance')
            },
            {
                name: () => 'Utm_source',
                prop: 'utm_source',
                type_id: DataTypeModel.TEXT,
                faq: () => ''
            },
            {
                name: () => 'Utm_medium',
                prop: 'utm_medium',
                type_id: DataTypeModel.TEXT,
                faq: () => ''
            },
            {
                name: () => 'Utm_campaign',
                prop: 'utm_campaign',
                type_id: DataTypeModel.TEXT,
                faq: () => ''
            },
            {
                name: () => 'Utm_term',
                prop: 'utm_term',
                type_id: DataTypeModel.TEXT,
                faq: () => ''
            },
            {
                name: () => 'Utm_content',
                prop: 'utm_content',
                type_id: DataTypeModel.TEXT,
                faq: () => ''
            }
        ]

        switch (platformId) {
            case this.TELEGRAM:
                let emailIndex = defaultForm.findIndex((item) => item.prop === 'email')

                let username = {
                    name: () => i18n.t('main-words.username-telegram'),
                    prop: 'username',
                    type_id: DataTypeModel.TEXT,
                    faq: () =>
                        i18n.t('services.faq.bot.subs.form.fields.telegram-username')
                }

                defaultForm.splice(emailIndex + 1, 0, username)

                break
        }

        return defaultForm
    }

    static getChatSubscriberFullDefaultForm() {
        return this.getDefaultSubForm(this.TELEGRAM)
    }

    static getSubscriberPlatformLink(platformId, sub) {
        switch (platformId) {
            case this.TELEGRAM:
                return sub.username ? `https://t.me/${sub.username}` : null
            case this.VK:
                return `https://vk.com/im?sel=${sub.subscriber_id}`
            case this.VIBER:
            case this.FACEBOOK:
            case this.INSTAGRAM:
                return null
            case this.SLACK:
                return `https://slack.com/app_redirect?channel=${sub.subscriber_id}`
        }
    }

    static hasCommand(platformId) {
        return [
            this.TELEGRAM,
            this.VIBER,
            this.VK,
            this.FACEBOOK,
            this.INSTAGRAM
        ].includes(platformId)
    }

    static hasCommandDescription(platformId) {
        return [this.TELEGRAM, this.FACEBOOK].includes(platformId)
    }

    static hasMenu(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static hasQuiz(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static hasButtons(platformId) {
        return [
            this.TELEGRAM,
            this.VIBER,
            this.VK,
            this.FACEBOOK,
            this.INSTAGRAM
        ].includes(platformId)
    }

    static hasPrivateAccess(platformId) {
        return [this.TELEGRAM, this.VK, this.FACEBOOK].includes(platformId)
    }

    static hasInside(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static hasStoryMention(platformId) {
        return [this.INSTAGRAM].includes(platformId)
    }

    static hasPrivateReply(platformId) {
        return [this.INSTAGRAM].includes(platformId)
    }

    static hasToken(platformId) {
        return [this.TELEGRAM, this.VIBER, this.SLACK].includes(platformId)
    }

    static hasTyping(platformId) {
        return [this.TELEGRAM, this.VK].includes(platformId)
    }

    static hasGroup(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static hasGroupInitMessage(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static hasDeepLink(platformId) {
        return [this.TELEGRAM, this.VIBER, this.VK, this.FACEBOOK].includes(
            platformId
        )
    }

    static hasAudio(platformId, isMultiple = false) {
        return isMultiple ?
            true :
            [this.TELEGRAM, this.VIBER, this.FACEBOOK, this.INSTAGRAM].includes(
                platformId
            )
    }

    static hasLocation(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static hasAudioRecord(platformId) {
        return [this.TELEGRAM, this.FACEBOOK].includes(platformId)
    }

    static hasIceBreaker(platformId) {
        return [this.FACEBOOK, this.INSTAGRAM].includes(platformId)
    }

    static hasProtectedContent(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static hasLinkPreview(platformId) {
        return [this.TELEGRAM, this.VK].includes(platformId)
    }

    static hasVideoNote(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static hasRemoteUpload(platformId) {
        return [this.TELEGRAM, this.VK].includes(platformId)
    }

    static hasContact(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static hasRecurringNotification(platformId) {
        return [this.FACEBOOK].includes(platformId)
    }

    static hasUploadText(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static canDeleteMessage(platformId) {
        return [this.TELEGRAM, this.VK].includes(platformId)
    }

    static canReply(platformId) {
        return [this.TELEGRAM, this.VK].includes(platformId)
    }

    static canUpdateButtons(platformId) {
        return [this.TELEGRAM].includes(platformId)
    }

    static canSendOnlyUpload(platformId, uploadTypeId) {
        switch (+platformId) {
            case this.VIBER:
                return +uploadTypeId === UploadFileTypeModel.FILE
            case this.FACEBOOK:
                return +uploadTypeId !== UploadFileTypeModel.PHOTO
            default:
                return false
        }
    }
}
