export const authRoutes = [
    {
        path: 'email',
        component: () => import('@/js/components/auth/SentToEmail.vue'),
        name: 'SentToEmail',
        meta: {
            requireGuest: true
        },
        props: true
    },
    {
        path: 'forgot-password',
        component: () => import('@/js/components/auth/ForgotPassword.vue'),
        name: 'ForgotPassword',
        meta: {
            requireGuest: true
        },
        props: true
    },
    {
        path: 'reset-password/:email/:token',
        component: () => import('@/js/components/auth/problems/ResetPassword.vue'),
        name: 'ResetPassword',
        meta: {
            requireGuest: true
        },
        props: true
    },
    {
        path: 'logout',
        component: () => import('@/js/components/auth/Logout.vue'),
        name: 'Logout',
        meta: {
            requireAuth: true
        }
    },
    {
        path: 'login',
        component: () => import('@/js/components/auth/Login.vue'),
        name: 'Login',
        meta: {
            requireGuest: true
        },
        props: true
    },
    {
        path: 'register',
        component: () => import('@/js/components/auth/Register.vue'),
        name: 'Register',
        meta: {
            requireGuest: true
        },
        props: true
    },
    {
        path: 'profile',
        component: () => import('@/js/components/auth/Profile.vue'),
        name: 'Profile',
        meta: {
            requireAuth: true
        },
        props: true
    },
    {
        path: '',
        component: () => import('@/js/components/auth/Login.vue'),
        name: 'LoginDefault',
        meta: {
            requireGuest: true
        },
        props: true
    },
]
