import {
  passwordConfirmError,
  passwordMinRule,
  requiredRule
} from '@/js/services/ValidationRuleService'

import { i18n } from '@/js/plugins/i18n'

export class UserModel {
  static URL = 'user'

  constructor() {
    this.form = {
      password: '',
      password_confirmation: ''
    }
  }

  get rules() {
    return {
      password: [requiredRule(), passwordMinRule()],
      password_confirmation: [
        requiredRule(),
        passwordMinRule(),
        {
          validator: (rule, value, callback) => {
            return value === this.form.password
              ? callback()
              : callback(new Error(i18n.t(passwordConfirmError)))
          }
        }
      ]
    }
  }
}
