function initialState() {
  return {
    chat_id: null,
    subscriber_id: null,
    isFullscreen: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setChatId(state, chat_id) {
      state.chat_id = chat_id
    },

    setSubscriberId(state, subscriber_id) {
      state.subscriber_id = +subscriber_id
    },

    setIsFullscreen(state, val) {
      state.isFullscreen = !!val
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    setSubscriberId({ commit }, data) {
      commit('setSubscriberId', data)
    },

    setIsFullscreen({ commit }, data) {
      commit('setIsFullscreen', data)
      const isSidebarOpen = data
        ? false
        : JSON.parse(localStorage.getItem('is_sidebar_open') || 'true')
      commit('setIsSidebarOpen', isSidebarOpen, { root: true })
    },

    reset({ commit }) {
      commit('reset')
    }
  }
}
