import { ChatQuestionaryModel } from '@/js/models/chat/questionary/ChatQuestionaryModel'

const initialState = () => {
  return {
    chat_questionaries: [],
    chat_questionaries_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_questionaries_loaded = isLoaded
    },

    setQuestionaries(state, stages) {
      state.chat_questionaries = []

      stages.forEach(({ id, name }) =>
        state.chat_questionaries.push({ id: id, name: name })
      )
    },

    deleteQuestionary(state, id) {
      let index = state.chat_questionaries.findIndex((item) => item.id === id)

      if (index === -1) return

      state.chat_questionaries.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchQuestionaries({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatQuestionaryModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setQuestionaries', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetQuestionaries')

            return reject(error)
          })
      })
    },

    setQuestionaries({ commit }, stages) {
      commit('setQuestionaries', stages)

      commit('setLoaded', true)
    },

    deleteQuestionary({ commit }, id) {
      commit('deleteQuestionary', id)
    },

    resetQuestionaries({ commit }) {
      commit('reset')
    }
  }
}
