<template>
  <button
    class="base-button"
    :class="`base-button_${mode}`"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
  export default {
    name: 'BaseButton',
    props: {
      mode: {
        type: String,
        default: 'primary'
      }
    }
  }
</script>

<style scoped lang="scss">
  .base-button {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;

    &_primary {
      font-size: 14px;
      font-weight: 500;
      line-height: 173%;
      border-radius: 10px;
      padding: 18px;
      width: 100%;
      color: #000;
      background: #fff;
    }
    &_primary-black {
      font-size: 14px;
      font-weight: 500;
      line-height: 173%;
      border-radius: 10px;
      padding: 18px;
      width: 100%;
      background: #1a1b1e;
      color: #fff;
    }
    &_outline {
      font-size: 14px;
      font-weight: 500;
      line-height: 173%;
      border-radius: 10px;
      padding: 18px;
      width: 100%;
      color: #6a6f7c;
      border: 1px solid #3c3f47;
    }
    &_transparent {
      padding: 5px;
    }
    &_black {
      font-family: e-Ukraine, sans-serif;
      padding: 10px 20px;
      background: #222529;
      border-radius: 100px;
      color: #fff;
      font-size: 14px;
      font-weight: 300;
    }
  }
</style>
