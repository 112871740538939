export const publicChatReferralRoutes = [
  {
    path: '/referral/:chatUuid',
    component: () => import( '@/js/components/public/chat/referral/PublicChatReferral.vue'),
    name: 'PublicChatReferral',
    meta: {
      isPublic: true
    },
    props: true
  }
]
