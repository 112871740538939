import {
    CHAT_ADMIN_KEY
}
    from '@/js/plugins/axios'

export const initialState = () => {
    return {
        chat_id: null,
        chat_user_id: null,
        chat_name: null,
        chat_image: null,
        chat_username: null,
        chat_username_id: null,
        chat_template_token: null,
        chat_template_parent_id: null,
        chat_platform_id: +localStorage.getItem('chat_platform_id'),
        chat_lang_id: +localStorage.getItem('chat_lang_id'),
        chat_type_id: +localStorage.getItem('chat_type_id'),
        chat_with_template: null,
        chat_sub_domain: null,
        chat_admin: !!localStorage.getItem(CHAT_ADMIN_KEY),
        chat_admin_role_id: +localStorage.getItem('chat_admin_role_id'),
        chat_hide_deleted_subs: !!localStorage.getItem('chat_hide_deleted_subs'),
        chat_hide_blocked_subs: !!localStorage.getItem('chat_hide_blocked_subs'),
        chat_referral: null,
        chat_unread_conversations: 0,
        chat_status_id: null,
        chat_template_has_update: null,
        chat_count_subs_total: 0
    }
}
export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        setData(state, data) {
            state.chat_id = data.id
            state.chat_name = data.name
            state.chat_username = data.username
            state.chat_username_id = data.username_id
            state.chat_image = data.image
            state.chat_user_id = data.user_id
            state.chat_platform_id = data.platform_id
            state.chat_lang_id = data.lang_id
            state.chat_sub_domain = data.sub_domain ? data.sub_domain : ''
            if(data.hasOwnProperty('unread_conversations')) {
                state.chat_unread_conversations = data.unread_conversations
            }
            if(data.hasOwnProperty('referral')) {
                state.chat_referral = data.referral
            }
            localStorage.setItem('chat_platform_id', +state.chat_platform_id)
            localStorage.setItem('chat_lang_id', +state.chat_lang_id)
            state.chat_type_id = data.type_id
            localStorage.setItem('chat_type_id', +state.chat_type_id)
            state.chat_admin_role_id = data.chat_admin_role_id || null
            localStorage.setItem('chat_admin_role_id', +state.chat_admin_role_id)
            state.chat_hide_deleted_subs = !!data.hide_deleted_subs
            localStorage.setItem('chat_hide_deleted_subs', state.chat_hide_deleted_subs ? 1 : '')
            state.chat_hide_blocked_subs = !!data.hide_blocked_subs
            localStorage.setItem('chat_hide_blocked_subs', state.chat_hide_blocked_subs ? 1 : '')
            if(data.hasOwnProperty('count_subs_total')) {
                state.chat_count_subs_total = data.count_subs_total
            }
            if(data.hasOwnProperty('status_id')) {
                state.chat_status_id = data.status_id
            }
            if(data.hasOwnProperty('template_has_update')) {
                state.chat_template_has_update = data.template_has_update
            }
        },
        setTemplate(state, data) {
            state.chat_template_token = data.template_token
            state.chat_template_parent_id = data.template_parent_id
            state.chat_with_template = state.chat_template_token || state.chat_template_parent_id
        },
        setSubDomain(state, val) {
            state.chat_sub_domain = val
        },
        setChatAdmin(state, val) {
            state.chat_admin = val
            localStorage.setItem(CHAT_ADMIN_KEY, val ? +val : '')
        },
        setHideDeletedSubs(state, val) {
            state.chat_hide_deleted_subs = !!val
            localStorage.setItem('chat_hide_deleted_subs', state.chat_hide_deleted_subs ? 1 : '')
        },
        setUnreadConversations(state, val) {
            this.chat_unread_conversations = !!val
        },
        setHideBlockedSubs(state, val) {
            state.chat_hide_blocked_subs = !!val
            localStorage.setItem('chat_hide_blocked_subs', state.chat_hide_blocked_subs ? 1 : '')
        },
        reset(state) {
            const init_state = initialState()
            for(let key in init_state) {
                state[key] = init_state[key]
            }
            localStorage.removeItem('chat_platform_id')
            localStorage.removeItem('chat_admin_role_id')
        }
    },
    actions: {
        setHideDeletedSubs({commit}, val) {
            commit('setHideDeletedSubs', val)
        },
        setUnreadConversations({commit}, val) {
            commit('setUnreadConversations', val)
        },
        setHideBlockedSubs({commit}, val) {
            commit('setHideBlockedSubs', val)
        },
        setChatAdmin({commit}, val) {
            commit('setChatAdmin', val)
            if(val) return
            this.dispatch('auth/resetChatAdmin')
        },
        setSubDomain({commit}, data) {
            commit('setSubDomain', data)
        },
        setCurrentChat({commit, dispatch}, data) {
            if(data && typeof data === 'object' && data.hasOwnProperty('chat_id')) {
                data.id = data.chat_id
                data.user_id = undefined
            }
            commit('setData', data)
            commit('setTemplate', data)
            dispatch('setUser', data)
        },
        setUser({state, commit, dispatch, rootState}, chat) {
            if(!state.chat_admin || (state.chat_id === chat.id && rootState.auth.loading) || (rootState.auth.subscription && rootState.auth.subscription.user_id === state.chat_user_id)) {
                return
            }
            dispatch('auth/setChatAdmin', chat.id, {
                root: true
            })
        },
        resetChat({commit, dispatch}) {
            commit('reset')
            dispatch('resetChatRelations')
            dispatch('auth/resetChatAdmin', {}, {
                root: true
            })
        },
        resetChatRelations({dispatch}) {
            dispatch('chatCourse/resetCourses', {}, {root: true})
            dispatch('chatGroup/resetGroups', {}, {root: true})
            dispatch('chatHomework/resetHomeworks', {}, {root: true})
            dispatch('chatLanding/resetLandings', {}, {root: true})
            dispatch('chatLandingDeepLink/resetLandingDeepLinks', {}, {root: true})
            dispatch('chatProduct/resetProducts', {}, {root: true})
            dispatch('chatProductPaymentSetting/resetPaymentSettings', {}, {root: true})
            dispatch('chatProposal/resetProposals', {}, {root: true})
            dispatch('chatQuestionary/resetQuestionaries', {}, {root: true})
            dispatch('chatVideo/resetVideos', {}, {root: true})
            dispatch('chatWebApp/resetWebApps', {}, {root: true})
            dispatch('chatWebinar/resetWebinars', {}, {root: true})
            dispatch('subscriberFilter/resetFilters', {}, {root: true})
            dispatch('subscriberForm/resetSubForm', {}, {root: true})
            dispatch('subscriberStage/resetStages', {}, {root: true})
            dispatch('subscriberTag/resetTags', {}, {root: true})
        }
    }
}
