import {
    i18n
} from '@/js/plugins/i18n'

import {
    Model
} from '@/js/models/Model'
import {
    MoneyReplenishmentCurrencyModel
} from '@/js/models/money/MoneyReplenishmentCurrencyModel'

export class MoneyReplenishmentAggregatorModel extends Model {
    static CLOUDPAYMENTS = 1
    static AUNITE = 2
    static PRODAMUS = 3
    static PLAY_MARKET = 4
    static TINKOFF = 5
    static ROBOKASSA = 6
    static YOOKASSA = 7
    static FONDY = 8
    static STRIPE = 9
    static YOOMONEY = 10
    static APP_SUMO = 11
    static MONETS_ME = 12
    static FANSPAY = 13
    static PRODAMUS_NATIVE = 14
    static LAVA = 15
    static BE_PAID = 16
    static CASH = 17
    static TELEGRAM_STARS = 18

    static ROBOKASSA_HASH_TYPES = [
        'MD5',
        'RIPEMD160',
        'SHA1',
        'SHA256',
        'SHA384',
        'SHA512'
    ]

    static get types() {
        return [
            {
                label: 'CloudPayments',
                value: this.CLOUDPAYMENTS
            },
            {
                label: i18n.t('models.money.replenishment.aggregator.prodamus-old'),
                value: this.PRODAMUS
            },
            {
                label: 'Prodamus',
                value: this.PRODAMUS_NATIVE
            },
            {
                label: 'Tinkoff',
                value: this.TINKOFF
            },
            {
                label: 'Robokassa',
                value: this.ROBOKASSA
            },
            {
                label: 'ЮKassa',
                value: this.YOOKASSA
            },
            {
                label: 'Fondy',
                value: this.FONDY
            },
            {
                label: 'YooMoney',
                value: this.YOOMONEY
            },
            {
                label: 'Stripe',
                value: this.STRIPE
            },
            {
                label: 'MonetsMe',
                value: this.MONETS_ME
            },
            {
                label: 'Fanspay',
                value: this.FANSPAY
            },
            {
                label: 'Lava',
                value: this.LAVA
            },
            {
                label: 'bePaid',
                value: this.BE_PAID
            },
            {
                label: 'Telegram Stars',
                value: this.TELEGRAM_STARS
            },
            {
                label: i18n.t('models.money.replenishment.aggregator.cash'),
                value: this.CASH
            }
        ]
    }

    static get typesRecurrent() {
        return [
            {
                label: 'CloudPayments',
                value: this.CLOUDPAYMENTS
            },
            {
                label: i18n.t(
                    'models.money.replenishment.aggregator.prodamus-old-recurrent'
                ),
                value: this.PRODAMUS
            },
            {
                label: 'Prodamus',
                value: this.PRODAMUS_NATIVE
            },
            {
                label: 'Tinkoff',
                value: this.TINKOFF
            },
            {
                label: 'Robokassa',
                value: this.ROBOKASSA
            },
            {
                label: 'ЮKassa',
                value: this.YOOKASSA
            },
            {
                label: 'Fondy',
                value: this.FONDY
            },
            {
                label: 'Stripe',
                value: this.STRIPE
            }
        ]
    }

    static hasPublicKey(id) {
        return [
            this.CLOUDPAYMENTS,
            this.AUNITE,
            this.PLAY_MARKET,
            this.TINKOFF,
            this.ROBOKASSA,
            this.YOOKASSA,
            this.FONDY,
            this.STRIPE,
            this.YOOMONEY,
            this.APP_SUMO,
            this.MONETS_ME,
            this.FANSPAY,
            this.LAVA,
            this.BE_PAID
        ].includes(+id)
    }

    static hasFirstAmount(id) {
        return [
            this.CLOUDPAYMENTS,
            this.PRODAMUS,
            this.PRODAMUS_NATIVE,
            this.TINKOFF,
            this.ROBOKASSA,
            this.YOOKASSA,
            this.FONDY,
            this.STRIPE
        ].includes(+id)
    }

    static hasCurrency(id) {
        return [
            this.CLOUDPAYMENTS,
            this.PRODAMUS,
            this.PRODAMUS_NATIVE,
            this.ROBOKASSA,
            this.FONDY,
            this.STRIPE,
            this.MONETS_ME,
            this.BE_PAID,
            this.CASH,
            this.TELEGRAM_STARS
        ].includes(+id)
    }

    static hasSecretKey(id) {
        return [
            this.CLOUDPAYMENTS,
            this.PRODAMUS,
            this.PRODAMUS_NATIVE,
            this.TINKOFF,
            this.ROBOKASSA,
            this.YOOKASSA,
            this.FONDY,
            this.YOOMONEY,
            this.STRIPE,
            this.FANSPAY,
            this.LAVA,
            this.BE_PAID
        ].includes(+id)
    }

    static hasTelOrEmail(id) {
        return [
            this.PRODAMUS,
            this.PRODAMUS_NATIVE,
            this.TINKOFF,
            this.ROBOKASSA,
            this.FONDY,
            this.MONETS_ME,
            this.BE_PAID
        ].includes(+id)
    }

    static isRequiredTelOrEmail(id) {
        return [this.TINKOFF].includes(+id)
    }

    static isRecurrentInternal(id) {
        return [
            this.TINKOFF,
            this.ROBOKASSA,
            this.YOOKASSA,
            this.FONDY,
            this.PRODAMUS_NATIVE
        ].includes(+id)
    }

    static getMinAmount(aggregatorId, currencyId) {
        switch (+aggregatorId) {
            case MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS:
                return 2
            case MoneyReplenishmentAggregatorModel.STRIPE:
                switch (currencyId) {
                    case MoneyReplenishmentCurrencyModel.AUD:
                    case MoneyReplenishmentCurrencyModel.BRL:
                    case MoneyReplenishmentCurrencyModel.CAD:
                    case MoneyReplenishmentCurrencyModel.CHF:
                    case MoneyReplenishmentCurrencyModel.EUR:
                    case MoneyReplenishmentCurrencyModel.INR:
                    case MoneyReplenishmentCurrencyModel.USD:
                    case MoneyReplenishmentCurrencyModel.XTR:
                        return 1
                    case MoneyReplenishmentCurrencyModel.AED:
                    case MoneyReplenishmentCurrencyModel.PLN:
                    case MoneyReplenishmentCurrencyModel.RON:
                        return 3
                    case MoneyReplenishmentCurrencyModel.CZK:
                        return 16
                    case MoneyReplenishmentCurrencyModel.HKD:
                        return 5
                    case MoneyReplenishmentCurrencyModel.SEK:
                        return 4
                }

                return 1
            default:
                return 1
        }
    }
}
