import { defineStore } from 'pinia';
import { useWebApp } from "@/js/store/pinia/webapp/WebApp.js";

export const useWebAppSubscriber = defineStore('webAppSubscriberStore', {
    state: () => ({
        id: null,
        chat_id: null,
        uuid: null,
        subscriber_id: null,
        first_name: null,
        last_name: null,
        username: null,
        language_code: null,
        avatar: null,
        ref_balance: null,
        email: null,
        tel: null,
        ym_id: null,
        isInit: false,
    }),
    persist: true,
    getters:
    {
        isAuth: (state) => {
            return state.isInit && state.uuid;
        }
    },
    actions:
    {
        async init(id = null)
        {
            let webApp = useWebApp();
            this.getState(webApp.chat.id);

            if(this.checkId(id))
            {
                const data = await webApp.fetch('subscriber', {
                    id: id,
                    chat_id: webApp.chat?.id
                });

                await this.update(data);
            }

            this.isInit = true;
        },

        async update(data)
        {
            Object.keys(data).forEach((key) => {
                if (key in this) {
                    this[key] = data[key];
                }
            });

            this.ym_id = this.ym_id ?? window.userYm?.clientID ?? null;
        },

        checkId(id)
        {
            const idStr = id?.toString() ?? '';
            if (idStr === '' || idStr === '1') return false;

            const candidateIds = [
                this.uuid?.toString(),
                this.subscriber_id?.toString()
            ];

            return !candidateIds.includes(idStr) || this.email === null || this.tel === null;
        },

        async clear()
        {
            this.id = null;
            this.chat_id = null;
            this.uuid = null;
            this.subscriber_id = null;
            this.first_name = null;
            this.last_name = null;
            this.username = null;
            this.language_code = null;
            this.avatar = null;
            this.ref_balance = null;
            this.email = null;
            this.tel = null;
            this.ym_id = null;
        },

        getState(chat_id)
        {
            const persistKey = `webAppSubscriberStore_${chat_id}`;
            const savedState = localStorage.getItem(persistKey);

            savedState
                ? this.$patch(JSON.parse(savedState))
                : this.clear();

            this.$subscribe((mutation, state) => {
                if(state.id) localStorage.setItem(persistKey, JSON.stringify(state));
            });
        }
    },
});
