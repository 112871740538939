export const chatSubscriberBaseFilterRoutes = [
  {
    path: 'chat/:chat_id/subscriber-base/filter/store',
    component: () => import('@/js/components/chat/subscriberBase/filter/StoreChatSubscriberFilter.vue'),
    name: 'StoreChatSubscriberFilter',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/subscriber-base/filter/:item_id/update',
    component: () => import('@/js/components/chat/subscriberBase/filter/UpdateChatSubscriberFilter.vue'),
    name: 'UpdateChatSubscriberFilter',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
