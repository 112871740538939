import {ChatModel} from '@/js/models/chat/ChatModel'

export class ChatGroupModel {
    constructor() {
        this.form = {
            id: null,
            type_id: null,
            is_remove_deny_access: false,
            name: '',
            avatar: '',
            chat_id: null,
            stages: [],
            actions: {
                subscribe: [],
                unsubscribe: []
            }
        }
    }

    static url(chat_id)
    {
        return `${ChatModel.URL}/${chat_id}/groups`
    }
}
