export const chatSubscriberBaseTasksRoutes = [
  {
    path: 'chat/:chat_id/subscriber-base/tasks/:item_id/update',
    component: () => import('@/js/components/chat/subscriberBase/task/UpdateChatSubscriberTask.vue'),
    name: 'UpdateChatSubscriberTask',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
