import { ChatGroupModel } from '@/js/models/chat/group/ChatGroupModel'

const initialState = () => {
  return {
    chat_groups: [],
    chat_groups_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_groups_loaded = isLoaded
    },

    setGroups(state, groups) {
      state.chat_groups = []

      groups.forEach(({ id, name, type_id }) =>
        state.chat_groups.push({ id, name, type_id })
      )
    },

    deleteGroup(state, id) {
      let index = state.chat_groups.findIndex((item) => item.id === id)

      if (index === -1) return

      state.chat_groups.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchGroups({ commit, dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatGroupModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setGroups', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetGroups')

            return reject(error)
          })
      })
    },

    setGroups({ commit }, groups) {
      commit('setGroups', groups)

      commit('setLoaded', true)
    },

    deleteGroup({ commit }, id) {
      commit('deleteGroup', id)
    },

    resetGroups({ commit }) {
      commit('reset')
    }
  }
}
