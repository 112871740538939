import { ChatModel } from '@/js/models/chat/ChatModel'

export class ChatCourseModel {
  static url(chatId) {
    return `${ChatModel.URL}/${chatId}/courses`
  }

  static urlPublic(chatUuid) {
    return `${ChatModel.URL_PUB}/${chatUuid}/courses`
  }

  static urlForWebApp() {
      return '/webapp/course';
  }

  static UUID_PREFIX = '/course/'

  static FILLABLE = [
    'uuid',
    'name',
    'allow_edit',
    'folder_id',
    'filter_id',
    'redirect_landing_id',
    'is_inside',
    'is_open_default_sections',
    'is_dark_theme',
    'color'
  ]

  constructor() {
    this.searchForm = {
      folder_id: null
    }

    this.form = {
      id: null,
      folder_id: null,
      filter_id: null,
      redirect_landing_id: null,
      name: null,
      description: null,
      image: null,
      allow_edit: 0,
      is_inside: 0,
      is_open_default_sections: 0,
      is_dark_theme: 0,
      color: null,
      lessons: []
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }

  static getPublicUrl(course, chatDomain = null) {
    let host = window.location.host

    if (course.uuid_domain) {
      host = course.uuid_domain
    } else if (chatDomain) {
      host = chatDomain.url
    }

    return `https://${host}${this.UUID_PREFIX}${course.uuid}`
  }
}
