import { ChatModel } from '@/js/models/chat/ChatModel'

export class ChatSubscriberStageModel {
  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/stages`
  }

  constructor() {
    this.form = {
      name: null,
      limit_duration: false,
      duration: null,
      default: false,
      order: null,
      allow_edit: false
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }
}
