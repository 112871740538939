import VueI18n from 'vue-i18n'
import Vue from 'vue'
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@/js/constants/trans'
import { Trans } from '@/js/plugins/translation';

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: {},
  pluralizationRules: {
    ru(choice, choicesLength) {
      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20

      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2
      }

      if (!teen && endsWithOne) {
        return 1
      }

      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return choicesLength < 4 ? 2 : 3
    }
  }
})

Trans.loadLanguageFile(DEFAULT_LANGUAGE).then((msgs) => {
    i18n.setLocaleMessage(DEFAULT_LANGUAGE, msgs.default || msgs);
});
