import { i18n } from '@/js/plugins/i18n'
import { Model } from '@/js/models/Model'

export class LandingTypeModel extends Model {
  static DEFAULT = 1

  static PRIVATE = 2

  static get types() {
    return [
      {
        label: i18n.t('models.landing.type.default'),
        value: this.DEFAULT
      },
      {
        label: i18n.t('models.landing.type.private'),
        value: this.PRIVATE
      }
    ]
  }
}
