import Vue from 'vue'
import Vuex from 'vuex'

import Auth from '@/js/store/modules/Auth.js'
import Alert from '@/js/store/modules/Alert.js'
import Chat from '@/js/store/modules/Chat.js'
import ChatQuestionary from '@/js/store/modules/ChatQuestionary'
import ChatGpt from '@/js/store/modules/ChatGpt'
import ChatCourse from '@/js/store/modules/ChatCourse'
import ChatWebApp from '@/js/store/modules/ChatWebApp'
import ChatHomework from '@/js/store/modules/ChatHomework'
import ChatVideo from '@/js/store/modules/ChatVideo'
import ChatWebinar from '@/js/store/modules/ChatWebinar'
import ChatTournament from '@/js/store/modules/ChatTournament'
import ChatProposal from '@/js/store/modules/ChatProposal'
import ChatProduct from '@/js/store/modules/ChatProduct'
import ChatProductPaymentSetting from '@/js/store/modules/ChatProductPaymentSetting'
import ChatLanding from '@/js/store/modules/ChatLanding'
import ChatGroup from '@/js/store/modules/ChatGroup'
import ChatSubscriber from '@/js/store/modules/ChatSubscriber.js'
import ChatSubscriberForm from '@/js/store/modules/ChatSubscriberForm'
import ChatSubscriberTag from '@/js/store/modules/ChatSubscriberTag'
import ChatSubscriberStage from '@/js/store/modules/ChatSubscriberStage'
import ChatSubscriberFilter from '@/js/store/modules/ChatSubscriberFilter'
import ChatConversation from '@/js/store/modules/ChatConversation'
import ChatMessage from '@/js/store/modules/ChatMessage'
import ChatLandingDeepLink from '@/js/store/modules/ChatLandingDeepLink'
import Pwa from '@/js/store/modules/Pwa'
import Conference from '@/js/store/modules/Conference'

import {SubDomainModel} from '@/js/models/SubDomainModel'

Vue.use(Vuex)

const LAPTOP_LARGE_SIZE = 1920
const LAPTOP_SIZE = 1280
const MOBILE_SIZE = 1024
const MOBILE_SMALL_SIZE = 768
const MOBILE_VERY_SMALL_SIZE = 500
const MOBILE_EXTRA_SMALL_SIZE = 400

function initialState() {
    return {
        windowWidth: window.innerWidth,
        laptopLargeSize: window.innerWidth >= LAPTOP_LARGE_SIZE,
        laptopSize: window.innerWidth >= LAPTOP_SIZE,
        laptopMobileSize: window.innerWidth >= MOBILE_SIZE,
        mobileSize: window.innerWidth < MOBILE_SIZE,
        mobileSmallSize: window.innerWidth < MOBILE_SMALL_SIZE,
        mobileVerySmallSize: window.innerWidth < MOBILE_VERY_SMALL_SIZE,
        mobileExtraSmallSize: window.innerWidth < MOBILE_EXTRA_SMALL_SIZE,
        hasOpacity: true,
        hasConsultant: false,
        viewId: 0,
        isSidebarOpen: JSON.parse(localStorage.getItem('is_sidebar_open') || 'true'),
        submenuName: '',
        submenuTitle: '',
        submenuProps: null,

        subDomain: () => {
            let host = window.location.host

            if (host === 'localhost' || host.includes('127.0.0.1:') || host.includes('localhost:')) {
                return SubDomainModel.WINWINBOT
            }

            switch (host) {
                case SubDomainModel.WINWINBOT_URL:
                case SubDomainModel.WINWINBOT_DEV_URL:
                case SubDomainModel.WINWINBOT_MOBILE_URL:
                case SubDomainModel.WINWINBOT_VARGA_DA_URL:
                case SubDomainModel.WINWINBOT_HUPOVKA_DA_URL:
                    return SubDomainModel.WINWINBOT
                case SubDomainModel.AUNITE_URL:
                    return SubDomainModel.AUNITE
                case SubDomainModel.LEARME_URL:
                    return SubDomainModel.LEARME
                case SubDomainModel.GETRESPONSE_URL:
                    return SubDomainModel.GETRESPONSE
                case SubDomainModel.GETRESPONSE_BR_URL:
                    return SubDomainModel.GETRESPONSE_BR
                case SubDomainModel.YOLKER_URL:
                    return SubDomainModel.YOLKER
                case SubDomainModel.CONSCIOUS_URL:
                    return SubDomainModel.CONSCIOUS
                case SubDomainModel.CALERIE_HEALTH_URL:
                    return SubDomainModel.CALERIE_HEALTH
                case SubDomainModel.TBOT_IMBOT_GURU_URL:
                    return SubDomainModel.TBOT_IMBOT_GURU
                case SubDomainModel.UNATIENDA_APP_URL:
                    return SubDomainModel.UNATIENDA_APP
                default:
                    return -1
            }
        },
        subDomainName: () => {
            let host = window.location.host

            switch (host) {
                case SubDomainModel.AUNITE_URL:
                    return 'AuniteBot'
                case SubDomainModel.LEARME_URL:
                    return 'Learme'
                case SubDomainModel.GETRESPONSE_URL:
                    return 'GetResponseBot'
                case SubDomainModel.GETRESPONSE_BR_URL:
                    return 'GetResponseBrBot'
                case SubDomainModel.YOLKER_URL:
                    return 'Yolker'
                case SubDomainModel.CONSCIOUS_URL:
                    return 'Conscious'
                case SubDomainModel.CALERIE_HEALTH_URL:
                    return 'CalerieHealth'
                case SubDomainModel.TBOT_IMBOT_GURU_URL:
                    return 'IMBotGuru'
                case SubDomainModel.UNATIENDA_APP_URL:
                    return 'UnatiendaApp'
                default:
                    return 'WinWinBot'
            }
        },

        aunite: window.location.host === SubDomainModel.AUNITE_URL,
        learme: window.location.host === SubDomainModel.LEARME_URL,
        winwinbot: [
            SubDomainModel.WINWINBOT_URL,
            SubDomainModel.WINWINBOT_DEV_URL,
            SubDomainModel.WINWINBOT_MOBILE_URL,
            SubDomainModel.WINWINBOT_VARGA_DA_URL,
            SubDomainModel.WINWINBOT_HUPOVKA_DA_URL,
            'localhost'
        ].includes(window.location.host) || window.location.host.includes('127.0.0.1:') || window.location.host.includes('localhost:'),
        winwinbotMobile: window.location.host === SubDomainModel.WINWINBOT_MOBILE_URL
    }
}

export default new Vuex.Store({
    modules: {
        auth: Auth,
        alert: Alert,
        chat: Chat,
        chatQuestionary: ChatQuestionary,
        chatGpt: ChatGpt,
        chatCourse: ChatCourse,
        chatWebApp: ChatWebApp,
        chatHomework: ChatHomework,
        chatLandingDeepLink: ChatLandingDeepLink,
        chatVideo: ChatVideo,
        chatWebinar: ChatWebinar,
        chatTournament: ChatTournament,
        chatProposal: ChatProposal,
        chatProduct: ChatProduct,
        chatProductPaymentSetting: ChatProductPaymentSetting,
        chatLanding: ChatLanding,
        chatGroup: ChatGroup,
        subscriber: ChatSubscriber,
        subscriberForm: ChatSubscriberForm,
        subscriberTag: ChatSubscriberTag,
        subscriberStage: ChatSubscriberStage,
        subscriberFilter: ChatSubscriberFilter,
        message: ChatMessage,
        conversation: ChatConversation,
        pwa: Pwa,
        conference: Conference
    }, state: initialState(), mutations: {
        changeWidthSize(state, data) {
            state.windowWidth = data

            state.laptopLargeSize = data >= LAPTOP_LARGE_SIZE

            state.laptopSize = data >= LAPTOP_SIZE

            state.mobileSize = data < MOBILE_SIZE

            state.mobileSmallSize = data < MOBILE_SMALL_SIZE

            state.mobileVerySmallSize = data < MOBILE_VERY_SMALL_SIZE

            state.mobileExtraSmallSize = data < MOBILE_EXTRA_SMALL_SIZE
        },

        incrementViewId(state) {
            state.viewId += 1
        },

        setHasOpacity(state, value) {
            state.hasOpacity = value
        },

        setHasConsultant(state, value) {
            state.hasConsultant = value
        },

        setIsSidebarOpen(state, value) {
            state.isSidebarOpen = value
        }, setIsSubmenuOpen(state, {name, title, props}) {
            state.submenuName = name
            state.submenuTitle = title
            state.submenuProps = props
            if (name && state.mobileSize) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'inherit'
            }
        }
    }, actions: {
        reset({dispatch}) {
            dispatch('auth/reset', {}, {root: true})
            dispatch('chat/resetChat', {}, {root: true})
            dispatch('subscriber/reset', {}, {root: true})
            dispatch('message/reset', {}, {root: true})
            dispatch('conversation/reset', {}, {root: true})
            dispatch('chatGpt/resetGpts', {}, {root: true})
            dispatch('pwa/reset', {}, {root: true})

            router.push({name: 'Login'})
        },

        changeWidthSize({commit}, data) {
            commit('changeWidthSize', data)
        },

        setHasOpacity({commit}, data) {
            commit('setHasOpacity', data)
        },

        setHasConsultant({commit}, data) {
            commit('setHasConsultant', data)
        },

        incrementViewId({commit}) {
            commit('incrementViewId')
        },

        setIsSidebarOpen({commit}, data) {
            localStorage.setItem('is_sidebar_open', JSON.stringify(data))
            commit('setIsSidebarOpen', data)
        }, setIsSubmenuOpen({commit}, data) {
            commit('setIsSubmenuOpen', data)
        }
    }
})
