import {
    mapActions,
    mapState
} from 'vuex'

export const pwaInstallMixin = {
    methods: {
        ...mapActions('pwa', ['setSuccess', 'setFail']),

        installPwa()
        {
            if(this.pwa === undefined || this.pwa === null) return;
            this.pwa.prompt()
            this.pwa.userChoice.then((res) => {
                if (res.outcome === 'accepted') {
                    this.setSuccess()
                }
            })
        }
    },
    computed: {
        ...mapState('pwa', ['pwa'])
    }
}

export const isMacSafariMixin = {
    computed: {
        isMacSafari() {
            return (
                /Safari/.test(navigator.userAgent) &&
                /Apple Computer/.test(navigator.vendor)
            )
        }
    }
}

export const iosMixin = {
    mixins: [isMacSafariMixin],
    computed: {
        isIos() {
            let agent = navigator.userAgent

            return (
                (!!agent.match(/iPad/i) || !!agent.match(/iPhone/i)) &&
                !!agent.match(/WebKit/i) &&
                !agent.match(/CriOS/i)
            )
        }
    }
}
