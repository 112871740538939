import { ChatProductModel } from '@/js/models/chat/product/ChatProductModel'

const initialState = () => {
  return {
    chat_products: [],
    chat_products_loaded: false,
    chat_recurrent_products: [],
    chat_recurrent_products_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, { isLoaded, is_recurrent }) {
      let key = is_recurrent
        ? 'chat_recurrent_products_loaded'
        : 'chat_products_loaded'

      state[key] = isLoaded
    },

    setProducts(state, { products, is_recurrent }) {
      let key = is_recurrent ? 'chat_recurrent_products' : 'chat_products'

      state[key] = []

      products.forEach(({ id, name, image }) =>
        state[key].push({ id: id, name: name, image: image })
      )
    },

    deleteProduct(state, id) {
      let keys = ['chat_products', 'chat_recurrent_products']

      keys.forEach((key) => {
        let index = state[key].findIndex((item) => item.id === id)

        if (index === -1) return

        state[key].splice(index, 1)
      })
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchProducts({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatProductModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setProducts', {
              products: data.items,
              is_recurrent: params.is_recurrent
            })

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetProducts')

            return reject(error)
          })
      })
    },

    setProducts({ commit }, { products, is_recurrent }) {
      commit('setProducts', { products: products, is_recurrent: is_recurrent })

      commit('setLoaded', { isLoaded: true, is_recurrent: is_recurrent })
    },

    deleteProduct({ commit }, id) {
      commit('deleteProduct', id)
    },

    resetProducts({ commit }) {
      commit('reset')
    }
  }
}
