<template>
    <div
        class="w-full absolute top-0 bg-gray-100 z-50 transition-transform duration-150 ease-out transform dark:bg-gray-950 dark:text-gray-300"
        :class="{
                'translate-y-0 shadow': isShow,
                '-translate-y-full': !isShow
              }"
    >
        <div class="container max-w-[600px] relative px-[20px] py-[10px]">
            <div class="flex items-center gap-8">
                <div>
                    {{ $t('main-words.install-webapp-description') }}:
                    <button @click="install" class="underline text-sky-500 font-semibold">{{$t('main-words.install').toLowerCase()}}</button>
                </div>
                <div class="">
                    <i class="icon el-icon-close" @click="isShow = false"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WebAppInstallRequest",
    data: () => ({
        isShow: false,
        prompt: null
    }),
    mounted()
    {
        this.init();
    },
    methods:
    {
        install()
        {
            if (this.prompt)
            {
                this.prompt.prompt();
                this.prompt.userChoice.then(() => {
                    this.prompt = null;
                    this.isShow = false;
                });
            }
        },
        init()
        {
            if(window.location.href.includes('/webapp'))
            {
                window.addEventListener('beforeinstallprompt', (e) => {
                    this.prompt = e;
                    e.preventDefault();
                    setTimeout(() => {
                        this.isShow = true;
                    }, 3000);
                    setTimeout(() => {
                        this.isShow = false;
                    }, 30000);
                });
            }
        },
    }
}
</script>

<style scoped>

</style>
