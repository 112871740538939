export class SubDomainModel {
    static WINWINBOT = null
    static WINWINBOT_URL = 'my.winwinbot.com'
    static WINWINBOT_DEV_URL = 'my.chatbot.ru'
    static WINWINBOT_VARGA_DA_URL = 'home.varga-da.com'
    static WINWINBOT_HUPOVKA_DA_URL = 'www.testovoe-zadanie.ru'
    static WINWINBOT_MOBILE_URL = 'com.winwinbot.app'

    static AUNITE = 1
    static AUNITE_URL = 'bot.aunite.com'

    static LEARME = 2
    static LEARME_URL = 'bot.learme.ru'

    static GETRESPONSE = 3
    static GETRESPONSE_URL = 'bot.getresponseservices.com'

    static GETRESPONSE_BR = 4
    static GETRESPONSE_BR_URL = 'winwinbot.smtmarketing.com.br'

    static YOLKER = 75
    static YOLKER_URL = 'tg.yolker.ru'

    static CONSCIOUS = 99
    static CONSCIOUS_URL = 'club.consciouselite.com'

    static CALERIE_HEALTH = 144
    static CALERIE_HEALTH_URL = 'caleriehealthglobal.com'

    static TBOT_IMBOT_GURU = 159
    static TBOT_IMBOT_GURU_URL = 'tbot.imbotguru.com'

    static UNATIENDA_APP = 156
    static UNATIENDA_APP_URL = 'bot.unatienda.app'

    constructor(props) {
        this.form = {
            id: null,
            name: '',
            url: null,
            redirect_url: null,
            user_id: null
        }
    }

    static get types() {
        return [
            {
                value: this.WINWINBOT,
                label: 'WinWinBot',
                logo: '/logo/winwinbot/favicon/150.png'
            },
            {
                value: this.AUNITE,
                label: 'Aunite',
                logo: '/logo/aunitebot/favicon/150.png'
            },
            {
                value: this.LEARME,
                label: 'Learme',
                logo: '/logo/learme/favicon/150.png'
            },
            {
                value: this.GETRESPONSE,
                label: 'GetResponse',
                logo: '/logo/getresponsebot/favicon/150.png'
            },
            {
                value: this.GETRESPONSE_BR,
                label: 'GetResponseBr',
                logo: '/logo/getresponsebrbot/favicon/150.png'
            },
            {
                value: this.YOLKER,
                label: 'Yolker',
                logo: '/logo/yolker/favicon/150.png'
            },
            {
                value: this.CONSCIOUS,
                label: 'Conscious',
                logo: '/logo/conscious/favicon/150.png'
            },
            {
                value: this.CALERIE_HEALTH,
                label: 'CalerieHealth',
                logo: '/logo/caleriehealth/favicon/150.png'
            },
            {
                value: this.TBOT_IMBOT_GURU,
                label: 'IMBotGuru',
                logo: '/logo/imbotguru/favicon/150.png'
            },
            {
                value: this.UNATIENDA_APP,
                label: 'UnatiendaApp',
                logo: '/logo/unatiendaapp/favicon/150.png'
            }
        ]
    }

    static getNameById(id) {
        return this.types.find((x) => x.value === id).label
    }

    static getLogoById(id) {
        let type = this.types.find((x) => x.value === id)

        return type ? type.logo : ''
    }

    static canUse(id) {
        return [
            this.WINWINBOT,
            this.AUNITE,
            this.GETRESPONSE,
            this.GETRESPONSE_BR,
            this.YOLKER,
            this.CONSCIOUS,
            this.CALERIE_HEALTH,
            this.TBOT_IMBOT_GURU,
            this.UNATIENDA_APP
        ].includes(id)
    }

    static canRegister(id) {
        return [
            this.WINWINBOT,
            this.AUNITE,
            this.WINWINBOT_MOBILE_URL
        ].includes(id)
    }

    static canSocialAuth(id) {
        return [
            this.WINWINBOT
        ].includes(id)
    }
}
