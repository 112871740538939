import { mapState } from 'vuex'

import { ChatAdminRoleModel } from '@/js/models/chat/admin/ChatAdminRoleModel'

export const chatAllowEditMixin = {
  methods: {
    chatAllowEdit(item) {
      return !item.template_parent_id || item.allow_edit
    }
  }
}

export const chatImageMixin = {
  methods: {
    toggleImage(imageKey, { src }) {
      this.model.form[imageKey] = src

      if (!src) {
        this.$refs[imageKey].fileList = []
      }
    },

    prepareImageForSending(imageKey, form) {
      if (this.$refs[imageKey]) {
        let fileList = this.$refs[imageKey].$refs['upload'].fileList

        if (fileList.length && fileList[0].hasOwnProperty('raw')) {
          return form.append(imageKey, fileList[0].raw)
        }
      }

      if (this.model.form[imageKey]) {
        form.append(imageKey, this.model.form[imageKey])
      }
    }
  }
}

export const chatAllowSubExportMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id']),

    chatAllowSubExport() {
      return (
        !this.chat_admin_role_id ||
        this.chat_admin_role_id === ChatAdminRoleModel.FULL_ACCESS
      )
    }
  }
}

export const chatAllowAdminMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id']),

    chatAllowAdmin() {
      return (
        !this.chat_admin_role_id ||
        this.chat_admin_role_id === ChatAdminRoleModel.FULL_ACCESS
      )
    }
  }
}

export const chatAllowTokenMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id']),

    chatAllowToken() {
      return (
        !this.chat_admin_role_id ||
        this.chat_admin_role_id === ChatAdminRoleModel.FULL_ACCESS
      )
    }
  }
}

export const chatAllowTemplateMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id']),

    chatAllowTemplate() {
      return (
        !this.chat_admin_role_id ||
        this.chat_admin_role_id === ChatAdminRoleModel.FULL_ACCESS
      )
    }
  }
}

export const chatAllowQuestionaryMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowQuestionary(roleId = null) {
      roleId = roleId || this.chat_admin_role_id

      return (
        !roleId ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR
        ].includes(roleId)
      )
    }
  }
}

export const chatAllowLongreadMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowLongread(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR
        ].includes(role_id)
      )
    }
  }
}

export const chatAllowWebinarMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowWebinar(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR
        ].includes(role_id)
      )
    }
  }
}

export const chatAllowHomeworkMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowHomework(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR
        ].includes(role_id)
      )
    }
  }
}

export const chatAllowHomeworkResultMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowHomeworkResult(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR,
          ChatAdminRoleModel.CHAT_OPERATOR
        ].includes(role_id)
      )
    }
  }
}

export const chatAllowVideoMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowVideo(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR
        ].includes(role_id)
      )
    }
  }
}

export const chatAllowProductMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowProduct(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR
        ].includes(role_id)
      )
    }
  }
}

export const chatAllowSubMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowSub(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.CHAT_OPERATOR
        ].includes(role_id)
      )
    }
  }
}

export const chatAllowConversationMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowConversation(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return !role_id || ChatAdminRoleModel.idsAsOperator.includes(role_id)
    }
  }
}

export const chatAllowMessageMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowMessage(roleId = null, isReadingQuickReply = false) {
      roleId = roleId || this.chat_admin_role_id

      return (
        !roleId ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR
        ].includes(roleId) ||
        (isReadingQuickReply && +roleId === ChatAdminRoleModel.CHAT_OPERATOR)
      )
    }
  }
}

export const chatAllowSettingsMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowSettings(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [ChatAdminRoleModel.FULL_ACCESS, ChatAdminRoleModel.USER].includes(
          role_id
        )
      )
    }
  }
}

export const chatAllowBotLandingMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowBotLanding(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [ChatAdminRoleModel.FULL_ACCESS, ChatAdminRoleModel.USER].includes(
          role_id
        )
      )
    }
  }
}

export const chatAllowWidgetMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowWidget(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [ChatAdminRoleModel.FULL_ACCESS, ChatAdminRoleModel.USER].includes(
          role_id
        )
      )
    }
  }
}

export const chatAllowSubReferralMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowSubReferral(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [ChatAdminRoleModel.FULL_ACCESS, ChatAdminRoleModel.USER].includes(
          role_id
        )
      )
    }
  }
}

export const chatAllowContentMixin = {
  mixins: [
    chatAllowLongreadMixin,
    chatAllowHomeworkMixin,
    chatAllowHomeworkResultMixin,
    chatAllowVideoMixin
  ],
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowContent(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR,
          ChatAdminRoleModel.CHAT_OPERATOR
        ].includes(role_id)
      )
    }
  }
}

export const chatAllowPromotionMixin = {
  mixins: [
    chatAllowWebinarMixin,
    chatAllowBotLandingMixin,
    chatAllowWidgetMixin,
    chatAllowSubReferralMixin
  ],
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowPromotion(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR
        ].includes(role_id)
      )
    }
  }
}

export const chatAllowProposalMixin = {
  computed: {
    ...mapState('chat', ['chat_admin_role_id'])
  },
  methods: {
    chatAllowProposal(role_id = null) {
      role_id = role_id || this.chat_admin_role_id

      return (
        !role_id ||
        [
          ChatAdminRoleModel.FULL_ACCESS,
          ChatAdminRoleModel.USER,
          ChatAdminRoleModel.EDITOR,
          ChatAdminRoleModel.CHAT_OPERATOR
        ].includes(role_id)
      )
    }
  }
}
