import { i18n } from '@/js/plugins/i18n'

export class ChatGptConditionModel {
  static TYPE_TEXT = 1
  static TYPE_ANSWER_COUNT = 2

  static get types() {
    return [
      {
        value: this.TYPE_TEXT,
        label: i18n.t('models.chat.gpt.condition.type.text')
      },
      {
        value: this.TYPE_ANSWER_COUNT,
        label: i18n.t('models.chat.gpt.condition.type.answer-count')
      }
    ]
  }

  constructor(typeId = null) {
    this.form = {
      type_id: typeId || ChatGptConditionModel.TYPE_TEXT,
      name: '',
      description: ''
    }
  }
}
