export const secondBgColor = (color) => {
  return color ? color.slice(0, color.lastIndexOf(',')) + '0.7)' : ''
}

export const bgGradient = (color) => {
  return color ? `linear-gradient(${color},${secondBgColor(color)})` : ''
}

export const bgColorMixin = {
  methods: {
    bgGradient(color) {
      return bgGradient(color)
    }
  }
}
