export const chatQuestionaryRoutes = [
  {
    path: 'chat/:chat_id/questionaries/store',
    component: () => import('@/js/components/chat/content/questionary/StoreChatQuestionary.vue'),
    name: 'StoreChatQuestionary',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/questionaries',
    component: () => import('@/js/components/chat/message/IndexChatMessage.vue'),
    name: 'IndexChatQuestionary',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/questionaries/:questionary_id/:page',
    component: () => import('@/js/components/chat/content/questionary/IndexChatQuestionaryTab.vue'),
    name: 'IndexChatQuestionaryTab',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
