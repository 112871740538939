import { adminWhiteLabelSubscriptionPreviewRoutes } from '@/js/routes/admin/whitelabel/subscription/preview/routes'
import { adminWhiteLabelSubscriptionTypeRoutes } from '@/js/routes/admin/whitelabel/subscription/type/routes'

export const adminWhiteLabelRoutes = [
  ...adminWhiteLabelSubscriptionPreviewRoutes,
  ...adminWhiteLabelSubscriptionTypeRoutes,
  ...[
    {
      path: 'admin-wl/users',
      component: () => import('@/js/components/admin/whitelabel/user/IndexAdminWhiteLabelUser.vue'),
      name: 'IndexAdminWhiteLabelUser',
      meta: {
        requireAdminWhiteLabel: true,
        requireAuth: true
      },
      props: true
    },
    {
      path: 'admin-wl/users/store',
      component: () => import('@/js/components/admin/whitelabel/user/StoreAdminWhiteLabelUser.vue'),
      name: 'StoreAdminWhiteLabelUser',
      meta: {
        requireAdminWhiteLabel: true,
        requireAuth: true
      },
      props: true
    },
    {
      path: 'admin-wl/users/:item_id/update',
      component: () => import('@/js/components/admin/whitelabel/user/UpdateAdminWhiteLabelUser.vue'),
      name: 'UpdateAdminWhiteLabelUser',
      meta: {
        requireAdminWhiteLabel: true,
        requireAuth: true
      },
      props: true
    }
  ]
]
