import {requiredRule} from '@/js/services/ValidationRuleService'

import {i18n} from '@/js/plugins/i18n'

import {ChatLangModel} from '@/js/models/chat/ChatLangModel'
import {ChatReferralModel} from '@/js/models/chat/referral/ChatReferralModel'
import {ChatWidgetModel} from '@/js/models/chat/widget/ChatWidgetModel'
import {ChatCreateTypeModel} from '@/js/models/chat/ChatCreateTypeModel'

export class ChatModel {

    static urlForWebapp()
    {
        return '/webapp/chat'
    }

    static URL = 'chats'

    static URL_PUB = 'pub/chats'

    static URL_TEMPLATE = 'chat-template'

    static IFRAME = 'iframe'

    static NOT_FILLABLE = ['vk', 'facebook', 'instagram', 'admin_code', 'image', 'template_parent_id', 'sub_domain']

    static FILLABLE = ['lang_id', 'name', 'short_description', 'token', 'template_token', 'parent_template_token', 'has_private_operators', 'has_random_operators', 'operator_id']

    static WARNING_DAYS = 7

    constructor() {
        this.form = {
            create_type_id: ChatCreateTypeModel.INSTRUCTOR,

            name: '',
            image: '',
            short_description: '',
            token: '',
            parent_template_token: null,
            template_parent_id: null,
            lang_id: ChatLangModel.getIdByName(i18n.locale),
            platform_id: null,
            admin_code: {
                code: null
            },
            vk: {
                group_token: null, group_id: null
            },
            facebook: {
                group_token: null, group_id: null
            },
            instagram: {
                group_token: null, group_id: null, instagram_id: null
            },
            sub_domain: {
                url: null
            },
            referral: new ChatReferralModel().form,
            widget: new ChatWidgetModel().form,

            has_random_operators: false,
            has_private_operators: false,
            has_passwords: false,
            operator_id: null
        }
    }

    get rules() {
        return {
            name: [requiredRule()],
            lang_id: [requiredRule()],
            platform_id: [requiredRule()],
            short_description: [requiredRule(), {max: 4000}],
            token: [requiredRule()]
        }
    }

    static getPublicUrl(chat) {
        let base = ''

        if (!chat) {
            return base
        }

        base = chat.sub_domain ? `https://${chat.sub_domain.url}` : window.baseOrigin()

        return `${base}/bot/${chat.platform_id}/${chat.username}`
    }

    static getActivationUrl(id) {
        return `${window.baseOrigin()}/api/botman/${id}`
    }
}
