import { Model } from '@/js/models/Model'
import { i18n } from '@/js/plugins/i18n'

export class ChatAdminRoleModel extends Model {
  static FULL_ACCESS = 1

  static USER = 2

  static EDITOR = 3

  static CHAT_OPERATOR = 4

  static get types() {
    return [
      {
        value: this.FULL_ACCESS,
        label: i18n.t('models.chat.admin.role.full-access'),
        faq: i18n.t('services.faq.bot.admin.role.full-access')
      },
      {
        value: this.USER,
        label: i18n.t('models.chat.admin.role.user'),
        faq: i18n.t('services.faq.bot.admin.role.user')
      },
      {
        value: this.EDITOR,
        label: i18n.t('models.chat.admin.role.editor'),
        faq: i18n.t('services.faq.bot.admin.role.editor')
      },
      {
        value: this.CHAT_OPERATOR,
        label: i18n.t('models.chat.admin.role.chat-operator'),
        faq: i18n.t('services.faq.bot.admin.role.chat-operator')
      }
    ]
  }

  static get idsAsOperator() {
    return [this.FULL_ACCESS, this.USER, this.CHAT_OPERATOR]
  }
}
