import { i18n } from '@/js/plugins/i18n'
import { Model } from '@/js/models/Model'

export class QuestionTypeModel extends Model {
  static SINGLE = 1

  static MULTIPLE = 2

  static FINAL = 3

  static QUIZ = 4

  static REQUEST_PHONE = 5

  static REQUEST_EMAIL = 6

  static MESSAGE_LANDING = 7

  static MESSAGE_LINK = 8

  static MESSAGE = 9

  static TIMEOUT = 10

  static REQUEST_UPLOAD = 11

  static MESSAGE_RANDOM = 12

  static GPT = 13

  static get types() {
    return [
      {
        label: i18n.t('models.question.type.single'),
        value: this.SINGLE,
        faq: i18n.t('services.faq.bot.message.question.type.single'),
        iconPath: new URL("@/assets/icons/chat/questionary/answer-random.svg", import.meta.url).href
      },
      {
        label: i18n.t('models.question.type.multiple'),
        value: this.MULTIPLE,
        faq: i18n.t('services.faq.bot.message.question.type.multiple'),
        iconPath: new URL("@/assets/icons/chat/questionary/answer-check.svg", import.meta.url).href
      },
      {
        label: i18n.t('models.question.type.quiz'),
        value: this.QUIZ,
        faq: i18n.t('services.faq.bot.message.question.type.quiz'),
        iconPath: new URL("@/assets/icons/chat/questionary/answer-quiz.svg", import.meta.url).href
      },
      {
        label: i18n.t('models.question.type.message-landing'),
        value: this.MESSAGE_LANDING,
        faq: i18n.t('services.faq.bot.message.question.type.message-landing'),
        icon: 'el-icon-connection'
      },
      {
        label: i18n.t('models.question.type.message-link'),
        value: this.MESSAGE_LINK,
        faq: i18n.t('services.faq.bot.message.question.type.message-link'),
        icon: 'el-icon-connection'
      },
      {
        label: i18n.t('models.question.type.timeout'),
        value: this.TIMEOUT,
        faq: i18n.t('services.faq.bot.message.question.type.timeout'),
        icon: 'el-icon-time'
      },
      {
        label: i18n.t('models.question.type.request-phone'),
        value: this.REQUEST_PHONE,
        faq: i18n.t('services.faq.bot.message.question.type.request-phone'),
        iconPath: new URL("@/assets/icons/chat/questionary/answer-phone.svg", import.meta.url).href
      },
      {
        label: i18n.t('models.question.type.request-email'),
        value: this.REQUEST_EMAIL,
        faq: i18n.t('services.faq.bot.message.question.type.request-email'),
        icon: 'el-icon-message'
      },
      {
        label: i18n.t('models.question.type.request-upload'),
        value: this.REQUEST_UPLOAD,
        faq: i18n.t('services.faq.bot.message.question.type.request-upload'),
        iconPath: new URL("@/assets/icons/chat/questionary/answer-download.svg", import.meta.url).href
      },
      {
        label: i18n.t('models.question.type.message'),
        value: this.MESSAGE,
        faq: i18n.t('services.faq.bot.message.question.type.message'),
        icon: 'el-icon-chat-line-round'
      },
      {
        label: i18n.t('models.question.type.message-random'),
        value: this.MESSAGE_RANDOM,
        faq: i18n.t('services.faq.bot.message.question.type.message-random'),
        icon: 'el-icon-chat-round'
      },
      {
        label: i18n.t('models.question.type.gpt'),
        value: this.GPT,
        faq: i18n.t('services.faq.bot.message.question.type.gpt'),
        icon: 'el-icon-user'
      },
      {
        label: i18n.t('models.question.type.final'),
        labelShort: i18n.t('models.question.type.final-short'),
        value: this.FINAL,
        faq: i18n.t('services.faq.bot.message.question.type.final')
      }
    ]
  }

  static getName(typeId, isShort) {
    let name = ''

    let type = this.types.find((x) => x.value === typeId)

    if (!type) {
      return name
    }

    return isShort && type.labelShort ? type.labelShort : type.label
  }

  static hasAnswers(typeId) {
    return [
      this.SINGLE,
      this.MULTIPLE,
      this.QUIZ,
      this.REQUEST_PHONE,
      this.REQUEST_EMAIL,
      this.REQUEST_UPLOAD,
      this.GPT
    ].includes(typeId)
  }

  static hasMultipleAnswers(typeId) {
    return [this.MULTIPLE, this.QUIZ, this.GPT].includes(typeId)
  }

  static hasMessage(typeId) {
    return [
      this.MESSAGE,
      this.MESSAGE_LANDING,
      this.MESSAGE_LINK,
      this.MESSAGE_RANDOM
    ].includes(typeId)
  }

  static hasMessageButton(typeId) {
    return [
      this.MULTIPLE,
      this.MESSAGE_LANDING,
      this.MESSAGE_LINK,
      this.FINAL
    ].includes(typeId)
  }

  static canUpdateType(typeId) {
    return [
      this.SINGLE,
      this.MULTIPLE,
      this.QUIZ,
      this.REQUEST_PHONE,
      this.REQUEST_EMAIL,
      this.REQUEST_UPLOAD,
      this.MESSAGE_LANDING,
      this.MESSAGE_LINK,
      this.MESSAGE,
      this.MESSAGE_RANDOM
    ].includes(typeId)
  }
}
