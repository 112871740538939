export const chatShopRoutes = [
  {
    path: 'chat/:chat_id/payment/shop/products/store',
    component: () => import('@/js/components/chat/content/shop/product/StoreChatProduct.vue'),
    name: 'StoreChatProduct',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/payment/shop_recurrent/products/store',
    component: () => import('@/js/components/chat/content/shop/product/StoreChatProduct.vue'),
    name: 'StoreChatRecurrentProduct',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/payment/shop/products/:item_id/update',
    component: () => import('@/js/components/chat/content/shop/product/UpdateChatProduct.vue'),
    name: 'UpdateChatProduct',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/payment/shop_recurrent/products/:item_id/update',
    component: () => import('@/js/components/chat/content/shop/product/UpdateChatProduct.vue'),
    name: 'UpdateChatRecurrentProduct',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },

  {
    path: 'chat/:chat_id/payment/shop/settings/store',
    component: () => import('@/js/components/chat/content/shop/setting/StoreChatProductPaymentSetting.vue'),
    name: 'StoreChatProductPaymentSetting',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/payment/shop_recurrent/settings/store',
    component: () => import( '@/js/components/chat/content/shop/setting/StoreChatProductPaymentSetting.vue'),
    name: 'StoreChatRecurrentProductPaymentSetting',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/payment/shop/settings/:item_id/update',
    component: () => import('@/js/components/chat/content/shop/setting/UpdateChatProductPaymentSetting.vue'),
    name: 'UpdateChatProductPaymentSetting',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/payment/shop_recurrent/settings/:item_id/update',
    component: () => import('@/js/components/chat/content/shop/setting/UpdateChatProductPaymentSetting.vue'),
    name: 'UpdateChatRecurrentProductPaymentSetting',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
