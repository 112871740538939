<template>
   <div v-if="isShow" class="mb-3 max-sm:text-xs" :class="webApp.styles.header">
       <div :style="'padding-top: ' + telegram.paddingTop + 'px;'">
           <div class="container max-w-[600px] mx-auto rounded-lg py-2 flex items-center justify-between gap-3 px-[10px!important]">
               <div class="flex flex-1 items-center justify-center gap-3">
                   <div class="flex-initial flex items-center">
                       <div class="z-0" v-if="isChatImage">
                           <div class="h-[45px] w-[45px] overflow-hidden rounded-full border-2 border-gray-300 dark:border-gray-900 duration-150">
                               <img :src="chat?.image" class="min-w-[45px] min-h-[45px] object-cover" :alt="chat?.name"  />
                           </div>
                       </div>
                       <div class="z-10" :class="isChatImage ? '-ml-[10px]' : ''" v-if="subscriber?.avatar">
                           <div class="h-[45px] w-[45px] overflow-hidden rounded-full border-2 border-gray-300 dark:border-gray-900 duration-150">
                               <img :src="subscriber?.avatar" class="min-w-[45px] min-h-[45px] object-cover" :alt="subscriber?.first_name + ' ' + subscriber?.last_name"  />
                           </div>
                       </div>
                   </div>
                   <div class="flex-1 grid grid-cols-1" v-if="subscriber?.uuid">
                       <div class="font-semibold duration-150">{{ subscriber?.first_name }} {{ subscriber?.last_name }}</div>
                       <div class="text-gray-500 dark:text-gray-300 text-sm duration-150" v-if="chat?.name">
                           <a :href="chat?.platform_url" target="_blank" :class="webApp.styles.link">{{ chat?.name }}</a>
                       </div>
                   </div>
                   <div class="flex-1 grid grid-cols-1" v-if="!subscriber?.uuid">
                       <div class="font-semibold duration-150">{{ chat?.name }}</div>
                       <div class="text-gray-500 dark:text-gray-300 text-sm duration-150" v-if="chat?.name">
                           <a :href="chat?.platform_url" target="_blank" :class="webApp.styles.link">@{{ chat?.username }}</a>
                       </div>
                   </div>
               </div>
               <div class="flex-initial">
                   <button
                       @click="settings.isDark = false;"
                       class="cursor-pointer duration-150 rounded-l-lg border-2 border-gray-300 border-r-0 dark:border-gray-700 p-1 px-2 text-sm"
                       :class="{'bg-green-200': !settings.isDark}"
                   >
                       <i class="icon el-icon-sunny"></i>
                   </button>
                   <button
                       @click="settings.isDark = true;"
                       class="cursor-pointer duration-150 rounded-r-lg border-2 border-gray-300 dark:border-gray-700 p-1 px-2 text-sm"
                       :class="{'bg-green-900': settings.isDark}"
                   >
                       <i class="icon el-icon-moon"></i>
                   </button>
               </div>
           </div>
       </div>
   </div>
</template>

<script>
import {useWebApp} from "@/js/store/pinia/webapp/WebApp.js";
import {useWebAppSettings} from "@/js/store/pinia/webapp/WebAppSettings.js";
import {useWebAppTelegram} from "@/js/store/pinia/webapp/WebAppTelegram.js";

export default {
    name: "WebAppHeader",
    computed:
    {
        webApp() {
            return useWebApp();
        },
        chat() {
            return this.webApp.chat;
        },
        subscriber() {
            return this.webApp.subscriber;
        },
        settings() {
            return useWebAppSettings();
        },
        isChatImage() {
            return this.chat.image !== undefined && this.chat.image !== null && this.chat.image !== 'https://storage.winwinbot.com/'
        },
        isShow() {
            return !this.webApp.isRawLanding
                && !this.webApp.isPreloader
                && this.webApp.isInitFull
        },
        telegram() {
            return useWebAppTelegram();
        },
    }
}
</script>

<style lang="css" scoped>

</style>
