import {ChatModel} from '@/js/models/chat/ChatModel'

export class ChatWebAppModel {
    static FILLABLE = ['name', 'allow_edit', 'folder_id']

    static urlForWebApp()
    {
        return '/webapp/webapp';
    }

    constructor() {
        this.searchForm = {
            folder_id: null
        }

        this.form = {
            id: null, folder_id: null, name: null, description: null, image: null, allow_edit: 0, sections: []
        }
    }

    static url(chatId) {
        return `${ChatModel.URL}/${chatId}/webapps`
    }

    static getName(item) {
        return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
    }

    static getPublicUrl(webApp, chatDomain = null) {
        const host = chatDomain ? chatDomain.url : window.location.host

        return `https://${host}/webapp/${webApp.uuid}`
    }
}
