import {
    ChatHomeworkModel
} from '@/js/models/chat/homework/ChatHomeworkModel'

const initialState = () => {
    return {
        chat_homeworks: [],
        chat_homeworks_loaded: false
    }
}

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        setLoaded(state, isLoaded) {
            state.chat_homeworks_loaded = isLoaded
        },

        setHomeworks(state, homeworks) {
            state.chat_homeworks = []

            homeworks.forEach(({
                                   id,
                                   name,
                                   folder_id
                               }) =>
                state.chat_homeworks.push({
                    id: id,
                    name: name,
                    folder_id: folder_id
                })
            )
        },

        addHomework(state, homework) {
            if (state.chat_homeworks.find((x) => x.id === homework.id)) return

            state.chat_homeworks.unshift(homework)
        },

        deleteHomework(state, id) {
            let index = state.chat_homeworks.findIndex((item) => item.id === id)

            if (index === -1) return

            state.chat_homeworks.splice(index, 1)
        },

        reset(state) {
            const init_state = initialState()

            for (let key in init_state) {
                state[key] = init_state[key]
            }
        }
    },
    actions: {
        async fetchHomeworks({dispatch}, {chat_id, params = {}}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(ChatHomeworkModel.url(chat_id), {
                        params: {
                            ...params,
                            ...{
                                with_chat: 1
                            }
                        }
                    })
                    .then(({data}) => {
                        dispatch('setHomeworks', data.items)
                        dispatch('chat/setCurrentChat', data.chat, {
                            root: true
                        })
                        return resolve()
                    })
                    .catch(async (error) => {
                        dispatch('resetHomeworks')
                        return reject(error)
                    })
            })
        },

        setHomeworks({commit}, homeworks) {
            commit('setHomeworks', homeworks)

            commit('setLoaded', true)
        },

        addHomework({commit}, homework) {
            commit('addHomework', homework)
        },

        deleteHomework({commit}, id) {
            commit('deleteHomework', id)
        },

        resetHomeworks({commit}) {
            commit('reset')
        }
    }
}
