import {i18n} from '@/js/plugins/i18n'
import {DataTypeModel} from '@/js/models/dataType/DataTypeModel'
import {ChatPlatformModel} from '@/js/models/chat/ChatPlatformModel'
import {UploadFileTypeModel} from '@/js/models/upload/UploadFileTypeModel'

export const uniqueError = 'services.validation.unique'

export const passwordConfirmError = 'services.validation.password-confirm'

export const urlRule = () => {
    return {
        type: 'url', message: i18n.t('services.validation.url'), trigger: ['blur']
    }
}

export const requiredRule = (isHidden = false) => {
    return {
        required: true, message: isHidden ? ' ' : i18n.t('services.validation.required'), trigger: ['blur']
    }
}

export const maxNewLinesMessage = 'services.validation.max-new-lines'

export const maxLengthMessage = 'services.validation.max-length'
export const maxLengthCountMessage = 'services.validation.max-length-count'
export const minLengthMessage = 'services.validation.min-length'

export const requiredTextMessage = 'services.validation.required-text'
export const requiredOnlyTextMessage = 'services.validation.required-only-text'
export const requiredNoTextMessage = 'services.validation.required-no-text'

export const requiredFinalMessage = 'services.validation.required-final-message'

export const onlyUploadOrButton = 'services.validation.only-upload-or-button'
export const onlyMenuOrButton = 'services.validation.only-menu-or-button'

export const emailRule = () => {
    return {
        type: 'email', message: i18n.t('services.validation.email'), trigger: ['blur']
    }
}

export const passwordMinRule = () => {
    return {
        min: 8, message: i18n.t('services.validation.password-min-length'), trigger: ['blur']
    }
}

export const integerRule = () => {
    return {
        type: 'integer', message: i18n.t('services.validation.number'), trigger: ['blur']
    }
}

export const numberRule = () => {
    return {
        trigger: ['blur'], validator: (rule, value, callback) => {
            return !value || !isNaN(value) ? callback() : callback(new Error(i18n.t('services.validation.number')))
        }
    }
}

export const mathExpressionRule = () => {
    return {
        trigger: ['blur'], validator: (rule, value, callback) => {
            if (!value) {
                return callback()
            }

            const re = /(?:(?:^|[-+^*/])(?:\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*))+$/

            return !value || re.test(value) ? callback() : callback(new Error(i18n.t('services.validation.expression')))
        }
    }
}

export const nullableRule = () => {
    return {
        trigger: ['blur'], validator: (rule, value, callback) => callback()
    }
}

export const getFilterValueRuleMixin = {
    methods: {
        getFilterValueRule(filterTypeId, isRequired = true) {
            let rules = isRequired ? [requiredRule()] : []

            return filterTypeId === DataTypeModel.INT ? rules.concat([numberRule()]) : rules
        }
    }
}

export const platformTextLengthValidator = (length, platformId, callback, hasButton = false, uploadTypeId = null, isHtml = false, buttons = [], hasRecurrentButton = false) => {
    const minLength = isHtml ? 8 : 1
    let textLimit = 0

    switch (platformId) {
        case ChatPlatformModel.TELEGRAM:
            if (!uploadTypeId && length < minLength) {
                return callback(new Error(i18n.t(requiredTextMessage)))
            }

            if (uploadTypeId) {
                textLimit = length - 900

                if (textLimit > 0) {
                    return callback(new Error(i18n.t(maxLengthCountMessage, {
                        count: textLimit, word: i18n.tc('tc.characters', textLimit)
                    })))
                }

                if ([UploadFileTypeModel.VIDEO_NOTE, UploadFileTypeModel.LOCATION].includes(+uploadTypeId) && length >= minLength) {
                    return callback(new Error(i18n.t(requiredNoTextMessage)))
                }
            }

            textLimit = length - 4000

            if (textLimit > 0) {
                return callback(new Error(i18n.t(maxLengthCountMessage, {
                    count: textLimit, word: i18n.tc('tc.characters', textLimit)
                })))
            }

            return callback()
        case ChatPlatformModel.VIBER:
            if (!uploadTypeId && !hasButton && length < minLength) {
                return callback(new Error(i18n.t(requiredTextMessage)))
            }

            if (uploadTypeId) {
                if (+uploadTypeId === UploadFileTypeModel.FILE && length >= minLength) {
                    return callback(new Error(i18n.t(requiredNoTextMessage)))
                }

                textLimit = length - 150

                if (textLimit > 0) {
                    return callback(new Error(i18n.t(maxLengthCountMessage, {
                        count: textLimit, word: i18n.tc('tc.characters', textLimit)
                    })))
                }
            }

            if (hasButton) {
                let rows = buttons.length

                if (length > minLength) {
                    rows += Math.ceil(length / 52)
                }

                if (rows > 7) {
                    return callback(new Error(i18n.t(maxLengthMessage)))
                }
            }

            textLimit = length - 4000

            if (textLimit > 0) {
                return callback(new Error(i18n.t(maxLengthCountMessage, {
                    count: textLimit, word: i18n.tc('tc.characters', textLimit)
                })))
            }

            return callback()
        case ChatPlatformModel.VK:
            if (!uploadTypeId && length < minLength) {
                return callback(new Error(i18n.t(requiredTextMessage)))
            }

            textLimit = length - 4000

            if (textLimit > 0) {
                return callback(new Error(i18n.t(maxLengthCountMessage, {
                    count: textLimit, word: i18n.tc('tc.characters', textLimit)
                })))
            }

            return callback()
        case ChatPlatformModel.SLACK:
            if (!uploadTypeId && length < minLength) {
                return callback(new Error(i18n.t(requiredTextMessage)))
            }

            if (uploadTypeId) {
                textLimit = length - 19000

                if (textLimit > 0) {
                    return callback(new Error(i18n.t(maxLengthCountMessage, {
                        count: textLimit, word: i18n.tc('tc.characters', textLimit)
                    })))
                }

                if (+uploadTypeId === UploadFileTypeModel.PHOTO && length < minLength) {
                    return callback(new Error(i18n.t(requiredTextMessage)))
                }
            }

            return callback()
        case ChatPlatformModel.FACEBOOK:
            if ((!uploadTypeId || hasButton) && length < minLength) {
                return callback(new Error(i18n.t(requiredTextMessage)))
            }

            if (uploadTypeId) {
                textLimit = length - 80

                if ([UploadFileTypeModel.PHOTO, UploadFileTypeModel.SUB_AVATAR].includes(+uploadTypeId) && textLimit > 0) {
                    return callback(new Error(i18n.t(maxLengthCountMessage, {
                        count: textLimit, word: i18n.tc('tc.characters', textLimit)
                    })))
                }

                if (![UploadFileTypeModel.PHOTO, UploadFileTypeModel.SUB_AVATAR, UploadFileTypeModel.FILE].includes(+uploadTypeId)) {
                    if (hasButton) {
                        return callback(new Error(i18n.t(onlyUploadOrButton)))
                    }

                    if (length >= minLength) {
                        return callback(new Error(i18n.t(requiredNoTextMessage)))
                    }
                }
            }

            textLimit = length - 65

            if (hasRecurrentButton && textLimit > 0) {
                return callback(new Error(i18n.t(maxLengthCountMessage, {
                    count: textLimit, word: i18n.tc('tc.characters', textLimit)
                })))
            }

            textLimit = length - 1000

            if (textLimit > 0) {
                return callback(new Error(i18n.t(maxLengthCountMessage, {
                    count: textLimit, word: i18n.tc('tc.characters', textLimit)
                })))
            }

            return callback()
        case ChatPlatformModel.INSTAGRAM:
            if ((!uploadTypeId || hasButton) && length < minLength) {
                return callback(new Error(i18n.t(requiredTextMessage)))
            }

            textLimit = length - 80

            if (+uploadTypeId === UploadFileTypeModel.PHOTO && textLimit > 0) {
                return callback(new Error(i18n.t(maxLengthCountMessage, {
                    count: textLimit, word: i18n.tc('tc.characters', textLimit)
                })))
            }

            textLimit = length - 65

            if (hasRecurrentButton && textLimit > 0) {
                return callback(new Error(i18n.t(maxLengthCountMessage, {
                    count: textLimit, word: i18n.tc('tc.characters', textLimit)
                })))
            }

            textLimit = length - 1000

            if (textLimit > 0) {
                return callback(new Error(i18n.t(maxLengthCountMessage, {
                    count: textLimit, word: i18n.tc('tc.characters', textLimit)
                })))
            }

            return callback()
    }
}
