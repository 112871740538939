export const chatWidgetRoutes = [
  {
    path: 'chat/:chat_id/promotion/widgets/store',
    component: () => import('@/js/components/chat/settings/widget/StoreChatWidget.vue'),
    name: 'StoreChatWidget',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/promotion/widgets/:item_id/update',
    component: () => import('@/js/components/chat/settings/widget/UpdateChatWidget.vue'),
    name: 'UpdateChatWidget',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
