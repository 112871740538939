import { ChatModel } from '@/js/models/chat/ChatModel'
import { ChatMessageFolderScheduleModel } from '@/js/models/chat/message/folder/ChatMessageFolderScheduleModel'

export class ChatMessageFolderModel {
  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/message-folders`
  }

  constructor() {
    this.form = {
      id: null,
      name: null,
      type_id: null,
      allow_edit: false,
      schedule: new ChatMessageFolderScheduleModel().form
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }
}
