export const adminSupportRoutes = [
  {
    path: 'admin-support/users',
    component: () => import('@/js/components/admin/support/IndexAdminSupport.vue'),
    name: 'IndexAdminSupport',
    meta: {
      requireAdminSupport: true,
      requireAuth: true
    },
    props: true
  }
]
