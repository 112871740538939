export const intensivesMap = {
  '1': 'Интенсив — Быстрые деньги в телеграме 2.0',
  '2': 'Микроворонка по Краснову'
}

export const availableIntensivesByTicket = {
  premium: ['1', '2'],
  vip: [],
  standart: [],
  free: []
}

export const productInfo = {
  '1': {
    title: 'Быстрые деньги в телеграме',
    subtitle:
      'Смотри видео и узнай как эксперту создать очередь клиентов на свои услуги и продукты используя телеграм\u00A0👇',
    img:  new URL("@/assets/images/conference/product/1.png", import.meta.url).href,
    quantityVideo: '5 видео',
    text:
      'Курс из 5 видео в котором я раскрываю топовую механику по заработку быстрых денег в телеграме через микроворонку \n\nЗапусти небольшую микроворонку с 1 продающим видео и создай очередь на свои услуги и продукты',
    firstVideo: {
      src: 'https://kinescope.io/xivLnyg6qtsSVZkgbSJJ46'
    },
    content: [
      {
        id: '1',
        img:  new URL("@/assets/images/conference/content/1_1.png", import.meta.url).href,
        text: '01. База — Быстрые деньги в телеграме'
      },
      {
        id: '2',
        img:  new URL("@/assets/images/conference/content/1_1.png", import.meta.url).href,
        text: '02. Путь клиента и блок схема по BPNM'
      },
      {
        id: '3',
        img:  new URL("@/assets/images/conference/content/1_1.png", import.meta.url).href,
        text: '03. Как собрать сильный оффер с чеком от 100 000₽'
      },
      {
        id: '4',
        img:  new URL("@/assets/images/conference/content/1_1.png", import.meta.url).href,
        text: '04. Смысловые карточки которые помогут в сборке оффера'
      },
      {
        id: '5',
        img:  new URL("@/assets/images/conference/content/1_1.png", import.meta.url).href,
        text: '05. Сильные смыслы и идеи для твоего продукта'
      }
    ],
    cards: {
      main: {
        quantity: 8,
        text:
          '8 смысловых карт которые помогут сделать сильный оффер и снять сопротивление в продажах'
      },
      gray: {
        img:  new URL("@/assets/images/conference/icons/cards.svg", import.meta.url).href,
        text: '8 смысловых карт'
      },
      black: {
        img:  new URL("@/assets/images/conference/icons/frame.svg", import.meta.url).href,
        text: 'Шаблон лендинга'
      },
      brown: {
        img:  new URL("@/assets/images/conference/icons/squares.svg", import.meta.url).href,
        text: 'Блок-схема',
        textSmall: 'Собери микроворонку в 1 видео по этой блок-схеме'
      },
      wide: {
        img:  new URL("@/assets/images/conference/icons/phone.svg", import.meta.url).href,
        text: 'Сценарий продажи на консультациях и диагностиках'
      }
    },
    format: {
      tags: [
        {
          text: 'NEW',
          background: '#C9FB47',
          color: '#000'
        },
        { text: 'Курс' },
        { text: '5 видео' }
      ],
      desc: [
        {
          text: 'Доступ к онлайн-курсу «Быстрые деньги в телеграме 2.0»'
        },
        {
          text: 'Доступ к курсу на 1 месяц'
        },
        {
          text: 'Доступ к чату курса на 1 месяц'
        },
        {
          text: 'Смысловые карточки (электронная версия)'
        },
        {
          text: 'Блоксхема'
        }
      ]
    }
  },
  '2': {
    title: 'Микроворонка по Краснову',
    subtitle:
      'Смотри видео и узнай как эксперту создать очередь клиентов на свои услуги и продукты используя телеграм\u00A0👇',
    img:  new URL("@/assets/images/conference/product/2.png", import.meta.url).href,
    quantityVideo: '18 видео',
    text:
      'Узнай, как создать очередь клиентов на свои консультации или продавй свой флагманский продукт через микроворонку по Краснову 24/7\n\nЧто может микроворонка:',
    textList: [
      '24/7 продает серию твоих продуктов за тебя',
      'Доводит подписчика до оффера за 15 минут, до первых твоих продаж',
      'Теряет гораздо меньше людей, т.к. от регистрации до покупки проходит 15-20 минут, а не 2 недели, как в классических запусках',
      'Прогревает автоматически на вебинары/консультации/курсы/диагностики/челенджи',
      'Выстраивает очередь клиентов на консультации/диагностики',
      'Экономит до 80% твоего личного времени',
      'Снимает подавляющую часть рисков, сводя их к минимуму, т.к. каждый этап микроворонки заменяем как конструктор Лего в любое время без остановки продаж',
      'Снимает огромный объем работы и риски с продюсера'
    ],
    firstVideo: {
      src: 'https://kinescope.io/q6N7NqW1hoaxLA7fKVKQTy'
    },
    content: [
      {
        id: '1',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '01. Как зарабатывать больше миллиона рублей на микроворонке',
        src: 'https://kinescope.io/m1fVvaLBAR4CgSibvHSavb',
        duration: '10 мин.'
        // additional: [
        //   {
        //     icon: new URL("@/assets/images/conference/icons/docs.svg", import.meta.url).href,
        //     text: 'Интеллект-карта',
        //     src: ''
        //   },
        //   {
        //     icon: new URL("@/assets/images/conference/icons/docs.svg", import.meta.url).href,
        //     text: 'Интеллект-карта 2',
        //     src: ''
        //   },
        //   {
        //     icon: new URL("@/assets/images/conference/icons/pdf.svg", import.meta.url).href,
        //     text: 'PDF',
        //     src: ''
        //   }
        // ]
      },
      {
        id: '2',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '02. Кому подходит микроворонка, как быстро запустить',
        src: 'https://kinescope.io/wHedUNm7GFmCgRMm6j9FCv',
        duration: '11 мин.'
      },
      {
        id: '3',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '03. Обзор сервисов для запуска',
        src: 'https://kinescope.io/aZu1AFpgC4MCJqENSQLfjP',
        duration: '15 мин.'
      },
      {
        id: '4',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '04. У кого не получится, какие нельзя допускать ошибки',
        src: 'https://kinescope.io/nb7qb9zTdRLsXSAsPV3DD2',
        duration: '7 мин.'
      },
      {
        id: '5',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '05. Сколько можно заработать на микроворонке',
        src: 'https://kinescope.io/b4EPavpqMHXirMuA8t7DBi',
        duration: '15 мин.'
      },
      {
        id: '6',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '06. Прогрев на: вебинары, марафоны, диагностики и т.д.',
        src: 'https://kinescope.io/5bz5seVE1GXvTfjH7nQfFf',
        duration: '18 мин.'
      },
      {
        id: '7',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '07. Очередь на консультации, сессии, диагностики',
        src: 'https://kinescope.io/87HWFDzFA14PU3zPMPjpTj',
        duration: '20 мин.'
      },
      {
        id: '8',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '08. Очередь на флагманский продукт',
        src: 'https://kinescope.io/8HJfBm3N4j1eNDeDfmkMaS',
        duration: '26 мин.'
      },
      {
        id: '9',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '09. Формула 3 видео',
        src: 'https://kinescope.io/82Kv7ub1JjpimmNgXJ9ceA',
        duration: '17 мин.'
      },
      {
        id: '10',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '10. Как собрать сильный релевантный оффер',
        src: 'https://kinescope.io/58REAkv9zc22wBkJ1iqmyG',
        duration: '15 мин.'
      },
      {
        id: '11',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '11. Как собрать метатеги',
        src: 'https://kinescope.io/kqDPdgounhZtbrfxtDkUq7',
        duration: '7 мин.'
      },
      {
        id: '12',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '12. Первый метатег – Оффер, заголовок',
        src: 'https://kinescope.io/cHZvjPJihfuJnBMAmKshz7',
        duration: '8 мин.'
      },
      {
        id: '13',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '13. Метатег — Решение + идея',
        src: 'https://kinescope.io/itwfEZj6fic8qhbet6PUxU',
        duration: '8 мин.'
      },
      {
        id: '14',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '14. Метатег — Обещания к продукту',
        src: 'https://kinescope.io/t3HQRNXEL9tr6A1Mk5QdP1',
        duration: '11 мин.'
      },
      {
        id: '15',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '15. Метатег — Выгоды к продукту',
        src: 'https://kinescope.io/3UEL7T8KZQw6Si3PNasxUZ',
        duration: '4 мин.'
      },
      {
        id: '16',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text:
          '16. Метатег — Твой контраст, то что выделит тебя на фоне конкурентов',
        src: 'https://kinescope.io/5MeJoQA5ZhnWnpuab2SUot',
        duration: '10 мин.'
      },
      {
        id: '17',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '17. Метатег — Трансформация клиента, то что продает в долгую',
        src: 'https://kinescope.io/uAE2c8LRJ2KY3xpGEJGQcb',
        duration: '9 мин.'
      },
      {
        id: '18',
        img:  new URL("@/assets/images/conference/content/2_1.png", import.meta.url).href,
        text: '18. Что нужно чтобы микроворонка приносила миллионы рублей',
        src: 'https://kinescope.io/n4CKMhqomrdAteThpWB4s8',
        duration: '10 мин.'
      }
    ],
    cards: {
      main: {
        quantity: 18,
        textBold: '18 коротких видео с помощью которых узнаешь —',
        text:
          'Как создать очередь клиентов на свои консультации или флагманский продукт'
      },
      gray: {
        img:  new URL("@/assets/images/conference/icons/table.svg", import.meta.url).href,
        text: 'Таблица прибыли микроворонки'
      },
      black: {
        img:  new URL("@/assets/images/conference/icons/squares.svg", import.meta.url).href,
        text: 'Блок-схема микроворонки'
      },
      brown: {
        img:  new URL("@/assets/images/conference/icons/stick.svg", import.meta.url).href,
        text: 'Интеллект-карта — \nМетатеги',
        textSmall: 'Создавай предложения на которые по умолчанию уже есть спрос'
      }
    },
    format: {
      tags: [
        {
          text: 'NEW',
          background: '#ED6A5E'
        },
        { text: 'Курс' },
        { text: '18 видео' }
      ],
      desc: [
        { text: 'Доступ к онлайн-курсу «Микроворонка по Краснову»' },
        { text: 'Доступ к курсу на 1 год' },
        { text: 'Доступ к чату курса на 1 месяц' },
        { text: 'Таблица прибыльности воронки' },
        { text: 'Блок-схема микроворонки' },
        { text: 'Интеллект-карта — Метатеги' },
        { text: 'Обратная связь в чате в формате войсов' }
      ]
    }
  }
}
