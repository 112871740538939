import axios from 'axios'
import { i18n } from '@/js/plugins/i18n'

import store from '@/js/store/index'

export const CHAT_ADMIN_KEY = 'chat_admin'

export const IS_NULLABLE_NAME = (name) => {
  return ['', 'null', null].includes(name)
}

export const GET_CHAT_ADMIN = () => {
  return localStorage.getItem(CHAT_ADMIN_KEY)
}

export const GET_NAME_BY_TIME = () => {
  const currentDate = new Date()

  const currentHours = currentDate
    .getHours()
    .toString()
    .padStart(2, '0')

  const currentMinutes = currentDate
    .getMinutes()
    .toString()
    .padStart(2, '0')

  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0')

  const currentDay = currentDate
    .getDate()
    .toString()
    .padStart(2, '0')

  const currentYear = currentDate.getFullYear()

  const time = `${currentDay}-${currentMonth}-${currentYear} ${currentHours}:${currentMinutes}`

  return `${i18n.t('main-words.untitled')} ${time}`
}

window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const baseUrl = document.head.querySelector('meta[name="api-url"]')

window.axios.defaults.baseURL = baseUrl ? baseUrl.content : '/api/'

const token = document.head.querySelector('meta[name="csrf-token"]')

window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token
  ? token.content
  : ''

axios.defaults.headers.common['Authorization'] =
  localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')

axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization =
      localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')

    const isChatAdmin = GET_CHAT_ADMIN()
    const nameByTime = GET_NAME_BY_TIME()

    if (config.hasOwnProperty('data')) {
      if (config.data instanceof FormData) {
        if (IS_NULLABLE_NAME(config.data.get('name'))) {
          config.data.set('name', nameByTime)
        }

        if (isChatAdmin) {
          config.data.append(CHAT_ADMIN_KEY, isChatAdmin)
        }
      } else {
        if (
          config.data.hasOwnProperty('name') &&
          IS_NULLABLE_NAME(config.data.name)
        ) {
          config.data['name'] = nameByTime
        }

        if (isChatAdmin && !config.data.hasOwnProperty(CHAT_ADMIN_KEY)) {
          config.data[CHAT_ADMIN_KEY] = isChatAdmin
        }
      }
    } else if (config.hasOwnProperty('params')) {
      if (
        config.params.hasOwnProperty('name') &&
        IS_NULLABLE_NAME(config.params.name)
      ) {
        config.params['name'] = nameByTime
      }

      if (isChatAdmin && !config.params.hasOwnProperty(CHAT_ADMIN_KEY)) {
        config.params[CHAT_ADMIN_KEY] = isChatAdmin
      }
    }

    config.headers['i18n-lang'] = i18n.locale

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.data.message === 'Unauthenticated.') {
      error.response.data.message = i18n.t('alert.unauthenticated')

      store.dispatch('reset')
    } else if (error.response.data.message === 'The given data was invalid.') {
      error.response.data.message = error.response.data.errors
    }

    store.dispatch('alert/showAlert', {
      text: error.response.data.message?.message || error.response.data.message,
      type: 'error'
    })

    return Promise.reject(error)
  }
)
