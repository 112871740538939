export const publicChatCourseRoutes = [
  // {
  //   path: '/course/:course_id',
  //   component: () => import('@/js/components/public/chat/course/PublicChatCourse.vue'),
  //   name: 'PublicChatCourse',
  //   meta: {
  //     isPublic: true
  //   },
  //   props: true
  // }
]
