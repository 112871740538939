import { Model } from '@/js/models/Model'
import { i18n } from '@/js/plugins/i18n'

export class ChatWebinarSessionRepeatModel extends Model {
  static EVERY_DAY = 1

  static EVERY_WEEK = 2

  static get types() {
    return [
      {
        label: i18n.t('models.chat.webinar.session.repeat.every-day'),
        value: this.EVERY_DAY
      },
      {
        label: i18n.t('models.chat.webinar.session.repeat.every-week'),
        value: this.EVERY_WEEK
      }
    ]
  }
}
