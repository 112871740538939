export const chatTournamentRoutes = [
  {
    path: 'chat/:chat_id/content/tournaments/store',
    component: () => import('@/js/components/chat/content/tournament/StoreChatTournament.vue'),
    name: 'StoreChatTournament',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/content/tournaments/:item_id/update',
    component: () => import('@/js/components/chat/content/tournament/UpdateChatTournament.vue'),
    name: 'UpdateChatTournament',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
