<template>
  <el-container direction="vertical" class="min-h-100 p-0">
    <main-header-chat
      v-if="isHomework"
      v-show="showHeader"
      :params="params"
      :style="stylePadding"
    />

    <el-main
      id="my-main"
      :class="hasOpacity ? 'custom-bg' : ''"
      :style="bgStyle"
      class="d-flex position-relative p-0"
    >
      <el-row
        :style="!isCourse ? styleMargin : ''"
        class="flex-grow-1 overflow-x-hidden"
      >
        <router-view :params="params" />
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
  import { mapActions, mapState } from 'vuex'
  import { Trans } from '@/js/plugins/translation'

  import MainHeaderChat from '@/js/components/main/header/MainHeaderChat.vue'

  import { ChatLangModel } from '@/js/models/chat/ChatLangModel'

  export default {
    name: 'MainPublic',
    components: {
      MainHeaderChat
    },
    props: {
      params: {
        required: false,
        type: Object
      }
    },
    computed: {
      ...mapState([
        'mobileSize',
        'mobileSmallSize',
        'laptopSize',
        'hasOpacity',
        'learme'
      ]),

      isHomework() {
        return this.$route.name === 'PublicChatHomework'
      },

      isCourse() {
        return this.$route.name === 'PublicChatCourse'
      },

      showHeader() {
        return !this.mobileSmallSize || !!!this.subscriber_id
      },

      stylePadding() {
        return this.laptopSize
          ? 'padding: 0 calc(7.5% + 20px)'
          : 'padding: 0 20px'
      },

      styleMargin() {
        if (this.laptopSize) {
          return 'margin: 20px calc(7.5% + 20px)'
        }

        if (this.mobileSmallSize || (this.mobileSize && this.isCourse)) {
          return 'margin: 0'
        }

        return 'margin: 20px'
      },

      bgStyle() {
        if (this.learme) {
          return 'background: #F2F7FC'
        }

        let style = ``

        if (this.bg_color) {
          style = `--my-custom-bg: ${this.bgGradient(this.bg_color)}`
        } else if (this.bg_path) {
          style = `--my-custom-bg: url(${this.bg_path})`
        }

        return style
      },

      hideForConversation() {
        return this.mobileSmallSize && !!this.subscriber_id
      },

      openMobileConversation() {
        return this.mobileSmallSize && !!this.subscriber_id
      }
    },
    created() {
      this.setLang()
    },
    mounted() {
      window.addEventListener('resize', this.resize)
    },
    destroyed() {
      window.removeEventListener('resize', this.resize)
    },
    methods: {
      ...mapActions(['changeWidthSize']),

      setLang() {
        if (!this.params.lang_id) {
          return
        }

        Trans.changeLanguage(ChatLangModel.getNameById(this.params.lang_id))
      },

      resize() {
        this.changeWidthSize(window.innerWidth)
      }
    }
  }
</script>
<style scoped></style>
