import { defineStore } from 'pinia';

export const useWebAppScrolling = defineStore('webAppScrolling', {
    state: () => ({
        urls: {}
    }),
    persist: true,
    actions:
    {
        setScrollPosition(url, position)
        {
            this.$patch(state => {
                state.urls[url] = position;
            });
        },
        getScrollPosition(url)
        {
            return this.urls[url] || 0;
        },
    },
});
