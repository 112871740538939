import {ChatModel} from '@/js/models/chat/ChatModel'
import {ChatLangModel} from '@/js/models/chat/ChatLangModel'
import {ChatProductAttemptTypeModel} from '@/js/models/chat/product/ChatProductAttemptTypeModel'

export class ChatProductModel {
    static BOOLEAN_KEYS = ['allow_upgrade', 'allow_downgrade', 'allow_recalculate_days', 'allow_edit', 'is_required_accept_terms', 'is_required_email', 'is_required_tel']
    static JSON_KEYS = ['actions', 'actions_recurrent_finished', 'actions_recurrent_changed', 'attempts']
    static UNDEFINED_KEYS = ['interval_count']
    static FILLABLE = ['name', 'description', 'volume_factor', 'limit_payment_count', 'amount', 'amount_discount', 'amount_first_time', 'allow_edit', 'allow_upgrade', 'allow_downgrade', 'allow_recalculate_days', 'setting_id', 'currency_id', 'is_recurrent', 'is_inside', 'is_required_accept_terms', 'is_required_email', 'is_required_tel', 'custom_id', 'seller_name', 'seller_email', 'seller_tel', 'seller_terms_of_use_url', 'interval', 'interval_type_id']

    constructor() {
        this.customParams = {
            prodamus: {
                sub_form_props: [],
                subscription_demo_period: 0,
                demo_mode: false,
                has_utm: false,
                has_sub_form_props: false
            },
            success_btn_name: '',
            success_btn_url: null
        }

        this.form = {
            id: null,
            chat_id: null,
            setting_id: null,
            currency_id: null,
            setting: null,
            custom_id: null,
            interval: null,
            interval_count: undefined,
            interval_type_id: null,
            name: null,
            description: null,
            image: null,
            has_limit_payment_count: false,
            limit_payment_count: null,
            volume_factor: 0,
            amount: 1,
            amount_discount: null,
            amount_first_time: null,
            has_amount_first_time: false,
            is_recurrent: false,
            allow_edit: false,
            allow_recalculate_days: false,
            allow_upgrade: false,
            allow_downgrade: false,
            attempts: [`${ChatProductAttemptTypeModel.HOUR}_3`, `${ChatProductAttemptTypeModel.HOUR}_24`, `${ChatProductAttemptTypeModel.DAY}_2`, `${ChatProductAttemptTypeModel.DAY}_4`],
            actions: [],
            actions_recurrent_finished: [],
            actions_recurrent_changed: [],
            seller_name: null,
            seller_email: null,
            seller_tel: null,
            seller_terms_of_use: null,
            custom_params: this.customParams,
            is_inside: 0,
            is_required_accept_terms: false,
            is_required_email: false,
            is_required_tel: false
        }
    }

    static urlForWebApp()
    {
        return '/webapp/product'
    }

    static urlForWebAppParams()
    {
        return '/webapp/product/params'
    }

    static url(chatId) {
        return `${ChatModel.URL}/${chatId}/products`
    }

    static getPublicUrl(product, domain) {
        const host = domain ? domain.url : window.location.host

        const path = product.is_recurrent ? 'subscription' : 'product'

        return `https://${host}/${path}/${product.url}`
    }

    static getPublicSuccessUrl(langId, domain) {
        const host = domain ? domain.url : window.location.host

        const lang = ChatLangModel.getNameById(langId)

        return `https://${host}/product-payment/${lang}/success`
    }

    static getName(item) {
        return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
    }
}
