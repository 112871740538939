import { i18n } from '@/js/plugins/i18n'
import { Model } from '@/js/models/Model'

export class ChatMessageTriggerEventModel extends Model {
  static SUB_FORM = 1
  static START_BOT = 2
  static MESSAGE_LINK = 3
  static BUTTON = 4
  static QUESTIONARY_COMPLETE = 5
  static NO_ACTIVITY = 6
  static STAGE = 7
  static QUESTIONARY_NOT_COMPLETE = 8
  static HOMEWORK = 9
  static PRODUCT_RECURRENT = 10
  static STORY_MENTION = 11
  static TAG_ADD = 12
  static TAG_DELETE = 13
  static PRODUCT_RECURRENT_EXPIRATION = 14
  static GROUP_SUBSCRIPTION = 15
  static GROUP_REJECTION = 16
  static PRIVATE_REPLY = 17
  static ANNIVERSARY = 18

  static ADMIN_CHAT_CREATED = 19
  static ADMIN_CHAT_NEW_SUB = 20
  static ADMIN_PAYMENT_SUCCESS_FIRST = 21
  static ADMIN_PAYMENT_SUCCESS_NOT_FIRST = 22
  static ADMIN_SUBSCRIPTION_EXPIRATION = 23
  static ADMIN_PAYMENT_FAIL = 24
  static ADMIN_CHAT_STOPPED = 25

  static get types() {
    return [
      {
        value: this.START_BOT,
        label: i18n.t('models.chat.message.trigger.event.start-bot')
      },
      {
        value: this.STORY_MENTION,
        label: i18n.t('models.chat.message.trigger.event.story-mention')
      },
      {
        value: this.PRIVATE_REPLY,
        label: i18n.t('models.chat.message.trigger.event.private-reply')
      },
      {
        value: this.SUB_FORM,
        label: i18n.t('models.chat.message.trigger.event.sub-form')
      },
      {
        value: this.MESSAGE_LINK,
        label: i18n.t('models.chat.message.trigger.event.message-link')
      },
      {
        value: this.BUTTON,
        label: i18n.t('models.chat.message.trigger.event.button-link')
      },
      {
        value: this.QUESTIONARY_COMPLETE,
        label: i18n.t('models.chat.message.trigger.event.questionary-complete')
      },
      {
        value: this.QUESTIONARY_NOT_COMPLETE,
        label: i18n.t(
          'models.chat.message.trigger.event.questionary-not-complete'
        )
      },
      {
        value: this.NO_ACTIVITY,
        label: i18n.t('models.chat.message.trigger.event.no-activity')
      },
      {
        value: this.STAGE,
        label: i18n.t('models.chat.message.trigger.event.stage')
      },
      {
        value: this.HOMEWORK,
        label: i18n.t('models.chat.message.trigger.event.homework')
      },
      {
        value: this.PRODUCT_RECURRENT,
        label: i18n.t('models.chat.message.trigger.event.product-recurrent')
      },
      {
        value: this.TAG_ADD,
        label: i18n.t('models.chat.message.trigger.event.tag-add')
      },
      {
        value: this.TAG_DELETE,
        label: i18n.t('models.chat.message.trigger.event.tag-delete')
      },
      {
        value: this.PRODUCT_RECURRENT_EXPIRATION,
        label: i18n.t(
          'models.chat.message.trigger.event.product-recurrent-expiration'
        )
      },
      {
        value: this.GROUP_SUBSCRIPTION,
        label: i18n.t('models.chat.message.trigger.event.group-subscription')
      },
      {
        value: this.GROUP_REJECTION,
        label: i18n.t('models.chat.message.trigger.event.group-rejection')
      },
      {
        value: this.ANNIVERSARY,
        label: i18n.t('models.chat.message.trigger.event.anniversary')
      }
    ]
  }

  static get adminTypes() {
    return [
      {
        value: this.ADMIN_CHAT_CREATED,
        label: i18n.t('models.chat.message.trigger.event.admin-chat-created')
      },
      {
        value: this.ADMIN_CHAT_NEW_SUB,
        label: i18n.t('models.chat.message.trigger.event.admin-chat-new-sub')
      },
      {
        value: this.ADMIN_PAYMENT_SUCCESS_FIRST,
        label: i18n.t(
          'models.chat.message.trigger.event.admin-payment-success-first'
        )
      },
      {
        value: this.ADMIN_PAYMENT_SUCCESS_NOT_FIRST,
        label: i18n.t(
          'models.chat.message.trigger.event.admin-payment-success-not-first'
        )
      },
      {
        value: this.ADMIN_SUBSCRIPTION_EXPIRATION,
        label: i18n.t(
          'models.chat.message.trigger.event.admin-subscription-expiration'
        )
      },
      {
        value: this.ADMIN_PAYMENT_FAIL,
        label: i18n.t('models.chat.message.trigger.event.admin-payment-fail')
      },
      {
        value: this.ADMIN_CHAT_STOPPED,
        label: i18n.t('models.chat.message.trigger.event.admin-chat-stopped')
      }
    ]
  }

  static getNameById(id) {
    let type = this.types.find((x) => x.value === id)

    if (type) {
      return type.label
    }

    type = this.adminTypes.find((x) => x.value === id)

    return type ? type.label : ''
  }
}
