<template>
  <div class="burger">
    <base-button mode="transparent" class="burger__button" @click="openMenu">
      <img src="@/assets/images/conference/burger.svg" />
    </base-button>

    <div v-if="isMenuOpen" class="burger__menu">
      <div class="burger__title">
        <span>MENU </span>
        <base-button mode="transparent" @click="closeMenu">
          <img src="@/assets/images/conference/icons/close.svg" />
        </base-button>
      </div>

      <div v-for="item in menuItems" :key="item.id" @click="closeMenu">
        <router-link
          :is="item.to ? 'router-link' : 'a'"
          :to="item.to ? item.to : ''"
          class="burger__item text-decoration-none"
          :href="item.href"
          :target="item.href ? '_blank' : ''"
        >
          <img :src="item.icon" /> {{ item.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import BaseButton from '@/js/components/conference/components/ui/BaseButton.vue'

  export default {
    name: 'BurgerButton',
    components: { BaseButton },
    data: () => ({
      isMenuOpen: false
    }),
    computed: {
      ...mapGetters('conference', ['activeTicket']),
      menuItems() {
        return [
          {
            id: 1,
            title: 'Мой билет',
            icon: new URL("@/assets/images/conference/menu/ticket.svg", import.meta.url).href,
            to: {
              name: 'ConferenceTicket',
              params: {
                ticketName: this.activeTicket,
                chatUuid: this.$route.params.chatUuid
              },
              query: this.$route.query
            }
          },
          {
            id: 2,
            title: 'Мои тренинги',
            icon: new URL("@/assets/images/conference/menu/trainings.svg", import.meta.url).href,
            to: {
              name: 'ConferenceProducts',
              params: {
                chatUuid: this.$route.params.chatUuid
              },
              query: this.$route.query
            }
          },
          {
            id: 3,
            title: 'Трансляция',
            icon: new URL("@/assets/images/conference/menu/translation.svg", import.meta.url).href,
            href: 'https://businessconf.getcourse.ru/bootcamp_veb'
          },
          {
            id: 4,
            title: 'Спикеры и расписание',
            icon: new URL("@/assets/images/conference/menu/speakers.svg", import.meta.url).href,
            to: {
              name: 'ConferenceSpeaker',
              params: {
                chatUuid: this.$route.params.chatUuid
              },
              query: this.$route.query
            }
          },
          {
            id: 5,
            title: 'Чат буткемпа',
            icon: new URL("@/assets/images/conference/menu/chat.svg", import.meta.url).href,
            href: 'https://t.me/+T6exunwqiCNmYzUy'
          },
          {
            id: 6,
            title: 'Поддержка',
            icon: new URL("@/assets/images/conference/menu/support.svg", import.meta.url).href,
            to: {
              name: 'ConferenceSupport',
              params: {
                chatUuid: this.$route.params.chatUuid
              },
              query: this.$route.query
            }
          }
        ]
      }
    },
    methods: {
      openMenu() {
        this.isMenuOpen = true
        document.body.style.overflow = 'hidden'
      },
      closeMenu() {
        this.isMenuOpen = false
        document.body.style.overflow = 'auto'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .burger {
    position: relative;
    z-index: 1;
    font-family: e-Ukraine, sans-serif;

    &__button {
      position: absolute;
      top: 35px;
      right: 30px;
    }

    &__menu {
      padding: 0 20px;
      position: absolute;
      overflow-y: auto;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: #08090a;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    &__title {
      padding: 30px 10px 22px 14px;
      color: #72747a;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.4px;
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 22px;
      padding: 22px 25px;
      color: #fff;
      font-size: 14px;
      font-weight: 300;
    }

    .burger__item.router-link-active {
      border-radius: 10px;
      background: #272a31;
    }
  }
</style>
