export const authTokenMixin = {
  computed: {
    authToken() {
      return localStorage.getItem('token')
    },

    authTokenType() {
      return localStorage.getItem('token_type')
    }
  }
}
