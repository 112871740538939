export const chatLandingDeepLinkRoutes = [
  {
    path: 'chat/:chat_id/promotion/landings/store',
    component: () => import('@/js/components/chat/settings/landing/StoreChatLandingDeepLink.vue'),
    name: 'StoreChatLandingDeepLink',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/promotion/landings/:item_id/update',
    component: () => import('@/js/components/chat/settings/landing/UpdateChatLandingDeepLink.vue'),
    name: 'UpdateChatLandingDeepLink',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
