import {
    App
} from '@capacitor/app'

import {
    Browser
} from '@capacitor/browser'

import {
    mapState
} from 'vuex'

import {
    fetchMixin
} from '@/js/mixins/fetch/fetchMixin'

import {
    MobileModel
} from '@/js/models/mobile/MobileModel'

export const capacitorOpenUrlMixin = {
    methods: {
        capacitorOpenUrl(url) {
            let parser = document.createElement('a')

            parser.href = url

            const WEB_URLS = [
                '/landing/',
                '/homework/',
                '/product/',
                '/bot/',
                '/webinar/',
                '/api/',
                '/token/'
            ]

            for (const prefix of WEB_URLS) {
                if (parser.pathname.startsWith(prefix)) {
                    return Browser.open({
                        url: url
                    })
                }
            }

            let path = parser.pathname + parser.search + parser.hash

            if (path) {
                this.$router.push({
                    path: path,
                    query: {
                        force: true
                    }
                })
            }
        }
    }
}

export const capacitorCheckVersionMixin = {
    mixins: [fetchMixin],
    data: () => ({
        capacitorServerVersion: null,
        capacitorCurrentVersion: 1
    }),
    methods: {
        async capacitorCheckVersion() {
            this.capacitorServerVersion = await this.fetchData(
                MobileModel.URL_VERSION
            )

            if (this.capacitorCurrentVersion >= +this.capacitorServerVersion) return

            this.$confirm(
                this.$t('mobile.new-version.description'),
                this.$t('mobile.new-version.title'), {
                    type: 'info',
                    center: true,
                    showClose: false,
                    showCancelButton: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    closeOnHashChange: false,
                    customClass: 'width-300px',
                    confirmButtonText: this.$t('main-words.update'),
                    beforeClose: (action, instance, done) => {
                        window.open(
                            `http://play.google.com/store/apps/details?id=com.winwinbot.app`
                        )
                    }
                }
            )
        }
    }
}

export const capacitorInitMixin = {
    mixins: [capacitorOpenUrlMixin, capacitorCheckVersionMixin],
    computed: {
        ...mapState(['winwinbotMobile'])
    },
    methods: {
        capacitorInit() {
            if (!this.winwinbotMobile) return

            App.addListener('appStateChange', ({isActive}) => {
                this.capacitorCheckVersion()
            })

            App.addListener('appUrlOpen', ({url}) => {
                this.capacitorOpenUrl(url)
            })
        }
    }
}
