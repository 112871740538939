export const chatProposalRoutes = [
  {
    path: 'chat/:chat_id/payment/proposals/store',
    component: () => import('@/js/components/chat/content/proposal/StoreChatProposal.vue'),
    name: 'StoreChatProposal',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/payment/proposals/:proposal_id/:page',
    component: () => import('@/js/components/chat/content/proposal/IndexChatProposalTab.vue'),
    name: 'IndexChatProposalTab',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
