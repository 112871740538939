import { i18n } from '@/js/plugins/i18n'

export class ChatMessageTriggerModel {
  static MAX_COUNT = 10

  static QUESTIONARY_STATUS_FINISHED = 1

  static QUESTIONARY_STATUS_TIMEOUT = 2

  static QUESTIONARY_STATUS_FINISHED_OR_TIMEOUT = 3

  static get questionaryStatuses() {
    return [
      {
        value: this.QUESTIONARY_STATUS_FINISHED,
        label: i18n.t('models.chat.message.trigger.questionary.finished')
      },
      {
        value: this.QUESTIONARY_STATUS_TIMEOUT,
        label: i18n.t('models.chat.message.trigger.questionary.timeout')
      }
    ]
  }

  constructor(props) {
    this.form = {
      id: null,
      type_id: 1,
      event_id: null,

      delay: null,
      delay_days: null,
      delay_activity: null,

      prop: null,
      prop_value: null,
      prop_type_id: null,
      prop_compare_type_id: null,

      trigger_message_id: null,
      button_id: null,
      link_id: null,

      questionary_id: null,
      questionary_status_id: null,
      questionary_statuses: [
        ChatMessageTriggerModel.QUESTIONARY_STATUS_FINISHED
      ],
      stage_id: null,

      homework_id: null,
      homework_result_status_id: null,

      product_id: null,
      product_recurrent_status_id: null,

      group_id: null,
      group_send_only_new: false,
      has_recurrent: false,
      has_payment: false,

      time: null,
      time_server: null,
      timezone: null
    }
  }
}
