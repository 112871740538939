import {
    i18n
} from '@/js/plugins/i18n'

import {
    ChatModel
} from '@/js/models/chat/ChatModel'
import {
    ChatLangModel
} from '@/js/models/chat/ChatLangModel'

export class ChatLandingDeepLinkModel {
    static url(chat_id) {
        return `${ChatModel.URL}/${chat_id}/landing-deeps`
    }

    static PREFIX_CUSTOM_ID = 'wwb_custom_id_'
    static PREFIX_YM_ID = 'ym_id_'
    static PREFIX_GA_ID = 'ga_id_'

    static FILLABLE = [
        'name',
        'description_code',
        'text_button',
        'lang_id',
        'folder_id',
        'folder_webinar_session_uuid',
        'enable_short_description',
        'enable_subs_count',
        'video_header'
    ]

    constructor() {
        this.form = {
            id: null,
            folder_id: null,
            folder_webinar_session_uuid: null,
            name: null,
            image_header: null,
            video_header: null,
            has_video_header: false,
            has_image_header: false,
            image_bg: null,
            description: '',
            description_code: null,
            text_button: null,
            lang_id: ChatLangModel.getIdByName(i18n.locale),
            enable_short_description: true,
            enable_subs_count: false,
            params: [],
            is_default: false
        }
    }
}
