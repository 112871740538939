import { i18n } from '@/js/plugins/i18n'
import { ChatModel } from '@/js/models/chat/ChatModel'

export class ChatReferralModel {
  static url(chatId) {
    return `${ChatModel.URL}/${chatId}/referral`
  }

  static BOOLEAN_KEYS = [
    'active',
    'require_code',
    'is_ask_without_code',
    'is_visible_volume'
  ]

  constructor(props) {
    this.form = {
      active: false,
      require_code: false,
      is_ask_without_code: true,
      is_visible_volume: false,

      level_count: 1,

      has_hidden_level: false,
      hidden_level_count: null,

      limit: null,
      has_limit: false,

      info: {
        skip_button: i18n.t('bot.settings.referral.skip-button'),

        message: {
          text: '',
          json: {},
          html: i18n.t('bot.settings.referral.welcome-message', {
            button: i18n.t('bot.settings.referral.skip-button')
          })
        },

        reject_text: i18n.t('bot.settings.referral.reject-text'),
        success_text: i18n.t('bot.settings.referral.success-text'),

        limit_text: i18n.t('bot.settings.referral.limit-text'),

        has_filter: false,
        filter: {
          prop: null,
          options: []
        }
      }
    }
  }
}
