import { defineStore } from 'pinia';
import { useWebApp } from "@/js/store/pinia/webapp/WebApp.js";
import { Trans } from "@/js/plugins/translation.js";

export const useWebAppChat = defineStore('webAppChatStore', {
    state: () => ({
        id: null,
        uuid: null,
        name: null,
        image: null,
        lang_id: null,
        has_partners_program: null,
        platform_url: null,
        username: null,
        isInit: false,
    }),
    actions:
    {
        async init(chatId)
        {
            if(this.id && this.id === chatId)
            {
                Trans.changeLanguage(
                    this.languageById(this.lang_id)
                );
                return;
            }

            let webApp = useWebApp();

            const data = await webApp.fetch('chat', {
                chat_id: chatId
            });
            await this.update(data);

            Trans.changeLanguage(
                this.languageById(this.lang_id)
            );

            this.isInit = true;
        },

        async update(data)
        {
            Object.keys(data).forEach((key) => {
                if (key in this) {
                    this[key] = data[key];
                }
            });
        },

        languageById(id)
        {
            if(id === undefined || id === null) return 'en';

            const languages = {
                1: 'ru',
                2: 'en',
                3: 'hu',
                4: 'es',
                27: 'hi'
            }
            return languages[id] ?? 'en';
        },

        languageLocaleById(id)
        {
            if(id === undefined || id === null) return 'en-US';

            const languages = {
                1: 'ru-RU',
                2: 'en-US',
            }
            return languages[id] ?? 'en-US';
        }
    },
});
