<template>
  <el-avatar
    :src="src ? src : avatar"
    :size="size"
    :style="isChain ? 'height: 0' : ''"
    class="background-unset flex-shrink-0"
  >
    <img src="@/assets/icons/user/user.png" style="width: inherit" />
  </el-avatar>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'UserAvatar',
    props: {
      src: {
        required: false,
        type: String
      },
      size: {
        required: false
      },
      isChain: {
        required: false,
        type: Boolean
      }
    },
    computed: {
      ...mapState('auth', ['avatar'])
    }
  }
</script>
