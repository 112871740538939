import { ChatLandingDeepLinkModel } from '@/js/models/chat/deeplink/ChatLandingDeepLinkModel'

const initialState = () => {
  return {
    chat_landing_deep_links: [],
    chat_landing_deep_links_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_landing_deep_links_loaded = isLoaded
    },

    setLandingDeepLinks(state, landing_deep_links) {
      state.chat_landing_deep_links = []

      landing_deep_links.forEach(({ id, name }) =>
        state.chat_landing_deep_links.push({ id: id, name: name })
      )
    },

    deleteLandingDeepLink(state, id) {
      let index = state.chat_landing_deep_links.findIndex(
        (item) => item.id === id
      )

      if (index === -1) return

      state.chat_landing_deep_links.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchLandingDeepLinks({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatLandingDeepLinkModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setLandingDeepLinks', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetLandingDeepLinks')

            return reject(error)
          })
      })
    },

    setLandingDeepLinks({ commit }, landing_deep_links) {
      commit('setLandingDeepLinks', landing_deep_links)

      commit('setLoaded', true)
    },

    deleteLandingDeepLink({ commit }, id) {
      commit('deleteLandingDeepLink', id)
    },

    resetLandingDeepLinks({ commit }) {
      commit('reset')
    }
  }
}
