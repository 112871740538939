<template>
    <div v-if="webApp.isPreloader || !webApp.isInitFull" class="absolute z-50 top-0 left-0 w-full  h-[100vh]">
        <div class="min-h-screen flex items-center justify-center">
            <div class="relative inline-flex items-center justify-center w-[100px] h-[100px]">
                <div class="absolute w-[100px] h-[100px] border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                <img
                    v-if="isChatImage"
                    :src="chat?.image"
                    :alt="chat?.name"
                    class="flex-initial w-[85px] h-[85px] items-center justify-center rounded-full object-cover m-0"
                />
            </div>
        </div>
    </div>
</template>

<script>

import {useWebApp} from "@/js/store/pinia/webapp/WebApp.js";

export default {
    name: "WebAppPreloader",
    computed:
    {
        webApp() {
            return useWebApp();
        },
        chat() {
            return this.webApp.chat;
        },
        isChatImage() {
            return this.chat.image !== undefined && this.chat.image !== null && this.chat.image !== 'https://storage.winwinbot.com/'
        }
    }
}
</script>

<style scoped>

</style>
