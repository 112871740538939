import {
    i18n
} from '@/js/plugins/i18n'
import {
    Model
} from '@/js/models/Model'

export class UploadFileTypeModel extends Model {
    static PHOTO_TYPES = ['.jpeg', '.jpg', '.png', '.gif', '.bmp']

    static VIDEO_TYPES = ['.ogg', '.gif', '.mp4', '.mp2']

    static AUDIO_TYPES = ['.mp3', '.m4a']

    static VOICE_TYPES = ['.ogg']

    static FILE_TYPES_WITH_PREVIEW = [
        'ai',
        'avi',
        'css',
        'doc',
        'dss',
        'eps',
        'fcp',
        'fla',
        'gif',
        'html',
        'ihdd',
        'iso',
        'jar',
        'jpg',
        'm4p',
        'mov',
        'mp3',
        'mp4',
        'pdf',
        'php',
        'png',
        'ppt',
        'ps',
        'psd',
        'swf',
        'tiff',
        'txt',
        'wma',
        'xls',
        'zip'
    ]

    static SUB_PROFILE_TYPES = ['.vcf']

    static MAX_SIZE = 100
    static MAX_SIZE_TG_PHOTO = 5
    static MAX_SIZE_TG_VOICE = 1
    static MAX_SIZE_TG = 100

    static WRONG_SYMBOLS = ['%', '+', '?', '/', '#', ':']

    static PHOTO = 1

    static FILE = 2

    static VIDEO = 3

    static AUDIO = 4

    static CONTACT = 5

    static LOCATION = 6

    static VOICE = 7

    static VIDEO_NOTE = 8

    static SUB_AVATAR = 9

    static SUB_PROFILE = 10

    static get types() {
        return [{
            label: i18n.t('main-words.photo'),
            value: this.PHOTO
        }, {
            label: i18n.t('main-words.file'),
            value: this.FILE
        }, {
            label: i18n.t('main-words.video'),
            value: this.VIDEO
        }, {
            label: i18n.t('main-words.audio'),
            value: this.AUDIO
        }, {
            label: i18n.t('main-words.voice'),
            value: this.VOICE
        }, {
            label: i18n.t('main-words.video-note'),
            value: this.VIDEO_NOTE
        }, {
            label: i18n.t('main-words.sub-avatar'),
            value: this.SUB_AVATAR
        }, {
            label: i18n.t('main-words.contact-card'),
            value: this.SUB_PROFILE
        }, {
            label: i18n.t('main-words.geo-location'),
            value: this.LOCATION
        }]
    }

    static getFileUrl(name) {
        let ext = name.substr(name.lastIndexOf('.')).toLowerCase()

        if (this.PHOTO_TYPES.includes(ext)) {
            return name
        }

        ext = ext.substr(1)

        return this.FILE_TYPES_WITH_PREVIEW.includes(ext) ?
            `/icons/chat/upload/${ext}-file.svg` :
            `/icons/chat/upload/blank-file.svg`
    }

    static acceptTypes(typeId, joinKey = ',') {
        typeId = +typeId

        switch (typeId) {
            case this.PHOTO:
                return this.PHOTO_TYPES.join(joinKey)
            case this.VIDEO:
                return this.VIDEO_TYPES.join(joinKey)
            case this.AUDIO:
                return this.AUDIO_TYPES.join(joinKey)
            case this.VOICE:
                return this.VOICE_TYPES.join(joinKey)
            case this.SUB_PROFILE:
                return this.SUB_PROFILE_TYPES.join(joinKey)
            default:
                return ''
        }
    }

    static isWrongFileName(name = null) {
        if (!name) {
            return false
        }

        for (let index = 0; index < this.WRONG_SYMBOLS.length; index++) {
            if (name.includes(this.WRONG_SYMBOLS[index])) {
                return this.WRONG_SYMBOLS[index]
            }
        }

        return false
    }

    static hasText(typeId) {
        return [this.VOICE, this.VIDEO_NOTE].includes(typeId)
    }
}
