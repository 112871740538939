<template>
  <el-menu
    id="main-header-laptop"
    ref="menu"
    mode="horizontal"
    class="py-2 d-flex align-items-center"
    router
  >
    <el-row
      class="flex-grow-1 font-size-24 line-height-28px font-weight-500 text-truncate pr-3"
    >
      {{ chat_name }}
    </el-row>

    <el-row class="d-inline-flex align-items-center text-truncate">
      <user-avatar
        v-loading="loading"
        :size="36"
        :src="sub.avatar"
        class="mr-2 flex-shrink-0"
      />

      <el-row class=" text-truncate">
        {{ ChatSubscriberModel.getName(sub) }}
      </el-row>
    </el-row>
  </el-menu>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  import { fetchMixin } from '@/js/mixins/fetch/fetchMixin'

  import UserAvatar from '@/js/components/user/components/UserAvatar.vue'

  import { ChatSubscriberModel } from '@/js/models/chat/subscriber/ChatSubscriberModel'

  export default {
    name: 'MainHeaderChat',
    components: {
      UserAvatar
    },
    mixins: [fetchMixin],
    props: {
      params: {
        required: true,
        type: Object
      }
    },
    data: () => ({
      ChatSubscriberModel,
      sub: new ChatSubscriberModel().form
    }),
    computed: {
      ...mapState('chat', ['chat_name']),

      url() {
        return ChatSubscriberModel.urlPublic(
          this.params.chat_uid,
          this.params.sub_uid
        )
      }
    },
    created() {
      this.fetchSub()
    },
    methods: {
      ...mapActions('chat', ['setCurrentChat']),
      ...mapActions('subscriber', ['setCurrentSubscriber']),

      async fetchSub() {
        this.sub = await this.fetchData(this.url)

        this.setCurrentChat(this.sub.chat)

        this.setCurrentSubscriber(this.sub)
      }
    }
  }
</script>

<style scoped></style>
