import { Model } from '@/js/models/Model'

import { i18n } from '@/js/plugins/i18n'

export class ChatProductAttemptTypeModel extends Model {
  static HOUR = 1

  static DAY = 2

  static WEEK = 3
}
