export const adminWpRoutes = [
  {
    path: 'admin-wp/promocodes',
    component: () => import('@/js/components/admin/wp/IndexAdminWpPromocode.vue'),
    name: 'IndexAdminWpPromocode',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin-wp/users/export',
    component: () => import('@/js/components/admin/wp/export/ExportAdminWp.vue'),
    name: 'ExportAdminWp',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  }
]
