import { ChatModel } from '@/js/models/chat/ChatModel'

export class ChatVideoModel {
  static URL = 'videos'

  static HOST = 'https://uploader.kinescope.io/'

  static HOST_JS = 'https://app.kinescope.io/assets/uploader/api.js'

  static url(chat_id) {
    return `${ChatModel.URL}/${chat_id}/videos`
  }

  constructor() {
    this.searchForm = {
      folder_id: null
    }

    this.form = {
      id: null,
      uuid: null,
      name: null,
      url: null,
      url_preview: null,
      chat_id: null,
      template_parent_id: null,
      is_private: false,
      is_inside: 0,
      filter_id: null,
      folder_id: null,
      actions: []
    }
  }

  static getName(item) {
    return item.name.length < 35 ? item.name : item.name.substr(0, 35) + '...'
  }

  static getEmbed(item, params) {
    let code = ''

    code = params.fix
      ? `<iframe width="${params.size.width}" height="${params.size.height}"`
      : '<div style="position:relative; padding: 0 0 56.25% 0; height:0; overflow:hidden;"><iframe style="position:absolute; top:0; left:0; width:100%; height:100%;"'

    let url = params.show_title ? item.url : `${item.url}?title=0`

    code += ` src="${url}" frameborder="0" allow="autoplay; fullscreen" ></iframe>`

    return params.fix ? code : `${code} </div>`
  }
}
