import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/js/store/index'
import { i18n } from '@/js/plugins/i18n'
import { mainRoutes } from '@/js/routes/mainRoutes'
import { Trans } from '@/js/plugins/translation'

const LANG_PATH = '/:lang'

function isRequiredSubscription(to) {
    return (
        store.state.aunite &&
        store.state.auth.is_logged_in &&
        typeof store.state.auth.subscription === 'object' &&
        !store.state.auth.subscription.id &&
        to.name !== 'UserSubscription' &&
        to.name !== 'Logout'
    )
}

function isDenyAdmin(to) {
    return (
        to.matched.some((route) => route.meta.requireAdmin) &&
        !store.state.auth.is_admin
    )
}

function isDenyWL(to) {
    return (
        to.matched.some((route) => route.meta.requireAdminWhiteLabel) &&
        !store.state.auth.is_admin_wl &&
        !store.state.auth.is_admin
    )
}

function isDenyAdminSupport(to) {
    return (
        to.matched.some((route) => route.meta.requireAdminSupport) &&
        !store.state.auth.is_admin_support &&
        !store.state.auth.is_admin_user_goal &&
        !store.state.auth.is_admin
    )
}

function isDenyAdminUserGuide(to) {
    return (
        to.matched.some((route) => route.meta.requireAdminUserGuide) &&
        !store.state.auth.is_admin_user_guide &&
        !store.state.auth.is_admin
    )
}

function isDenyAuth(to) {
    return (
        to.matched.some((route) => route.meta.requireAuth) &&
        !store.state.auth.is_logged_in
    )
}

function isDenyGuest(to) {
    return (
        to.matched.some((route) => route.meta.requireGuest) &&
        store.state.auth.is_logged_in
    )
}

function isDenyToken(to) {
    return (
        to.matched.some((route) => route.meta.requireToken) &&
        store.state.auth.is_logged_in &&
        !to.query.hasOwnProperty('token')
    )
}

function isDenyPartnership(to) {
    return (
        to.matched.some((route) => route.meta.requirePartnership) &&
        (!store.state.winwinbot || i18n.locale === 'en')
    )
}

function isDenyGoalQuestions(to) {
    return !!store.state.auth.has_goal_questions && to.name !== 'IndexChat'
}

function isDenyWp(to) {
    return (
        to.matched.some((route) => route.meta.requireNoWp) && store.state.auth.is_wp
    )
}

function isDenyChat(to) {
    return (
        to.matched.some(
            (route) => route.path !== LANG_PATH && !route.meta.showBotName
        ) && store.state.chat.chat_id
    )
}

function redirectToLogin(next, lang) {
    next({
        name: 'Login'
    })
}

function redirectChats(next, lang) {
    next({
        name: 'IndexChat',
        params: {
            lang: lang
        }
    })
}

function redirectToSubscriptionStore(next, lang) {
    next({
        name: 'UserSubscription',
        params: {
            lang: lang
        }
    })
}

Vue.use(VueRouter)

// Создаем экземпляр маршрутизатора
const router = new VueRouter({
    mode: 'history',
    routes: mainRoutes,
    scrollBehavior(to, from, savedPosition) {
        if (to.matched.some((m) => m.meta.noScroll)) {
            return { x: 0, y: 0 }
        }
        return savedPosition ? savedPosition : { x: 0, y: 0 }
    }
})

// Если необходимо, сохраняем экземпляр в глобальной области (на случай использования в Sentry и т.д.)
global.router = router

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(error => {
        let text = error.toString()
        if (
            text.includes('Redirected when going from') ||
            text.includes('Navigation cancelled from') ||
            text.includes('NavigationDuplicated')
        ) {
            return
        }
        throw error
    })
}

router.beforeEach((to, from, next) => {
    if (to.path && to.query.force === true) {
        return next()
    }

    if (to.name === 'RegisterRefRU') {
        to.params.lang = 'ru'
        return next()
    }

    if (to.meta.hasOwnProperty('isPublic') && to.meta.isPublic) {
        return next()
    }

    const lang = to.params.lang

    if (!Trans.isLangSupported(lang)) {
        return next(Trans.getUserSupportedLang())
    }

    if (isRequiredSubscription(to)) {
        return redirectToSubscriptionStore(next, lang)
    }

    if (
        isDenyAuth(to) ||
        isDenyAdmin(to) ||
        isDenyAdminSupport(to) ||
        isDenyAdminUserGuide(to) ||
        isDenyWL(to) ||
        isDenyWp(to)
    ) {
        return redirectToLogin(next, lang)
    }

    if (isDenyChat(to)) {
        store.dispatch('chat/resetChat')
        store.dispatch('auth/resetChatAdmin')
        next({
            name: to.name,
            params: to.params
        })
        return
    }

    if (
        isDenyGuest(to) ||
        isDenyToken(to) ||
        isDenyPartnership(to) ||
        isDenyGoalQuestions(to)
    ) {
        return redirectChats(next, lang)
    }

    return Trans.changeLanguage(lang).then(() => next())
})

// Экспортируем экземпляр маршрутизатора
export default router
