const initialState = () => {
    return {
        chat_subscriber_id: null,
        chat_subscriber_first_name: null,
        chat_subscriber_last_name: null,
        chat_subscriber_username: null,
        chat_subscriber_avatar: null,
        chat_homework_id: null,
        chat_homework_result_id: null
    }
}

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        setHomeworkId(state, data) {
            state.chat_homework_id = data
        },

        setHomeworkResultId(state, data) {
            state.chat_homework_result_id = data
        },

        setSubscriber(state, data) {
            state.chat_subscriber_id = data.id
            state.chat_subscriber_first_name = data.first_name
            state.chat_subscriber_last_name = data.last_name
            state.chat_subscriber_username = data.username
            state.chat_subscriber_avatar = data.avatar
        },

        reset(state) {
            const init_state = initialState()

            for (let key in init_state) {
                state[key] = init_state[key]
            }
        }
    },
    actions: {
        setHomeworkId({commit}, data) {
            commit('setHomeworkId', data)
        },

        setHomeworkResultId({commit}, data) {
            commit('setHomeworkResultId', data)
        },

        setCurrentSubscriber({commit}, data) {
            commit('setSubscriber', data)
        },

        reset({commit}) {
            commit('reset')
        }
    }
}
