import {
    Model
} from '@/js/models/Model'

export class ChatLangModel extends Model {
    static RU = 1
    static EN = 2
    static HU = 3
    static ES = 4
    static ID = 5
    static MS = 6
    static DK = 7
    static DE = 8
    static EU = 9
    static MX = 10
    static FR = 11
    static IT = 12
    static NL = 13
    static NO = 14
    static PL = 15
    static BR = 16
    static PT = 17
    static RO = 18
    static SV = 19
    static VI = 20
    static TR = 21
    static HE = 22
    static TH = 23
    static KO = 24
    static ZH = 25
    static JP = 26
    static HI = 27

    static get types() {
        return [
            {
                label: 'en',
                locale: 'en-US',
                title: 'English (US)',
                value: this.EN
            },
            {
                label: 'br',
                title: 'Português (Brasil)',
                value: this.BR
            },
            {
                label: 'de',
                title: 'Deutsch',
                value: this.DE
            },
            {
                label: 'dk',
                title: 'Dansk',
                value: this.DK
            },
            {
                label: 'es',
                title: 'Español (España)',
                value: this.ES
            },
            {
                label: 'eu',
                title: 'English (EU)',
                value: this.EU
            },
            {
                label: 'fr',
                title: 'Français (France)',
                value: this.FR
            },
            {
                label: 'he',
                title: 'עברית',
                value: this.HE
            },
            {
                label: 'hi',
                title: 'हिन्दी',
                value: this.HI
            },
            {
                label: 'hu',
                title: 'Magyar',
                value: this.HU
            },
            {
                label: 'id',
                title: 'Bahasa Indonesia',
                value: this.ID
            },
            {
                label: 'it',
                title: 'Italiano',
                value: this.IT
            },
            {
                label: 'jp',
                title: '日本語',
                value: this.JP
            },
            {
                label: 'ko',
                title: '한국어',
                value: this.KO
            },
            {
                label: 'ms',
                title: 'Bahasa Malaysia',
                value: this.MS
            },
            {
                label: 'mx',
                title: 'Español (Latinoamérica)',
                value: this.MX
            },
            {
                label: 'nl',
                title: 'Nederlands',
                value: this.NL
            },
            {
                label: 'no',
                title: 'Norsk (Bokmål)',
                value: this.NO
            },
            {
                label: 'pl',
                title: 'Polski',
                value: this.PL
            },
            {
                label: 'pt',
                title: 'Português (Portugal)',
                value: this.PT
            },
            {
                label: 'ro',
                title: 'Română',
                value: this.RO
            },
            {
                label: 'ru',
                locale: 'ru-RU',
                title: 'Русский',
                value: this.RU
            },
            {
                label: 'sv',
                title: 'Svenska',
                value: this.SV
            },
            {
                label: 'tr',
                title: 'Türkçe',
                value: this.TR
            },
            {
                label: 'th',
                title: 'ภาษาไทย',
                value: this.TH
            },
            {
                label: 'vi',
                title: 'Tiếng Việt',
                value: this.VI
            },
            {
                label: 'zh',
                title: '中文 (简体)',
                value: this.ZH
            }
        ]
    }

    static getTitle(id)
    {
        let type = this.types.find((item) => item.value === +id)
        return type ? type.title : ''
    }

    static getIdByName(name)
    {
        let type = this.types.find((item) => item.label === name.toLowerCase())
        return type ? type.value : null
    }

    static getLocaleById(id) {
        let type = this.types.find((item) => item.value === +id);
        return type && type.locale ? type.locale : 'en-US';
    }

    static getLabelById(id) {
        let type = this.types.find((item) => item.value === +id);
        return type && type.label ? type.label : 'en';
    }
}
