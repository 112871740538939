export const adminSuperVideoRoutes = [
  {
    path: 'admin/video',
    component: () => import('@/js/components/admin/AdminVideo.vue'),
    name: 'AdminVideo',
    meta: {
      requireAdmin: true,
      requireAuth: true
    }
  },
  {
    path: 'admin/video/link/:token',
    component: () => import('@/js/components/admin/AdminVideoLink.vue'),
    name: 'AdminVideoLink',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  }
]
