export const chatWebinarRoutes = [
  {
    path: 'chat/:chat_id/promotion/webinars/store',
    component: () => import('@/js/components/chat/content/webinar/StoreChatWebinar.vue'),
    name: 'StoreChatWebinar',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  },
  {
    path: 'chat/:chat_id/promotion/webinars/:item_id/update',
    component: () => import('@/js/components/chat/content/webinar/UpdateChatWebinar.vue'),
    name: 'UpdateChatWebinar',
    meta: {
      requireAuth: true,
      showBotName: true
    },
    props: true
  }
]
