import { ChatVideoModel } from '@/js/models/chat/video/ChatVideoModel'

const initialState = () => {
  return {
    chat_videos: [],
    chat_videos_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_videos_loaded = isLoaded
    },

    setVideos(state, videos) {
      state.chat_videos = []

      videos.forEach(({ id, name, url }) =>
        state.chat_videos.push({ id: id, name: name, url: url })
      )
    },

    addVideo(state, video) {
      if (state.chat_videos.find((x) => x.id === video.id)) return

      state.chat_videos.unshift(video)
    },

    deleteVideo(state, id) {
      let index = state.chat_videos.findIndex((item) => item.id === id)

      if (index === -1) return

      state.chat_videos.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchVideos({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatVideoModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setVideos', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetVideos')

            return reject(error)
          })
      })
    },

    setVideos({ commit }, videos) {
      commit('setVideos', videos)

      commit('setLoaded', true)
    },

    addVideo({ commit }, video) {
      commit('addVideo', video)
    },

    deleteVideo({ commit }, id) {
      commit('deleteVideo', id)
    },

    resetVideos({ commit }) {
      commit('reset')
    }
  }
}
