export const adminSuperPromocodeRoutes = [
  {
    path: 'admin/promocodes',
    component: () => import('@/js/components/admin/super/promocode/IndexAdminPromocode.vue'),
    name: 'IndexAdminPromocode',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/promocodes/store',
    component: () => import('@/js/components/admin/super/promocode/StoreAdminPromocode.vue'),
    name: 'StoreAdminPromocode',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  },
  {
    path: 'admin/promocodes/:item_id/update',
    component: () => import('@/js/components/admin/super/promocode/UpdateAdminPromocode.vue'),
    name: 'UpdateAdminPromocode',
    meta: {
      requireAdmin: true,
      requireAuth: true
    },
    props: true
  }
]
