import { ChatWebinarModel } from '@/js/models/chat/webinar/ChatWebinarModel'

const initialState = () => {
  return {
    chat_webinars: [],
    chat_webinars_loaded: false,
    chat_webinars_sessions_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_webinars_loaded = isLoaded
    },

    setSessionsLoaded(state, isLoaded) {
      state.chat_webinars_sessions_loaded = isLoaded
    },

    setWebinars(state, webinars) {
      state.chat_webinars = []

      webinars.forEach(({ id, name, sessions }) =>
        state.chat_webinars.push({ id: id, name: name, sessions: sessions })
      )
    },

    deleteWebinar(state, id) {
      let index = state.chat_webinars.findIndex((item) => item.id === id)

      if (index === -1) return

      state.chat_webinars.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchWebinars({ commit, dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatWebinarModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setWebinars', data.items)

            if (params.with_sessions) {
              commit('setSessionsLoaded', true)
            }

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetWebinars')

            return reject(error)
          })
      })
    },

    setWebinars({ commit }, webinars) {
      commit('setWebinars', webinars)

      commit('setLoaded', true)
    },

    deleteWebinar({ commit }, id) {
      commit('deleteWebinar', id)
    },

    resetWebinars({ commit }) {
      commit('reset')
    }
  }
}
