import { ChatCourseModel } from '@/js/models/chat/course/ChatCourseModel'

export class ChatCourseLessonModel {
  static url(chatId, courseId) {
    return `${ChatCourseModel.url(chatId)}/${courseId}/lessons`
  }

  static urlForWebApp() {
      return "course/section/lesson";
  }

  constructor(typeId = null) {
    this.searchForm = {
      section_id: null
    }

    this.form = {
      id: Date.now(),
      name: null,
      type_id: typeId,
      section_id: null,
      landing_id: null,
      homework_id: null,
      video_id: null,
      duration_text: null
    }
  }
}
