import { DataTypeModel } from '@/js/models/dataType/DataTypeModel'

export class QuestionAnswerFieldModel {
  constructor(hasValue = false) {
    this.form = {
      prop: null,
      type_id: DataTypeModel.TEXT
    }

    if (hasValue) {
      this.form.value = null
    }
  }
}
