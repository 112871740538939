import {
    Model
} from '@/js/models/Model'
import {
    MoneyReplenishmentAggregatorModel
} from '@/js/models/money/MoneyReplenishmentAggregatorModel'

export class MoneyReplenishmentCurrencyModel extends Model {
    static RUB = 1
    static USD = 2
    static EUR = 3
    static UAH = 4
    static GBP = 5
    static CZK = 6
    static BYN = 7
    static KZT = 8
    static AZN = 9
    static CHF = 10
    static CAD = 11
    static PLN = 12
    static SEK = 13
    static TRY = 14
    static CNY = 15
    static INR = 16
    static BRL = 17
    static ZAR = 18
    static UZS = 19
    static BGN = 20
    static RON = 21
    static AUD = 22
    static HKD = 23
    static GEL = 24
    static KGS = 25
    static AMD = 26
    static AED = 27
    static XTR = 28

    static get types() {
        return [{
            label: 'AED',
            symbol: 'د.إ',
            value: this.AED,
            aggregator_ids: [
                MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                MoneyReplenishmentAggregatorModel.STRIPE,
                MoneyReplenishmentAggregatorModel.CASH
            ]
        },
            {
                label: 'AMD',
                symbol: '֏',
                value: this.AMD,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'AUD',
                symbol: 'A$',
                value: this.AUD,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.STRIPE,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'AZN',
                symbol: '₼',
                value: this.AZN,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'BGN',
                symbol: 'лв',
                value: this.BGN,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.STRIPE,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'BRL',
                symbol: 'R$',
                value: this.BRL,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.STRIPE,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'BYN',
                symbol: 'Br',
                value: this.BYN,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.BE_PAID,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'CAD',
                symbol: 'C$',
                value: this.CAD,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.STRIPE,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'CHF',
                symbol: 'Fr',
                value: this.CHF,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.STRIPE,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'CNY',
                symbol: '¥',
                value: this.CNY,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'CZK',
                symbol: 'Kč',
                value: this.CZK,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.FONDY,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'EUR',
                symbol: '€',
                value: this.EUR,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.PRODAMUS,
                    MoneyReplenishmentAggregatorModel.PRODAMUS_NATIVE,
                    MoneyReplenishmentAggregatorModel.ROBOKASSA,
                    MoneyReplenishmentAggregatorModel.FONDY,
                    MoneyReplenishmentAggregatorModel.MONETS_ME,
                    MoneyReplenishmentAggregatorModel.STRIPE,
                    MoneyReplenishmentAggregatorModel.BE_PAID,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'GBP',
                symbol: '£',
                value: this.GBP,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.FONDY,
                    MoneyReplenishmentAggregatorModel.STRIPE,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'GEL',
                symbol: '₾',
                value: this.GEL,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'HKD',
                symbol: 'HK$',
                value: this.HKD,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'INR',
                symbol: '₹',
                value: this.INR,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.STRIPE,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'KGS',
                symbol: 'с',
                value: this.KGS,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'KZT',
                symbol: '₸',
                value: this.KZT,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.PRODAMUS,
                    MoneyReplenishmentAggregatorModel.PRODAMUS_NATIVE,
                    MoneyReplenishmentAggregatorModel.ROBOKASSA,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'PLN',
                symbol: 'zł',
                value: this.PLN,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.STRIPE,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'RON',
                symbol: 'RON',
                value: this.RON,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'RUB',
                symbol: '₽',
                value: this.RUB,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.PRODAMUS,
                    MoneyReplenishmentAggregatorModel.PRODAMUS_NATIVE,
                    MoneyReplenishmentAggregatorModel.ROBOKASSA,
                    MoneyReplenishmentAggregatorModel.YOOKASSA,
                    MoneyReplenishmentAggregatorModel.MONETS_ME,
                    MoneyReplenishmentAggregatorModel.BE_PAID,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'SEK',
                symbol: 'kr',
                value: this.SEK,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'TRY',
                symbol: '₺',
                value: this.TRY,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'UAH',
                symbol: '₴',
                value: this.UAH,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.FONDY,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'USD',
                symbol: '$',
                value: this.USD,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.PRODAMUS,
                    MoneyReplenishmentAggregatorModel.PRODAMUS_NATIVE,
                    MoneyReplenishmentAggregatorModel.ROBOKASSA,
                    MoneyReplenishmentAggregatorModel.FONDY,
                    MoneyReplenishmentAggregatorModel.MONETS_ME,
                    MoneyReplenishmentAggregatorModel.STRIPE,
                    MoneyReplenishmentAggregatorModel.BE_PAID,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'UZS',
                symbol: "So'm",
                value: this.UZS,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'ZAR',
                symbol: 'R',
                value: this.ZAR,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.CLOUDPAYMENTS,
                    MoneyReplenishmentAggregatorModel.CASH
                ]
            },
            {
                label: 'XTR',
                symbol: '★',
                value: this.XTR,
                aggregator_ids: [
                    MoneyReplenishmentAggregatorModel.TELEGRAM_STARS
                ]
            }
        ]
    }

    static getSymbolById(id) {
        let type = this.types.find((x) => x.value === id)

        return type ? type.symbol : '₽'
    }
}
