export class ChatMessageFolderScheduleModel {
  constructor() {
    this.form = {
      starts: [],
      is_restartable: true,
      is_returnable: false,
      is_manual: true,
      has_starts: false,
      has_stop: false,
      has_stop_actions: false,
      stop_id: null,
      stop_stage_id: null,
      stop_folder_id: null,
      stop_actions: [],
      stop_days: undefined,
      stop_timezone: undefined,
      stop_datetime: undefined
    }
  }
}
