import {mapActions} from 'vuex'

export const fetchMixin = {
    data: () => ({
        loading: false
    }),
    methods:
    {
        fetchData(url, params = {}, validateLoading = true, toggleLoading = true) {
            return new Promise((resolve, reject) => {
                if (validateLoading && this.loading) {
                    reject(new Error('not validate'))
                }

                if (toggleLoading) {
                    this.loading = true
                }

                axios
                    .get(url, {params: params})
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                    .finally(() => {
                        if (toggleLoading) {
                            this.loading = false
                            this.$emit('loaded')
                        }
                    })
            })
        }
    }
}

export const fetchItemsMixin = {
    data: () => ({
        items: {
            data: [], current_page: null, last_page: null, per_page: null, total: null
        }
    })
}

export const fetchMethodMixin = {
    methods:
    {
        ...mapActions('chat', ['setCurrentChat']),

        async fetch({page = 1, isMore = false} = {}, validateLoading = true, toggleLoading = true) {
            let params = {
                ...this.fetchParams, ...{
                    page: isMore ? this.items.current_page : page, with_pagination: 1
                }
            }

            if (!isMore) {
                this.items.data = []
            }

            let res = await this.fetchData(this.url, params, validateLoading, toggleLoading)

            if (params.with_chat) {
                this.setCurrentChat(res.chat)

                res = res.items
            } else if (params.with_type) {
                this.type = res.type

                res = res.items
            }

            this.processingFetch(res.data)

            this.setPagination(res)
        }
    },
    computed:
    {
        url() {
            return null
        },

        fetchParams() {
            return {}
        }
    }
}

export const fetchItemsProcessingMixin = {
    methods:
    {
        processingFetch(data) {
            data.forEach((item) => {
                item.loading = false

                this.items.data.push(item)
            })
        }
    }
}

export const fetchSetPaginationMixin = {
    methods:
    {
        setPagination(res) {
            this.items.current_page = res.current_page

            this.items.last_page = res.last_page

            this.items.per_page = res.per_page

            this.items.total = res.total
        }
    }
}
