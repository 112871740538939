<template>
  <div class="primary">
    <div v-if="conference_loaded" class="primary__box">
      <burger-button />
      <main>
        <transition name="slide">
          <router-view />
        </transition>
      </main>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapState } from 'vuex'
  import { fetchMixin } from '@/js/mixins/fetch/fetchMixin'
  import BurgerButton from '@/js/components/conference/components/BurgerButton.vue'

  export default {
    name: 'MainConference',
    components: { BurgerButton },
    mixins: [fetchMixin],
    computed: {
      ...mapState('conference', ['subscriber', 'conference_loaded']),
      subUuid() {
        return this.$route.query?.uuid
      },
      chatUuid() {
        return this.$route.params?.chatUuid
      }
    },
    mounted() {
      this.$router.onReady(() => {
        window.Telegram?.WebApp?.expand()

        if (this.chatUuid && !this.conference_loaded) {
          this.fetchSub()
        }
      })
    },
    methods: {
      ...mapActions('conference', ['setConference']),

      async fetchSub() {
        const conference = await this.fetchData(
          `/pub/chats/${this.chatUuid}/conference`,
          window.Telegram?.WebApp?.initData
            ? { webapp: window.Telegram?.WebApp.initData }
            : { uuid: this.subUuid }
        )
        this.setConference(conference)
      }
    }
  }
</script>
<style scoped>
  @font-face {
    font-family: 'Vela Sans';
    font-weight: 200;
    font-display: auto;
    src: url('@/assets/fonts/VelaSans/VelaSans-ExtraLight.woff2') format('woff2'),
      url('@/assets/fonts/VelaSans/VelaSans-ExtraLight.woff') format('woff');
  }

  @font-face {
    font-family: 'Vela Sans';
    font-weight: 300;
    font-display: auto;
    src: url('@/assets/fonts/VelaSans/VelaSans-Light.woff2') format('woff2'),
      url('@/assets/fonts/VelaSans/VelaSans-Light.woff') format('woff');
  }

  @font-face {
    font-family: 'Vela Sans';
    font-weight: 400;
    font-display: auto;
    src: url('@/assets/fonts/VelaSans/VelaSans-Regular.woff2') format('woff2'),
      url('@/assets/fonts/VelaSans/VelaSans-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Vela Sans';
    font-weight: 500;
    font-display: auto;
    src: url('@/assets/fonts/VelaSans/VelaSans-Medium.woff2') format('woff2'),
      url('@/assets/fonts/VelaSans/VelaSans-Medium.woff') format('woff');
  }

  @font-face {
    font-family: 'Vela Sans';
    font-weight: 600;
    font-display: auto;
    src: url('@/assets/fonts/VelaSans/VelaSans-SemiBold.woff2') format('woff2'),
      url('@/assets/fonts/VelaSans/VelaSans-SemiBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Vela Sans';
    font-weight: 700;
    font-display: auto;
    src: url('@/assets/fonts/VelaSans/VelaSans-Bold.woff2') format('woff2'),
      url('@/assets/fonts/VelaSans/VelaSans-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'e-Ukraine';
    font-weight: 400;
    font-display: auto;
    src: url('@/assets/fonts/eUkraine/eUkraine.woff2') format('woff2'),
      url('@/assets/fonts/eUkraine/eUkraine.woff2') format('woff');
  }
  .primary {
    min-height: 100vh;
    font-family: 'Vela Sans', sans-serif;
    color: #fff;
    background: #121315;
  }
  .primary__box {
    max-width: 425px;
    margin: 0 auto;
    overflow: hidden;
    min-height: 100vh;
    position: relative;
  }
  .slide-leave-active,
  .slide-enter-active {
    transition: transform 0.5s;
  }
  .slide-enter {
    transform: translateX(100%);
  }
  .slide-leave-to {
    transform: translateX(-100%);
  }
</style>
