import {
    ChatPlatformModel
} from '@/js/models/chat/ChatPlatformModel'
import { ChatModel } from '@/js/models/chat/ChatModel'

export class ChatSubscriberFormModel {
    static url(chat_id) {
        return `${ChatModel.URL}/${chat_id}/subscriber-form`
    }

    static WWB_DATE_NOW = 'wwb_date_now'
    static REFNAME = 'REFNAME'
    //дни недели// Текущая неделя
    static WWB_DATE_CURRENT_MONDAY    = 'wwb_date_current_monday';
    static WWB_DATE_CURRENT_TUESDAY   = 'wwb_date_current_tuesday';
    static WWB_DATE_CURRENT_WEDNESDAY = 'wwb_date_current_wednesday';
    static WWB_DATE_CURRENT_THURSDAY  = 'wwb_date_current_thursday';
    static WWB_DATE_CURRENT_FRIDAY    = 'wwb_date_current_friday';
    static WWB_DATE_CURRENT_SATURDAY  = 'wwb_date_current_saturday';
    static WWB_DATE_CURRENT_SUNDAY    = 'wwb_date_current_sunday';
    // Следующая неделя
    static WWB_DATE_NEXT_MONDAY    = 'wwb_date_next_monday';
    static WWB_DATE_NEXT_TUESDAY   = 'wwb_date_next_tuesday';
    static WWB_DATE_NEXT_WEDNESDAY = 'wwb_date_next_wednesday';
    static WWB_DATE_NEXT_THURSDAY  = 'wwb_date_next_thursday';
    static WWB_DATE_NEXT_FRIDAY    = 'wwb_date_next_friday';
    static WWB_DATE_NEXT_SATURDAY  = 'wwb_date_next_saturday';
    static WWB_DATE_NEXT_SUNDAY    = 'wwb_date_next_sunday';


    constructor() {
        this.form = {
            id: null,
            chat_id: null,
            info: []
        }
    }

    static setFormInfoLocalization(platformId, info) {
        let form = ChatPlatformModel.getDefaultSubForm(platformId)

        info.forEach((item) => {
            if (form.findIndex((x) => x.prop === item.prop) >= 0) return

            form.push(item)
        })

        return form
    }

    static renderFormName(prop, name) {
        let form = ChatPlatformModel.getChatSubscriberFullDefaultForm()

        let item = form.find((x) => x.prop === prop)

        return item ? item.name() : name
    }

    static togglePropUpdate(
        form,
        newForm,
        keyType = 'type_id',
        keyCompareType = 'compare_type_id'
    ) {
        if (!newForm.hasOwnProperty('prop')) {
            form.value = newForm

            form[keyType] = null

            return (form.prop = null)
        }

        form.prop = newForm.prop

        if (form[keyType] === null || form[keyType] !== newForm.type_id) {
            form.prop_value = null

            form[keyCompareType] = null
        }

        form[keyType] = newForm.type_id
    }

    static isHideReferral(prop, chat_referral) {
        return ['REFID', 'REFCOUNT'].includes(prop) && !chat_referral
    }

    static isUtm(prop) {
        return [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_term',
            'utm_content'
        ].includes(prop)
    }
}
