import { ChatSubscriberStageModel } from '@/js/models/chat/subscriber/stage/ChatSubscriberStageModel'

const initialState = () => {
  return {
    chat_subscriber_stages: [],
    chat_subscriber_stages_loaded: false
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoaded(state, isLoaded) {
      state.chat_subscriber_stages_loaded = isLoaded
    },

    setStages(state, stages) {
      state.chat_subscriber_stages = []

      stages.forEach((item) =>
        state.chat_subscriber_stages.push({
          id: item.id,
          name: item.name,
          default: item.default
        })
      )
    },

    addStage(state, stage) {
      if (state.chat_subscriber_stages.find((x) => x.id === stage.id)) return

      state.chat_subscriber_stages.unshift(stage)
    },

    deleteStage(state, id) {
      let index = state.chat_subscriber_stages.findIndex(
        (stage) => stage.id === id
      )

      if (index === -1) return

      state.chat_subscriber_stages.splice(index, 1)
    },

    reset(state) {
      const init_state = initialState()

      for (let key in init_state) {
        state[key] = init_state[key]
      }
    }
  },
  actions: {
    async fetchStages({ dispatch }, { chat_id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(ChatSubscriberStageModel.url(chat_id), {
            params: { ...params, ...{ with_chat: 1 } }
          })
          .then(({ data }) => {
            dispatch('setStages', data.items)

            dispatch('chat/setCurrentChat', data.chat, { root: true })

            return resolve()
          })
          .catch(async (error) => {
            dispatch('resetStages')

            return reject(error)
          })
      })
    },

    setStages({ commit }, stages) {
      commit('setStages', stages)

      commit('setLoaded', true)
    },

    addStage({ commit }, stage) {
      commit('addStage', stage)
    },

    deleteStage({ commit }, id) {
      commit('deleteStage', id)
    },

    resetStages({ commit }) {
      commit('reset')
    }
  }
}
